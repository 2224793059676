.container {
  position: fixed;
  width: 100%;
  height: 55px;
  overflow: hidden;
  bottom: 0;
  z-index: -1;

  .app_version {
    text-align: center;
    width: 100%;
    padding-top: 10px;
    font-family: "IRANYekanMobileLight";
    overflow: hidden;
  }

  // background-color: red;
}
.ball_gradient_footer {
  z-index: -1;
  position: absolute;
  border-radius: 50%;
  width: 213px;
  height: 213px;
  bottom: -165px;
  z-index: -1;
  background: linear-gradient(
    60.13deg,
    rgba(44, 64, 101, 0.38) -87.8%,
    rgba(44, 64, 101, 0) 95.22%
  );
  left: 50%;
  transform: translate(-50%);
}
@media screen and (max-height: 560px) {
  .container {
    display: none;
  }
}
