@import "../../styles/_global.scss";
@mixin button {
  font-family: "IranSansWeb";
  border-radius: 3px;
  border-width: 0px;
  font-size: 14.5px;
  font-weight: 700;
  color: #fff;
  outline: none;
  height: 40px;
  width: 90%;
}
@mixin titleNotification {
  min-width: 214px;
  max-width: 240px;
  text-align: center;
  margin: 0 auto;
  font-size: 12px;
  border-radius: 3px;
  color: #fff;
  min-height: 33px;
}
@mixin paymentDetails {
}
.bg_success {
  background-color: $static_green;
}
.text_success {
  color: $static_green;
}
.bg_failed {
  background-color: $static_darkRed;
}
.text_failed {
  color: $static_darkRed;
}
.border_success {
  border: 1px solid $static_green;
}
.border_failed {
  border: 1px solid $static_darkRed;
}
.failed_text_edit {
  font-size: 13.5px;
  direction: rtl;
  color: rgba(99, 98, 98, 1);
  padding: 5px 10px 5px 0px;
  line-height: 20px;
}
//ORG --->
.container {
  font-family: "IranSansWeb";
  position: relative;
  width: 100%;
  margin-bottom: 110px;
  .loading {
    background-color: $static_darkBlue;
    position: relative;
    width: 100%;
    height: 100vh;
    section {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
    }
    div {
      position: absolute;
      display: inline-block;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .container_btn {
    text-align: center;
    .status_btn {
      @include button;
    }
  }
  .status_btn_success {
    background-color: $static_green;
  }
  .status_btn_failed {
    background-color: $static_darkRed;
  }
  .status_icon {
    text-align: center;
  }
}
//\\
//\\
//STATUS's ---->
//\\
//\\
.status_container {
  width: 100%;
  position: relative;
  .status_title {
    font-size: 18px;
    text-align: center;
    margin: 45px;
    margin-bottom: 30px;
    font-weight: 700;
  }
  .status_notification {
    position: relative;
    @include titleNotification;
    p {
      margin: 0 auto;
      width: 85%;
      padding-top: 11px;
    }
    .image {
      position: absolute;
      top: 8px;
      right: 5px;
    }
  }
  .status_details {
    margin: 15px auto;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    border-radius: 4px;
    padding: 10px 0 12px 0;
    .grid_item {
      padding-left: 10px;
      margin-top: 13px;
      display: grid;
      grid-template-columns: auto auto;
      direction: rtl;
      justify-content: space-between;
      text-indent: 10px;
      color: var(--secondary-color);
      font-size: 13px;
      div:nth-child(2) {
        background-color: rgba(196, 196, 196, 0.25);
        border-radius: 12px;
        div {
          direction: ltr;
          text-align: center;
          padding-top: 2px;
          font-size: 11px;
          padding-left: 5px;
          padding-right: 10px;
        }
      }
    }
  }
}
