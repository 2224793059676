@import "../../../styles/_global.scss";

.first_input,
.second_input {
  margin-top: 13px;
  position: relative;
  left: 50%;
  transform: translate(-50%);
  width: 93%;
  height: 40px;

  input {
    width: 100%;
    padding: 0px;
    margin: 0px;
    border: inherit;
    height: 35px;
    border-radius: 5px;
    background-color: #f2f8f6;
    outline: none;
    font-family: "IranSansWeb";
    font-size: 16px;
    text-indent: 10px;
    &:focus {
      border: 1px solid $static_green;
    }
    &::placeholder {
      font-size: 12px;
      line-height: 40px;
      padding-right: 10px;
      float: right;
    }
  }
}
.second_input {
  .second_input_placeholder {
    font-family: "IranSansWeb";
    position: absolute;
    top: 9px;
    right: 10px;
    font-size: 11.5px;
    color: rgb(15, 15, 15, 0.5);
    font-weight: 700;
    direction: rtl;
    // z-index: -1;
  }
}
.first_input {
  .first_input_placeholder {
    font-family: "IranSansWeb";
    position: absolute;
    top: 8px;
    right: 10px;
    font-size: 11.5px;
    color: rgb(15, 15, 15, 0.5);
    font-weight: 700;
    direction: rtl;
    // z-index: -1;
  }
}
.user_coin_count {
  margin: 0 auto;
  position: relative;
  width: 90%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  direction: rtl;
  .title_count {
    font-family: "IranSansWeb";
    max-width: 150px;
    height: 15px;
    color: #868484;
    right: 2px;
    font-size: 11.5px;
  }
  .user_coins {
    display: inline;
    color: #868484;
    height: 15px;
    .symbol_coin {
      display: inline-block;
      font-size: 11.5px;
      margin-right: 5px;
      font-family: "IranSansWeb";
    }
    .coin_amount {
      font-family: "IranSansWeb";
      font-size: 12px;
      display: inline-block;
      margin-inline-start: 2px;
    }
  }
}

//
.switch_container {
  position: relative;
  text-align: center;
  width: 100%;
  height: 25px;
  .button_change_to_sell {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    img {
      animation-name: rotateVertical;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
    }
  }
}

.user_wallet_amount {
  margin: 0 auto;
  position: relative;
  width: 90%;
  min-height: 30px;
  display: flex;
  align-items: center;
  .title_count {
    position: absolute;
    width: 150px;
    height: 15px;
    color: #868484;
    right: 2px;
    top: 8px;
    font-size: 11.5px;
    direction: rtl;
    font-family: "IranSansWeb";
  }
  .user_coins {
    display: inline;
    color: #868484;
    height: 15px;
    .symbol_coin {
      display: inline-block;
      font-size: 11.5px;
      margin-right: 5px;
      font-family: "IranSansWeb";
    }
    .coin_amount {
      font-size: 12px;
      display: inline-block;
      font-family: "IranSansWeb";
    }
  }
}
.button_place {
  margin-top: 15px;
  display: flex;
  margin-inline: 4%;
  column-gap: 5px;
}
.message_for_login {
  width: 340px;
  margin: 8px auto;
  margin-top: 20px;
  direction: rtl;
  color: $static_darkRed;
  font-family: "IranSansWeb";
  font-size: 14px;
  text-align: center;
  span {
    padding-bottom: 4px;
    color: $static_darkBlue;
    border-bottom: 1px solid $static_green;
  }
}

//////// MESSAGE STYLES //////

//Animation
@keyframes rotateVertical {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(360deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

//
@keyframes buttonShow {
  0% {
    font-size: 16px;
  }
  50% {
    font-size: 18px;
  }
  100% {
    font-size: 16px;
  }
}

///
.show_persian_price {
  margin: 0 auto;
  margin-top: 5px;
  direction: rtl;
  color: rgba(85, 85, 85, 0.8);
  width: 92%;
  font-size: 13.5px;
  font-family: "IranSansWeb";
}

.error_container {
  border: 1px solid var(--danger-color);
  border-radius: 3px;
  font-size: 13px;
  width: 90%;
  margin: 5px auto;
  min-height: 25px;
  padding-block: 4px;
  padding-inline: 2px;
  align-items: center !important;
  justify-content: center !important;
  font-family: "IranSansWeb";
  direction: rtl;
  color: var(--danger-color);
}
.on_price_container {
  width: 90%;
  margin: 2px auto;
  margin-top: 5px;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  direction: rtl;
  font-family: "IranSansWeb";
  font-size: 12.5px;
  color: var(--secondary-color);
}
