.buttnon3 {
  width: 100%;
  height: 35px;
  border-width: 0;
  outline: none;
  font-family: "IranSansWeb";
  border-radius: 3px;
  background: #F2F1F1;
  border:1px solid transparent;
  color: #272E40;
  font-size: 13px;
  &:disabled {
    background: rgba(0, 193, 148, 0.1);
    border: 1px solid #00c194;
    color: #00c194;
  }
}
