@import "../../../../styles/_global.scss";

.container {
  position: relative;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  overflow: hidden;
  .buttons_swap_components {
    z-index: 1;
    position: relative;
    margin: 0 auto;
    width: 92%;
    height: 34px;
    margin-top: 28px;
    margin-bottom: 10px;
    display: grid;
    grid-template-columns: 100%;
    justify-content: space-between;
    .button_of_swap {
      background-color: white;
      width: 100%;
      height: 32px;
      color: $static_green;
      text-align: center;
      font-family: "IranSansWeb";
      border: 1px solid #10d078;
      border-radius: 3px;
      line-height: 33px;
    }
    .active_button_swap {
      background-color: $static_green;
      color: #fff;
    }
  }
  .information_of_convert_container {
    position: relative;
    margin: 0 auto;
    margin-top: 30px;
    width: 92%;
    .pay_coin_information,
    .get_coin_information {
      position: relative;
      display: grid;
      grid-template-columns: auto;
      .deposit_coin {
        color: rgba(147, 146, 146, 1);
        display: grid;
        grid-template-columns: auto;
        justify-content: end;
        margin-top: 15px;
        margin-bottom: 8px;
        div {
          display: grid;
          grid-template-columns: auto auto;
          width: 50px;
          height: 16px;
          font-size: 13px;
          font-family: "IranSansWeb";
          line-height: 20px;
        }
      }
      .input_place {
        .box_coin_select {
          position: relative;
          min-width: 100px;
          max-width: 200px;
          height: 45px;
          &:hover,
          &:focus {
            background-color: rgba(196, 196, 196, 0.7);
            .forAnim_arrow {
              animation: ArrowMove;
              animation-duration: 0.5s;
              animation-iteration-count: 1;
            }
          }
          .ArrowPlace {
            position: absolute;
            width: 9px;
            height: 9px;
            right: 5px;
            top: 12px;
            img {
              position: absolute;
              top: 0;
              left: 0;
            }
          }
          .coin_symbol {
            position: relative;
            direction: rtl;
            height: 20px;
            bottom: 15px;
            padding-right: 35px;
            top: -10px;
            left: 15px;
            text-align: right;
            font-size: 14px;
            font-family: "IranSansWeb";
          }
          .coin_name {
            font-family: "IranSansWeb";
            position: relative;
            direction: rtl;
            height: 20px;
            bottom: 15px;
            padding-right: 35px;
            padding-left: 10px;
            top: -13px;
            left: 20px;
            text-align: right;
            font-size: 12.5px;
          }
          .coin_pic {
            position: absolute;
            top: 8px;
            left: 5px;
          }
        }
      }
      .user_coin_info {
        top: 3px;
        width: 100%;
        margin-top: 5px;
        position: relative;
        display: flex;
        flex-direction: wrap;
        justify-content: space-between;
        direction: rtl;
        font-size: 12.5px;
      }
    }
    .swap_coins {
      position: relative;
      margin: 5px auto;
      margin-top: 20px;
      width: 50px;
      height: 30px;
      text-align: center;
      button {
        all: unset;
        width: 100%;
        height: 100%;
        &:disabled {
          img {
            filter: invert(51%) sepia(92%) saturate(13%) hue-rotate(358deg)
              brightness(83%) contrast(86%);
          }
        }
      }
      img {
        animation-name: RotateSwitch;
        animation-duration: 0.6s;
        animation-iteration-count: 1;
      }
    }

    .convert_coins_price {
      margin-top: 15px;
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: 49% 1% 49%;
      border-radius: 4px;
      justify-content: space-between;
      animation: scaleOnce;
      animation-duration: 0.5s;
      animation-iteration-count: 1;
      background: linear-gradient(
        270deg,
        rgb(196, 196, 196, 0.15) 0%,
        rgba(196, 196, 196, 0.15) 99.99%,
        rgba(196, 196, 196, 0.15) 100%
      );
      .line_between_price {
        position: relative;
        top: 5px;
        width: 1px;
        height: 25px;
        background-color: rgba(16, 208, 120, 1);
      }
      .left_right_side_coin {
        position: relative;
        color: rgba(131, 127, 127, 1);
        font-size: 11px;
        font-family: "IranSansWeb";
        display: grid;
        grid-template-columns: 20px auto auto;
        text-align: center;
        justify-content: space-around;
        .icon_mini_chart {
          width: 16px;
          height: 16px;
          padding-top: 11px;
          padding-left: 8px;
        }
        .price_of_one_coin {
          padding-top: 12px;
        }
        .dollar_price_of_coin {
          word-break: break-all;
          padding-top: 12px;
          display: grid;
          grid-template-columns: auto auto;
          max-width: 90px;
          justify-content: space-around;
          padding-bottom: 10px;
        }
      }
    }
  }
  .footer_convert {
    position: relative;
    margin-top: 40px;
    display: grid;
    grid-template-columns: auto;
    button {
      margin: 0 auto;
      width: 85%;
      border-width: 0px;
      background: var(--primary-color);
      font-size: 17px;
      color: #fff;
      font-family: "IranSansWeb";
      border-radius: 3px;
      outline: none;
      height: 38px;
      margin-top: 20px;
      &:hover {
        border: 1px solid #fff;
      }
      &:disabled {
        opacity: 0.7;
      }
    }
    .line_convert_footer {
      margin: 53px auto;
      width: 90%;
      margin-bottom: 5px;
    }
    .showErrorNotification {
      display: grid;
      grid-template-columns: auto auto;
      margin: 10px auto;
      position: relative;
      direction: rtl;
      font-size: 13.5px;
      font-family: "IranSansWeb";
      color: rgba(246, 85, 85, 1);
      line-height: 25px;
    }
  }
  .footer_custom_convert {
    position: relative;
    margin-top: 90px;
    display: grid;
    grid-template-columns: auto;
    button {
      margin: 0 auto;
      width: 85%;
      border-width: 0px;
      background-color: $static_darkBlue;
      font-size: 17px;
      color: #fff;
      font-family: "IranSansWeb";
      border-radius: 3px;
      outline: none;
      height: 38px;
      margin-top: 20px;
      border: 1px solid $static_darkBlue;
      &:hover {
        border: 1px solid #fff;
      }
    }
    .line_convert_footer {
      margin: 12px auto;
      width: 94%;
      margin-bottom: 5px;
    }
    .showErrorNotification {
      display: grid;
      grid-template-columns: auto auto;
      margin: 10px auto;
      position: relative;
      direction: rtl;
      font-size: 13px;
      font-family: "IranSansWeb";
      color: rgba(246, 85, 85, 1);
      line-height: 25px;
    }
  }
}

.greenIcon {
  filter: invert(36%) sepia(99%) saturate(660%) hue-rotate(112deg)
    brightness(103%) contrast(90%);
}

.custom_coin_info {
  margin-top: 14px;
}
.custom_coin2_info {
  top: 8px;
}
//animations
@keyframes ArrowMove {
  from {
    top: 0px;
  }
  to {
    top: 6px;
  }
}
@keyframes RotateSwitch {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes scaleOnce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.03);
  }
}

////Message LOGIN
.footer_convert,
.footer_custom_convert {
  margin-bottom: 110px;
  button {
    &:disabled {
      background-color: rgba(0, 0, 0, 0.2) !important;
      border: none !important;
    }
  }
}
.message_for_login {
  width: 85%;
  margin: 5px auto;
  margin-top: 20px;
  color: $static_darkRed;
  direction: rtl;
  font-size: 14px;
  text-align: center;
  font-family: "IranSansWeb";
  span {
    color: $static_darkBlue;
  }
}
.underBuild {
  position: absolute;
  background-color: rgba(131, 127, 127, 0.6);
  width: 100%;
  height: 100%;
  z-index: 1;
  font-family: "IranSansWeb";
  h2 {
    text-align: center;
    line-height: 80vh;
    direction: rtl;
    color: #fff;
  }
}

.custom_details_container {
  margin-top: 10px;
  height: 50px;
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  direction: rtl;
  .title_paper {
    font-family: "IranSansWeb";
    font-size: 12px;
    padding-right: 10px;
    color: rgba(131, 127, 127, 1);
  }
  .select_day_expire {
    width: 100%;
    text-align: center;
  }
  .area_balance {
    position: absolute;
    color: rgb(88, 85, 85, 0.8);
    font-family: "IranSansWeb";
    padding-top: 7px;
    font-size: 13.5px;
  }
  .select_price_container {
    position: relative;
    width: 100%;
    direction: ltr;
    input {
      background-color: transparent;
      width: 87%;
      padding: 0;
      border-width: 0px;
      height: 40px;
      outline: none;
      text-align: center;
      text-indent: 4px;
      font-family: "IranSansWeb";
      font-size: 13px;
      color: rgb(88, 85, 85);
    }
    button {
      position: absolute;
      width: 15px;
      height: 15px;
      padding: 0;
      border-width: 0px;
      background-color: rgba(255, 255, 255, 1);
      border: 0.7px solid #c4c4c4;
      border-radius: 1px;
      right: -1px;
      outline: none;
      color: #c4c4c4;
      line-height: 13px;
    }
    button:nth-child(1) {
      top: 0;
    }
    button:nth-child(2) {
      line-height: 10px;
      bottom: 2px;
    }
  }
  .price_must_user_pay {
    margin-top: 4px;
    width: 100%;
    height: 20px;
    text-align: left;
    color: rgb(88, 85, 85);
    font-size: 14px;
    direction: ltr;
    svg {
      position: relative;
      top: 2px;
    }
  }
}
