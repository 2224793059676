.container {
  background-color: var(--third-color);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 7px;
  font-family: "IranSansWeb";
  min-height: 60px;
  .subject {
    color: var(--secondary-color);
  }
  .status {
    span[data-statusnum="false"] {
      svg {
        color: var(--danger-color) !important;
      }
    }
    span[data-statusnum="true"] {
      svg {
        color: var(--primary-color) !important;
      }
    }
    button{
      font-family: "IranSansWeb";
      outline: none;
      border: 1px solid var(--primary-color);
      background-color: transparent;
      color: var(--primary-color);
      padding: 5px 20px;
      border-radius: 5px;
    }
  }
}
