.container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: -1;
  &[data-visibility="true"] {
    z-index: 99;
  }
  .content {
    position: fixed;
    bottom: -100%;
    background-color: var(--third2-color);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    padding: 10px;
    box-sizing: border-box;
    width: 100%;
    transition: bottom 0.25s;
    // display: none;
    &[data-visibility="true"] {
      bottom: 0;
    }
  }
  direction: rtl;
  .headContent {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
    p {
      margin: 0;
      color: var(--secondary-color);
      font-size: 13px;
    }
    svg {
      color: var(--secondary-color) !important;
      font-size: 20px;
    }
  }
}
.itemsContainer {
  max-height: 250px;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
.item {
  min-height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  border-radius: 5px;
  margin-bottom: 5px;
  padding-inline: 10px;
  box-sizing: border-box;
  border: 1px solid var(--main-color);
  p {
    margin: 0;
    margin-top: 3px;
    font-family: "IranYekanBold" !important;
    color: var(--secondary-color);
  }
  &[data-active="true"] {
    border-color: var(--primary-color);
    p {
      color: var(--primary-color);
    }
  }
}
.emptyContainer{
  p{
    margin: 20px;
    text-align: center;
    color: var(--danger-color);
  }
}