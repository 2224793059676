.cotainer {
  width: 100%;
  height: 28px;
  direction: rtl;
  font-family: "IranSansWeb";
  // padding-inline: 15px;
  color: #01081e;
  border-bottom: 1px solid #fcfcfc;
  position: relative;
  top: 5px;
  .type div {
    display: inline;
  }
  .type {
    position: relative;
    top: -6px;
    display: inline;
    padding-inline: 5px;
    .title {
      font-size: 14px;
    }
    .icon {
      svg {
        position: relative;
        top: 5px;
      }
    }
  }
  .info {
    display: inline;
    margin-right: 20px;
    font-size: 18px;
    svg {
      position: relative;
      top: 1px;
    }
  }
}
