.item {
  background-color: rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 8px 15px;
  font-family: "IranSansWeb";
  margin-bottom: 6px;
  span {
    color: var(--secondary-color);
    &[data-icon="true"] {
      svg {
        color: var(--secondary-color) !important;
        margin-top: 5px;
        font-size: 22px;
      }
    }
  }
  &[data-active="true"] {
    span {
      color: var(--primary-color) !important;
    }
  }
}
