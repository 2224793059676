.container {
  width: 100%;
  z-index: 5;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  direction: rtl;
  &[data-invisible="true"] {
    display: none;
  }
  .bg {
    background: rgba(0, 0, 0, 0.25);
    width: 100%;
    height: 100%;
  }
  .main {
    background: var(--third2-color);
    width: 90%;
    max-width: 480px;
    padding-bottom: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    border: 1px solid var(--topLevel-color);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
  .image {
    margin: 4px;
    img {
      object-fit: contain;
    }
  }
  b {
    font-size: 13px;
    color: var(--secondary-color);
    margin-bottom: 5px;
  }
}
