@import "../../styles/_global.scss";
.container {
  margin: 40px auto;
  width: 92%;
  margin-bottom: 110px;
  direction: rtl;
  font-family: "IranSansWeb";
  .back_btn {
    width: 20px;
    height: 20px;
    position: absolute;
    top: 20px;
    left: 5%;
    img {
      transform: rotate(180deg);
    }
  }
  .title {
    width: 100%;
    text-align: center;
    color: $static_green;
    margin-bottom: 20px;
    font-weight: 700;
    font-size: 19px;
  }
  .place_notification {
    line-height: 22px;
    text-align: justify;
    div:nth-child(1) {
      border: 1px solid rgb(201, 146, 44) !important;
    }
    div {
      span {
        font-size: 14px;
        color: #fff !important;
      }
      span:nth-child(2) {
        font-size: 16px;
        color: #fff !important;
      }
    }
  }
  h4 {
    text-align: center;
    width: 100%;
    font-size: 15px;
    color: $static_darkRed;
  }
  ol {
    padding-right: 20px;
    padding-left: 10px;
    margin: 0;
  }
  li {
    line-height: 21px;
    margin-bottom: 20px;
    padding: 0;
    text-align: justify;
  }
  .button_container {
    width: 100%;
    text-align: center;
    button {
      margin: 0 auto;
      width: 50%;
      border-width: 0px;
      border-radius: 3px;
      height: 40px;
      font-family: "IranSansWeb";
      background-color: $static_green;
      color: #fff;
      outline: none;
    }
  }
}
