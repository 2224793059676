.container {
  min-height: 50px;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  strong {
    color: var(--secondary-color);
    font-size: 14px;
  }
  .svgPlace {
    &[data-fix="true"] {
      margin-left: -7px;
    }
    svg {
      color: var(--secondary-color) !important;
    }
  }
}
