.container {
  width: 100%;
  height: 237px;
  background: var(--temp-color);
  border-radius: 12px;
}

.banner_container {
  width: 100%;
  height: 220px;
  margin-top: 10px;
  padding-inline: 10px;
  box-sizing: border-box;
  direction: rtl;
  .logo {
    margin-top: -15px;
    text-align: right;
  }
  .title {
    text-align: right;
    color: var(--secondary-color);
    font-size: 13.2px;
    width: 100%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  strong {
    font-family: "IranSansWeb"!important;
  }
  .desc {
    text-align: right;
    color: var(--secondary3-color);
    opacity: 0.92;
    font-size: 11.5px;
    height: 38px;
  }
  .pic_container {
    width: 100%;
    direction: ltr;
  }
  .pic {
    width: 110px;
    height: 110px;
    margin-top: 5px;
  }
}
