@import "../../../styles/_global.scss";

.container {
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  box-shadow: 0px -3px 0px rgba(0, 0, 0, 0.07);
  border-radius: 21px 21px 0px 0px;
  padding-bottom: 100px;
  .header_container {
    width: 100%;
    height: 100px;
    margin-block: 5px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-inline: 10px;
    .back_btn {
      width: 50px;
      svg {
        color: var(--secondary4-color) !important;
      }
    }
    .name {
      width: calc(100% - 100px);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      h2 {
        margin: 0;
        padding: 0;
        margin-left: 20px;
        font-size: 18.5px;
        color: var(--secondary2-color);
        font-family: IranSansWeb;
      }
      .more {
        width: 20px;
        text-align: center;
        svg {
          color: var(--secondary3-color) !important;
        }
      }
    }
    .icon{
      width: 45px;
      height: 45px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .middle_layout {
    background: var(--layout-color);
    width: 93%;
    margin: 0 auto;
    padding-inline: 13px;
    box-sizing: border-box;
    border-radius: 2px;
    padding-bottom: 10px;
    font-family: "IranSansWeb";
    margin-top: 5px;
    margin-bottom: 30px;
    position: relative;
    .head_details {
      display: flex;
      justify-content: space-between;
      width: 100%;
      min-height: 20px;
      h1 {
        background: var(--primary-color);
        color: #fff;
        font-size: 13px;
        margin: 0;
        padding-inline: 12px;
        padding-block: 3px;
        padding-bottom: 4px;
        border-radius: 0px 0px 3px 3px;
        max-width: 150px;
        text-align: center;
      }
      .head_details_left {
        display: flex;
        flex-direction: row;
        grid-gap: 10px;
        margin-top: -5px;
        max-width: 150px;
        .desc {
          border: 1px solid var(--primary-color);
          height: 26px;
          padding-inline: 12px;
          font-size: 12.5px;
          display: flex;
          align-items: center;
          color: var(--primary-color);
          border-radius: 3px;
        }
        .percent24 {
          border-radius: 3px;
          height: 26px;
          background: var(--danger-color);
          color: #fff;
          padding-inline: 8px;
          font-size: 12.5px;
          display: flex;
          align-items: center;
          &[data-green="true"] {
            background: var(--primary-color);
          }
        }
      }
    }
    .body {
      margin-top: 5px;
      .item {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: var(--item-color);
        font-size: 14.5px;
        padding-block: 3px;
        direction: rtl;
        &::before {
          position: absolute;
          content: "";
          width: 0;
          height: 0;
          border-top: 5px solid transparent;
          border-right: 8px solid var(--item-color);
          border-bottom: 5px solid transparent;
          right: -13px;
        }
        &[data-svgcolor="true"] {
          &::before {
            border-right-color: var(--primary-color);
          }
        }
        span[data-color="buy"] {
          color: var(--primary-color);
        }
        span[data-color="sell"] {
          color: var(--danger-color);
        }
        .title {
          font-size: 12px;
        }
      }
    }
    .more_btn {
      width: 35px;
      height: 35px;
      background: var(--third-color);
      border: 1px solid var(--main-color);
      border-radius: 50%;
      position: absolute;
      bottom: -30px;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      svg {
        color: var(--primary-color) !important;
        font-size: 28px;
      }
      &[data-active="true"] {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
}
// .coin_information {
//   margin-top: 14px;
//   width: 93%;
//   height: 225px;
//   position: relative;
//   left: 50%;
//   transform: translate(-50%);
//   border-radius: 8px;
//   .persian_coin_name {
//     position: absolute;
//     right: 12px;
//     height: 15px;
//     text-align: center;
//     color: rgba(146, 146, 146, 1);
//     font-weight: 600;
//     min-width: 60px;
//     background-color: rgba(252, 252, 252, 1);
//     font-size: 12.2px;
//     line-height: 12px;
//     font-family: "IranSansWeb";
//     border-radius: 0px 0px 3px 3px;
//     padding: 3px 10px;
//   }
//   .coin_24H_changes {
//     position: absolute;
//     left: 80px;
//     height: 19px;
//     width: 45px;
//     top: -3px;
//     font-size: 10.5px;
//     text-align: center;
//     color: #fff;
//     font-family: "IranSansWeb";
//     border-radius: 2px;
//     line-height: 21px;
//   }
//   .coin_change_positive {
//     background-color: $static_green;
//   }
//   .coin_change_negative {
//     background-color: $static_darkRed;
//   }
//   .description_button {
//     position: absolute;
//     left: 12px;
//     height: 19px;
//     width: 60px;
//     top: -3px;
//     text-align: center;
//     background-color: transparent;
//     color: $static-green;
//     border: 1px solid $static-green;
//     &:hover{
//       background: $static-green;
//       color: #fff;
//     }
//     font-size: 10px;
//     line-height: 19px;
//     font-family: "IranSansWeb";
//     border-radius: 2px;
//   }
//   .description_item {
//     width: 100%;
//     position: relative;
//     height: 20px;
//     top: 25px;
//     margin-top: 5px;
//     font-family: "IranSansWeb";
//     color: rgba(134, 132, 132, 1);
//     font-weight: 500;
//     .polygon_place {
//       position: absolute;
//       width: 7px;
//       height: 7px;
//       right: 0;
//       top: 7.2px;
//       img {
//         position: absolute;
//         right: 0;
//       }
//     }
//     .title_info_place {
//       text-align: right;
//       width: 112px;
//       font-size: 12px;
//       position: absolute;
//       top: 0px;
//       right: 10px;
//       direction: rtl;
//     }
//   }
//   .desc_info_place {
//     position: relative;
//     .symbol_place {
//       font-size: 11px;
//       margin-left: 12px;
//       margin-right: 5px;
//     }
//     .after_symbol_info {
//       height: 20px;
//       font-size: 14.5px;
//       font-weight: 600;
//       line-height: 10px;
//     }
//   }
// }

.font_color_green {
  color: $static_green;
}
.font_color_red {
  color: $static_darkRed;
}
//
