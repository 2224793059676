.container {
  background-color: var(--temp-color);
  border-radius: 3px;
  min-height: 37px;
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-direction: row-reverse;
  input,
  button {
    background-color: transparent;
    outline: none;
    border-style: none;
    font-family: inherit;
  }
  input {
    flex: 1;
    direction: rtl;
    padding-inline: 10px;
    color: var(--secondary-color);
    font-family: "IranYekanBold" !important;
    font-size: 15px;
    padding-top: 5px;
  }
  button {
    padding-inline: 10px;
    font-size: 12px;
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
  }
  .pasteBtn {
    color: var(--primary-color);
  }
  .cameraIcon {
    padding-top: 5px;
    svg {
      color: var(--secondary3-color) !important;
    }
  }
  .divider {
    background-color: var(--border-color);
    width: 1px;
  }
}
