.container{
    width: 100%;
    height: 25px;
    display: grid;
    grid-template-columns: repeat(4,24%);
    align-items: center;
    justify-content: space-between;
    grid-column-gap: 1%;
    text-align: center;
   
    .label{
        width: 100%;
        background: #FCFCFC;
        color: #837F7F;
        height: 25px;
        border-radius: 3px;
        font-size: 12.5px;
        line-height: 25px;
    }
}