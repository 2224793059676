.container {
  width: 100%;
  .header_btns {
    width: 100%;
    height: 40px;
    border-bottom: 1px solid #9d9ea8;
    .btn {
      position: relative;
      font-family: "IranSansWeb";
      outline: none;
      background: transparent;
      height: 40px;
      border-width: 0px;
      margin-right: 10px;
      font-size: 12px;
      padding-inline: 14px;
      color: #9d9ea8;
      &::before {
        content: "";
        position: absolute;
        display: none;
        width: 100%;
        height: 2px;
        background: #00c194;
        left: 0;
        bottom: -1px;
      }
    }
    .btn_active {
      color: #00c194;
      &::before {
        display: block;
      }
    }
  }
}
