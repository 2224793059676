@import "../../../../styles/_global.scss";
.container {
  width: 100%;
  position: relative;
  font-family: "IranSansWeb";
  overflow: hidden;
  margin-bottom: 110px;
  .addCardContainer{
    border: 3px solid var(--third2-color);
    padding: 5px;
    padding-block: 15px;
    margin-inline: 5px;
    p{
      margin: 0;
      color: var(--secondary-color);
      text-align: right;
    }
    
  }
  .inputs_container {
    position: relative;
    margin: 0 auto;
    margin-top: 25px;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    input {
      direction: rtl;
      font-family: "IranSansWeb";
      text-indent: 13px;
      width: 99%;
      background-color: rgba(196, 196, 196, 0.5);
      height: 36px;
      outline: none;
      border-width: 0px;
      border-radius: 3px;
      font-size: 14.5px;
      margin-bottom: 10px;
      // color: rgba(124, 119, 119, 0.99);
    }
    .btn_container {
      margin-top: 18px;
      width: 100%;
      display: grid;
      button {
        width: 100%;
        height: 40px;
        margin: 0 auto;
        outline: none;
        border-width: 0px;
        background-color: $static-green;
        border-radius: 3px;
        color: #fff;
        font-size: 17px;
        font-family: "IranSansWeb";

        &:disabled {
          background-color: rgb(184, 184, 184) !important;
        }
      }
    }
  }
}
.errors_place {
  color: $static_darkRed;
  font-size: 13px;
  direction: rtl;
  line-height: 18px;
}

.userCardsContainer {
  width: 100%;
  height: 85px;
  align-items:center !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  margin-bottom: 4px;
  .left {
    width: 65%;
    padding: 5px;
    box-sizing: border-box;
    div {
      margin-top: 5px;
    }
  }
  .left,
  .right {
    height:100%;
    flex-direction: column !important;
    align-items: center !important;
    justify-content: center !important;
    background: var(--third2-color);
  }
  .right {
    width: 34%;
    font-size: 12.5px;
  }
}
