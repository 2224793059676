@import "../../../styles/_global.scss";

.container {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  margin-bottom: 110px;
  .coin_pic {
    width: 50px;
    height: 50px;
    position: absolute;
    top: 5px;
    right: 20px;
  }
  .back_icon {
    top: 20px;
    position: relative;
    margin-bottom: 55px;
    img {
      position: absolute;
      left: 30px;
      transform: rotate(180deg);
    }
  }
  .coin_img {
    margin: 27px auto;
    width: 320px;
    height: 165px;
  }
  .select_buy_or_sell {
    direction: rtl;
    margin: 0 auto;
    margin-top: 10px;
    width: 90%;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    button {
      padding: 0px;
      border-width: 0px;
      width: 100%;
      height: 38px;
      outline: none;
      border-radius: 3px;
      color: #fff;
      font-family: "IranSansWeb";
      font-size: 13px;
      direction: rtl;
    }
    .buy_btn {
      background-color: $static-green;
    }
    .sell_btn {
      background-color: $static-darkRed;
    }
  }
  .place_of_chart {
    margin: 0 auto;
    width: 90%;
  }
  .coin_description {
    margin: 15px auto;
    width: 90%;
    direction: rtl;
    font-family: "IranSansWeb";
    font-size: 15.2px;
    word-spacing: 1px;
    row-gap: 10px;
    padding-right: 10px;
    padding-left: 10px;
    text-align: justify;
    line-height: 32px;
    p {
      line-height: 1.4rem;
    }
  }
}
.blur_read_more {
  position: relative;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  margin-bottom: 30px;
  .blur {
    width: 90%;
    margin: 0 auto;
    background-color: rgba(196, 196, 196, 0.9);
    height: 80px;
    filter: blur(8px);
  }
  .readFullContent {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%);
    font-family: "IranSansWeb";
    border: 1px solid $static-blue;
    color: $static-blue;
    display: inline-block;
    padding: 10px;
    border-radius: 3px;
    font-size: 14px;
    width: 50%;
    text-align: center;
    &:hover{
      background: $static-blue;
      color: #fff;
    }
  }
}
.hidden_other_text {
  height: 90px;
  overflow: hidden;
}
.coinNameFa {
  width: 100%;
  direction: rtl;
  font-size: 17px;
  font-family: "IranSansWeb";
  text-align: center;
  padding-top: 10px;
}
