.container {
  width: 100%;
  height: 100vh;
  background: #202328;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  padding-inline: 15px;
  box-sizing: border-box;
  .heading {
    direction: rtl;
    color: #fff;
    text-align: center;
    margin-top: 8px;
    opacity: 0.8;
    strong {
      font-size: 13px;
    }
    .v {
      font-family: IranYekanBold !important;
      font-size: 12px;
    }
  }
  .box {
    width: 100%;
    min-height: 100px;
    background: rgb(55, 55, 55);
    margin-top: 30px;
    padding: 15px;
    box-sizing: border-box;
    direction: rtl;
    p {
      font-size: 14px;
      color: #fff !important;
      margin-block: 4px;
      svg {
        color: var(--primary-color) !important;
      }
    }
  }
  .button {
    margin-block: 10px;
    button {
      width: 120px;
      background: var(--danger-color);
      font-family: "IranSansWeb";
      height: 30px;
      border-width: 0;
      border-color: none;
      outline: none;
      border-style: none;
      border-radius: 2px;
      color: #fff;
    }
  }
  .guid {
    position: absolute;
    bottom: 80px;
    left: 50%;
    transform: translate(-50%, 0);
    svg {
      font-size: 40px;
      color: #fff !important;
      transform: rotate(-90deg);
    }
  }
}
