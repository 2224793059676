.headerTexts {
  font-size: 12.5px;
  text-align: center;
  font-family: "IranSansWeb" !important;
  color: var(--secondary3-color);
  flex: 1;
  &[data-theme="error"] {
    color: var(--danger-color);
  }
  &[data-theme="success"] {
    color: var(--primary-color);
  }
  margin: 0;
}
.descText {
  margin: 0;
  flex: 1;
  font-size: 14px;
  text-align: center;
  font-family: "IranYekanBold" !important;
  color: var(--secondary3-color);
  &[data-theme="error"] {
    color: var(--danger-color);
  }
  &[data-theme="success"] {
    color: var(--primary-color);
  }
}
.label {
  font-size: 11px;
  margin-inline: 1px;
}
