@import "../../../../styles/_global.scss";
.container {
  width: 100%;
  position: relative;
  font-family: "IranSansWeb";
  .button_for_swap {
    width: 90%;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    margin: 0 auto;
    .button_swap {
      position: relative;
      width: 100%;
      height: 30px;
      text-align: center;
      line-height: 28px;
      border-bottom: 1px solid rgba(196, 196, 196, 1);
    }
    .button_swap_active {
      color: $static_green;
      border-bottom: 1px solid $static_green;
    }
  }
}
