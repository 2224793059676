.container {
  .type {
    position: relative;
    height: 33px;
    border-bottom: 2px solid #e1e2e4;
    button {
      position: relative;
      padding-inline: 10px;
      height: 33px;
      background: transparent;
      border-width: 0px;
      font-family: "IranSansWeb";
      color: #837f7f;
      cursor: pointer;
      font-size: 14.5px;
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
    .button_active {
      color: #00c194 !important;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #00c194;
        bottom: 0px;
        left: 0px;
      }
    }
    .icon_history {
      position: absolute;
      top: 0;
      left: 18px;
      font-size: 22px;
      color: #837f7f;
    }
  }
  .open_orders_section {
    width: 96%;
    margin: 0 auto;
    font-family: "IranSansWeb";
    .filter_buttons {
      width: 100%;
      margin-top: 4px;
      display: grid;
      grid-template-columns: 49% 49%;
      grid-column-gap: 2%;
      align-items: center;
    }
    .normal_orders {
      width: 100%;
      margin-top: 8px;
      border-bottom: 1px solid #e1e2e4;
      .one {
        display: grid;
        grid-template-columns: 55% 45%;
        justify-content: space-between;
        align-items: center;
        .name {
          font-size: 12.5px;
          margin-top: 7px;
          color: #837f7f;
          .icon_buy_sell {
            font-size: 20px;
            position: relative;
            top: 5px;
          }
        }
        .work {
          direction: ltr;
          position: relative;
          top: 5px;
          button {
            padding-inline: 15px;
            padding-block: 4px;
            font-size: 13px;
            outline: none;
            border-width: 0px;
            background: transparent;
            margin-right: 10px;
            border-radius: 3px;
            border: 1px solid;
            font-family: "IranSansWeb";
          }
          .cancel_btn {
            color: #ed6e72;
            border-color: #ed6e72;
          }
          .update_btn {
            color: #00c194;
            border-color: #00c194;
          }
        }
      }
      .second {
        display: grid;
        grid-template-columns: 33% 33% 33%;
        align-items: center;
        justify-content: space-between;
        color: #837f7f;
        font-size: 13px;
        margin-top: 3px;
        .date {
          margin-top: 2px;
        }
        .market_type {
          font-size: 12px;
          text-align: center;
          span {
            border: 1px solid currentColor;
            border-radius: 3px;
            padding-inline: 10px;
            padding-block: 3px;
          }
        }
        .area_price {
          margin-top: 2px;
          text-align: left;
        }
      }
      .three,
      .four {
        margin-top: 4px;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        align-items: center;
        justify-content: space-between;
        .status {
          text-align: left;
        }
        .titles {
          color: #837f7f;
          font-size: 12px;
          margin-bottom: 6px;
        }
        .titles ~ div {
          margin-top: -3px;
          font-size: 13px;
          color: #272e40;
        }
        .amount {
          text-align: right;
        }
        .dones {
          text-align: center;
        }
        .undones {
          text-align: left;
        }
        .trade_type {
          text-align: center;
        }
        .trade_type > .titles ~ div {
          margin-top: -5px;
        }
      }
    }
  }
  .check {
    margin-top: 2px;
  }
}
