.container {
  margin: 0 auto;
  width: 90%;
  position: relative;
  font-size: "IranSansWeb";
  .inputs_container {
    position: relative;
    width: 100%;
    margin-top: 25px;
    display: grid;
    grid-template-columns: auto;
    p {
      direction: rtl;
      font-size: 12.2px;
      color: rgba(124, 119, 119, 1);
    }
    .userCardLabel {
      background-color: var(--third2-color);
      border-radius: 3px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 7px;
      direction: rtl;
      font-family: "IranYekanBold" !important;
      font-weight: 400;
      min-height: 38px;
      p{
        margin: 0;
        margin-top: 3px;
        font-size: 16px;
      }
      color: var(--secondary3-color);
      svg{
        color: var(--secondary3-color);
        margin-top: 5px;
      }
    }
    .amount {
      padding: 0px;
      width: 100%;
      direction: rtl;
      height: 45px;
      border-width: 0px;
      outline: none;
      background-color: var(--third2-color);
      border-radius: 3px;
      font-family: "IranYekanBold" !important;
      text-indent: 10px;
      color: var(--secondary-color);
      font-size: 14px;
    }
    button {
      margin: 20px auto;
      position: relative;
      margin-bottom: 110px;
      width: 100%;
      height: 40px;
      outline: none;
      border-width: 0px;
      border-radius: 3px;
      font-size: 18px;
      font-weight: 400;
      font-family: "IranSansWeb";
      color: #fff;
      &:disabled {
        background-color: rgba(124, 119, 119, 0.92) !important;
      }
    }
  }
  .persian_price {
    margin-top: 5px;
    direction: rtl;
    color: rgba(124, 119, 119, 0.92);
    font-size: 13px;
  }
}

.openThis {
  display: block !important;
}

.user_cart_zero {
  font-size: 12px;
  direction: rtl;
}
