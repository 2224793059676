@import "../../../styles/_global.scss";

.BottomMenu {
  z-index: 2;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 80px;
  padding-bottom: 10px;
  background-color: var(--topLevel-color);
  box-shadow: 0px -4px 20px rgba(0, 0, 0, 0.08);
  -webkit-tap-highlight-color: transparent;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  align-items: center;
  font-family: "IranSansWeb";
  padding-inline: 3px;
  box-sizing: border-box;
  .markets_link {
    align-self: baseline;
    margin-top: -20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin-top: 5px;
      font-size: 13.5px;
      color: var(--primary-color);
    }
  }
  .markets {
    width: 55px;
    height: 55px;
    border-radius: 50%;
    background: var(--primary-color);
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    color: #fff;
    svg {
      font-size: 28px;
      color: #fff !important;
    }
  }
  .item_link {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .item_box {
      display: flex !important;
      align-items: center !important;
      svg {
        font-size: 24px;
        color: var(--secondary3-color) !important;
      }
      &[data-active="true"] {
        svg {
          color: var(--primary-color) !important;
        }
      }
      .active_cirlce {
        width: 8px;
        height: 8px;
        margin-bottom: 10px;
        background: var(--primary-color);
        border-radius: 50%;
      }
    }

    p {
      font-size: 10.8px;
      color: var(--secondary3-color);
    }
  }
}

@media screen and (max-height: 500px) {
  .BottomMenu {
    display: none;
  }
}
@media screen and (max-width: 340px) {
  .item_link {
    .item_box {
      svg {
        font-size: 24px !important;
      }
    }
    p {
      font-size: 11px !important;
    }
  }
}
.hiddenMenu {
  visibility: hidden;
}
