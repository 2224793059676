.LOADING_STYLES {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.35);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 4;
  .LOADING_ANIMATION_CONTAINER {
    position: absolute;
    top: 50%;
    z-index: 6;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.LOADING_STYLES_2 {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.85);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 4;
  .LOADING_ANIMATION_CONTAINER_2 {
    position: absolute;
    top: 50%;
    z-index: 6;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.LOADING_STYLES_3 {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.94);
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 10;
  .LOADING_ANIMATION_CONTAINER_3 {
    position: absolute;
    top: 50%;
    z-index: 6;
    left: 50%;
    transform: translate(-50%, -60%);
    text-align: center;
    img {
      width: 140px;
      height: 100px;
    }
    h2 {
      color: var(--primary-color);
      font-size: 12px;
      margin-top: -15px;
      font-family: "IranSansWeb";
    }
  }
}
