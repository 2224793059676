@import "../../../styles/_global.scss";
.ChoiceCoin_container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  background-color: white;
  .title_and_close_container {
    position: relative;
    width: 100%;
    background-color: $static_darkBlue;
    height: 45px;
    .container_for_width {
      margin: 0 auto;
      width: 90%;
      height: 45px;
      position: relative;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: end;
      grid-gap: 15px;
      font-family: "IranSansWeb";
      font-weight: 800;
      font-size: 14.5px;
      line-height: 47px;
      color: #fff;
      .title_choiceCoin {
        animation: textScale;
        animation-duration: 0.6s;
        animation-iteration-count: 1;
      }
      div {
        img {
          margin-top: 15px;
        }
      }
    }
  }

  //Main
  .Main_ChoiceCoin {
    position: relative;
    margin: 0 auto;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    .search_choiceCoin {
      width: 100%;
      .search_input_place {
        margin: 0 auto;
        margin-top: 18px;
        width: 95%;
        display: grid;
        grid-template-columns: auto;
        padding: 5px;
        overflow: hidden;
        position: relative;
        .search_coin_list {
          width: 100%;
          input {
            background-color: rgba(196, 196, 196, 0.1);
            width: 100%;
            font-family: "IranSansWeb";
            height: 1px;
          }
        }

        label {
          font-family: "IranSansWeb";
          right: 33px;
          top: -9px;
          font-size: 14px;
        }
        .icon_place {
          text-align: right;
          margin-top: -33px;
          margin-right: 8px;
        }

        .icon_place_active {
          filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
            brightness(88%) contrast(119%);
        }
      }
    }
    .lists_of_coins_container {
      z-index: 5;
      background-color: white;
      position: relative;
      width: 100%;
      .ul_of_list {
        display: grid;
        grid-template-columns: auto;
        .list_coin {
          margin: 0 auto;
          width: 95%;
          // height: 54px;
          position: relative;
          display: grid;
          grid-template-columns: auto auto;
          justify-content: end;
          grid-gap: 20px;
          margin-top: 5px;
          padding-bottom: 20px;
          &:hover {
            background-color: rgba(223, 223, 223,0.2);
            border-radius: 4px;
          }
          .coin_icon {
            width: 30px;
            height: 30px;
            border-radius: 50px;
            margin-top: 9px;
            margin-right: 7px;
          }
          .coin_persian_name {
            direction: rtl;
            margin-top: 16px;
            font-family: "IranSansWeb";
            font-weight: 600;
            font-size: 12.7px;
            max-width: 150px;
            text-align: right;
          }
          .coin_symbol_name_history {
            font-family: "IranSansWeb";
            font-weight: 600;
            font-size: 13px;
            position: absolute;
            margin-left: 5px;
            height: 20px;
            margin-top: 18px;
            max-width: 150px;
            .coin_eng_name {
              display: inline-block;
            }
            .must_used {
              margin-left: 5px;
              position: absolute;
              width: 20px;
              height: 20px;
              display: inline-block;
            }
          }
          .line_after_li {
            position: absolute;
            width: 95%;
            height: 1px;
            background-color: rgba(235, 231, 231, 0.3);
            bottom: 0;
            left: 50%;
            transform: translate(-50%);
          }
        }
      }
    }
  }
}

.invisible_container {
  visibility: hidden;
}
.loadingBar {
  height: 50vh;
  text-align: center;
  display: grid;
  align-items: center;
  direction: rtl;
  font-size: 18px;
  font-family: "IranSansWeb";
}

//Animations
@keyframes textScale {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.05);
  }
}
