.container {
  width: 100%;
  height: 75px;
  padding-inline: 15px;
  padding-top: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  direction: rtl;
  .market {
    display: grid;
    grid-template-columns: 13px auto;
    align-items: center;
    .arrow {
      font-size: 19px;
      position: relative;
      top: 4px;
      right: -5px;
      color: #9d9ea8;
    }
    .name {
      color: #01081e;
      h1 {
        margin: 0;
        font-size: 12.5px;
        font-family: "IranSansWeb";
        max-width: 90%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      strong {
        font-size: 10px;
      }
    }
  }
  .details {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    .percent {
      .box {
        width: 80%;
        min-width: 43px;
        height: 20px;
        border-radius: 2px;
        color: #fff;
        text-align: center;
        direction: ltr;
        font-size: 11px;
        line-height: 20px;
      }
    }
    .more {
      display: grid;
      grid-template-columns: repeat(4, 25%);
      justify-content: center;
      align-items: center;
      .isFav {
        position: relative;
        top: 2px;
        right: 1px;
        font-size: 27px;
        .NoLike {
          color: #01081e;
        }
        .Liked {
          color: rgb(220, 220, 25);
        }
      }
      .more_actions {
        position: relative;
        top: 4px;
        font-size: 27px;
        color: #01081e;
      }
    }
    .back {
      text-align: center;
      svg {
        color: var(--item-color) !important;
        font-size: 19px;
        margin-top: 5px;
      }
    }
  }
}
