$static_white: rgba(255, 255, 255, 0.94);
$static_green: #00c194;
$static_green_hover: rgba(13, 160, 93, 1);
$static_darkBlue: rgba(43, 63, 99, 1);
$static_gray: rgba(196, 196, 196, 0.1);
$static_red: rgba(244, 133, 133, 1);
$static_darkRed: rgba(246, 85, 85, 1);
$static_grayLight: rgba(196, 196, 196, 0.05);
$static_blue: #0090ff;

//# 202328
//# 191919
//# fff
:root {
  --primary-color: #00c194;
  --warning-color: rgb(255, 204, 0);
  --danger-color: rgba(246, 85, 85, 1);
  --secondary-color: #202020;
  --secondary2-color: #191919;
  --secondary3-color: #191919;
  --secondary4-color: #393939;
  --main-color: rgba(25, 25, 25, 0.2);
  --main2-color: #fff;
  --topLevel-color: #fff;
  --third-color: #eee;
  --third2-color: #ddd;
  --layout-color: #f2f8f6;
  --item-color: #868484;
  --sec4-color: rgb(77, 77, 77);
  --temp-color: #f2f8f6;
  --border-color: #ddd;
  --primaryOpacity-color: rgba(0, 193, 148, 0.2);
  --warningOpacity-color: rgba(255, 204, 0, 0.25);
  --dangerOpacity-color: rgba(246, 85, 85, 0.2);
}
html[data-theme="dark"] {
  --primary-color: #00c194;
  --warning-color: rgb(255, 204, 0);
  --danger-color: rgba(246, 85, 85, 1);
  --secondary-color: #e7e7e7;
  --secondary2-color: #e7e7e7;
  --secondary3-color: #b9b9b9;
  --secondary4-color: #b9b9b9;
  --main-color: rgba(255, 255, 255, 0.4);
  --main2-color: #212121;
  --topLevel-color: #202328;
  --third-color: #212121;
  --third2-color: #202328;
  --layout-color: #202328;
  --item-color: #fff;
  --sec4-color: #00c194;
  --temp-color: #202328;
  --border-color: #363636;
  --primaryOpacity-color: rgba(0, 193, 148, 0.1);
  --warningOpacity-color: rgba(255, 204, 0, 0.25);
  --dangerOpacity-color: rgba(246, 85, 85, 0.2);
}
.imber-attract-contacts {
  display: none;
}

.google-recaptcha-custom-class, .grecaptcha-badge{
  display: none!important;
}