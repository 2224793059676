.container {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0px;
  z-index: 5;
  font-family: "IranSansWeb";
}
.full_view {
  top: 0px;
}
.mini_view {
  top: 75px;
}
.bg {
  width: 100%;
  height: 100%;
  position: fixed;
  right: 0px;
  z-index: 1;
  background: rgba(187, 187, 187, 0.3);
}
.aside {
  width: 100%;
  height: 100%;
  position: fixed;
  transition: 0.5 right;
  z-index: 1;
  background: #fcfcfc;
  transition-property: right;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
}
.show_aside {
  right: 0px;
}
.hidden_aside {
  right: -100%;
}
//* -->
.box_search {
  width: 90%;
  margin: 0 auto;
  margin-top: 15px;
}
.market_filter {
  margin-top: 10px;
  width: 100%;
  min-height: 40px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.4);
  direction: rtl;
}
.btns {
  width: 95%;
  margin: 0 auto;
  display: flex;
  flex-direction: nowrap;
}
.item {
  position: relative;
  padding-inline: 20px;
  min-height: 40px;
  background: transparent;
  outline: none;
  border-width: 0px;
  color: #272e40;
  font-family: "IranSansWeb";
  img{
    position: relative;
    top: 5px;
    margin-left: 3px;
  }
}
.item_active {
  color: #00c194;
  &::before {
    content: "";
    position: absolute;
    background-color: #00c194;
    width: 100%;
    height: 2px;
    bottom: 0px;
    left: 0px;
    font-family: "IranYekanMobileBold";
  }
}

.market_table {
  width: 90%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  margin: 0 auto;
  margin-top: 20px;
  direction: rtl;
  .header {
    margin-bottom: 10px;
    font-size: 13.3px;
  }
  .list {
    margin-top: 5px;
    margin-bottom: 8px;
    padding-block: 4px;
    border-bottom: 1px solid var(--border-color);
    .left {
      display: grid;
      grid-template-columns: auto;
      align-items: center;
      img {
        position: relative;
        top: 3px;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: center;
      .box {
        text-align: center;
        width: 52px;
        height: 20px;
        line-height: 22px;
        direction: ltr;
        font-size: 12px;
        border-radius: 2px;
        color: #fff;
      }
    }
    .name_coin {
      .fa_coin {
        color: #272e40;
        color: var(--secondary4-color);
        font-size: 12px;
      }
      .market_name {
        color: #9d9ea8;
        .asset_symbol{
          color: var(--secondary-color);
          font-size: 15.5px;
        }
        .area{
          font-size: 11.5px;
        }
      }
    }
    .center {
      font-size: 14px;
    }
  }
  .header,
  .list {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    color: #272e40;
    .left {
      text-align: right;
    }
    .center {
      text-align: center;
    }
    .right {
      text-align: center;
    }
  }
}
.table_one {
  height: 100%;
  overflow: auto;
  &::-webkit-scrollbar {
    display: none;
  }
}
