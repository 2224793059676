.containerForShowMessage {
  position: fixed;
  background-color: rgba(121, 115, 115, 0.4);
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  z-index: 3;
}
.showMessageBox {
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  width: 80%;
  top: 50%;
  left: 50%;
  z-index: 3;
  transform: translate(-50%, -50%);
  border: 1px solid #f65555;
  border-radius: 5px;
}
.closeMessageBox {
  position: absolute;
  width: 25px;
  height: 25px;
  background-color: rgba(246, 85, 85, 1);
  top: -10px;
  right: 8px;
  border-radius: 50px;
  text-align: center;
  img {
    padding-top: 6px;
  }
}
.textOfMessage {
  position: relative;
  word-break: break-all;
  padding: 10px 0px;
  left: 20px;
  width: 90%;
  direction: rtl;
  text-align: center;
  color: rgba(246, 85, 85, 1);
  font-size: 13.5px;
  font-family: "IranSansWeb";
}
