.container {
  width: 100%;
  display: grid;
  grid-template-columns: 49% 49%;
  grid-column-gap: 2%;
  padding-inline: 5px;
  border-bottom: 1px solid #e1e2e4;
  height: 30px;
  margin-bottom: 2px;
  .empty {
  }
  .buttons {
    .list{
      width: 100%;
    }
    img {
      position: relative;
      right: -5px;
    }
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: center;
    .open_chart{
      font-size: 20px;
      position: relative;
      // top: 2px;
      svg{
        position: relative;
        top: 2px;
      }
    }
  }
}
.chart{
  width: 100%;
  height: 0px;
  transition: height 0.5s;
  opacity: 0;
}
.chart_show{
  opacity: 1;
  height: 290px;
}
