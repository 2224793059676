.container {
  background-color: #212121;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.input{
  width: 100%;
  height: 40px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #212121;
  padding-inline: 10px;
  outline-color: var(--primary-color);
  color:#000;
  direction: rtl;
  font-family: inherit;
  font-size: 16px;
  font-family: "IranYekanBold";
}