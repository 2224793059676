.container {
  width: 100%;
  min-height: 195px;
  direction: rtl;
  font-family: "IranSansWeb";
  .top {
    padding-inline: 15px;
    display: flex;
    // flex-direction: grid;
    justify-content: space-between;
    // grid-template-columns: 80% 30%;
    align-items: center;
    height: 70px;
    padding-top: 20px;
    box-sizing: border-box;
    .fake {
      width: 50px;
      svg {
        transform: rotate(-180deg);
        color: var(--secondary3-color)!important;
      }
    }
    .market {
      width: calc(100% - 100px);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      .arrow {
        font-size: 22px;
        position: relative;
        top: 1px;
        svg {
          color: var(--secondary3-color) !important;
        }
      }
      .name {
        color: #01081e;
        h1 {
          margin-left: 20px;
          box-sizing: border-box;
          font-size: 16.5px;
          font-family: "IranSansWeb";
          white-space: nowrap;
        }
      }
    }
    .isFavorite {
      font-size: 30px;
      width: 40px;
      text-align: center;
    }
    .NoLike {
      color: #9d9ea8 !important;
    }
    .Liked {
      color: rgb(220, 220, 25);
    }
  }
  .bottom {
    margin-top: 5px;
    padding-inline: 15px;
    padding-block: 15px;
    .price {
      margin-top: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .market_price {
        font-size: 15.5px;
        direction: ltr;
        display: flex;
        flex-direction: row;
        span{
          padding-inline: 3px;
          box-sizing: border-box;
        }
      }
      .area_title{
        color: #9d9ea8;
        font-size: 13.5px;
      }
    }
    .summary {
      .title {
        width: 100%;
        color: #9d9ea8;
        font-size: 12.5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-family: "IranYekanMobileBold";
      }
      .desc {
        word-break: break-all;
        color: #01081e;
        padding-top: 3px;
        padding-bottom: 10px;
      }
    }
  }
}
