.background_container {
  position: fixed;
  background-color: rgba(121, 115, 115, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 110vh;
  z-index: 3;
}
.notification_container {
  position: fixed;
  width: 290px;
  background-color: rgba(255, 255, 255, 1);
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 4;
  border-radius: 6px;
  display: grid;
  grid-template-columns: auto;
  .title_notification {
    font-family: "IRANYekanMobileBold";
    margin: 0 auto;
    margin-top: 10px;
  }
  .line_in_notification {
    width: 270px;
    height: 1px;
    margin: 15px auto;
    background-color: rgba(16, 208, 120, 1);
  }
  .network_permissions {
    width: 270px;
    margin: 0 auto;
    direction: rtl;
    font-size: 13px;
    font-family: "IranSansWeb";
    line-height: 25px;
  }
  .arzfi_notification {
    line-height: 25px;
    width: 270px;
    font-family: "IranSansWeb";
    margin: 0 auto;
    direction: rtl;
    font-size: 13px;
  }
  .button_understand {
    width: 95px;
    height: 30px;
    background-color: rgba(16, 208, 120, 1);
    outline: none;
    border-width: 0;
    border-radius: 3px;
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
    text-align: center;
    font-family: "IranSansWeb";
    color: #fff;
    &:disabled {
      background-color: rgba(0, 0, 0, 0.15);
    }
  }
}
