.container {
  width: 100%;
  height: 70px;
  background: var(--temp-color);
  border-radius: 14px;
  padding: 10px;
  box-sizing: border-box;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
  direction: rtl;
  .image {
    width: 40%;
    align-items: center !important;
    justify-content: center;
    margin-top: 3px;
    svg {
      width: 35px;
      height: 35px;
      color: var(--primary-color)!important;
    }
  }
  .text {
    width: 60%;
    text-align: center;
    h2 {
      color: var(--secondary-color);
      font-size: 14.5px;
    }
    h3 {
      font-size: 12.5px;
      color: var(--secondary4-color);
      opacity: 0.9;
    }
  }
}
