.PercentTrade_container {
  width: 100%;
//   height: 70px;
  display: grid;
  align-items: center;
  position: relative;
  .line_show {
    width: 100%;
    background: rgb(233, 233, 233);
    height: 10px;
    position: relative;
    .negative {
      position: absolute;
      background: #ed6e72;
      height: 10px;
      left: 0px;
    }
    .positive {
      right: 0px;
      position: absolute;
      background: #00c194;
      height: 10px;
    }
  }
  .percent_show {
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    direction: rtl;
    display: grid;
    .percent_buy,
    .percent_sell {
      display: grid;
      justify-content: start;
      grid-column-gap: 1%;
      align-items: center;
    }
    .percent_sell {
      direction: ltr;
    }
    .percent_buy_show{
        font-size: 10px;
        text-align: left;
        color: #00c194;
    }
    .percent_sell_show{
        font-size: 10px;
        text-align: right;
        color: #ed6e72;
    }
  }
}
