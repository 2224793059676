.warning_container {
  font-family: "IranSansWeb";
  box-sizing: border-box;
  width: 100%;
  min-height: 23px;
  background-color: rgba(246, 85, 85, 0.1);
  border-radius: 4px;
  border: 0.2px solid rgba(246, 85, 85, 0.4);
  padding-inline: 8px;
  padding-block: 7px;
  flex-direction: row !important;
  direction: rtl;
  .icon {
    width: 18px;
    height: 18px;
    margin-inline-end: 8px;
  }
  .title {
    color: rgba(246, 85, 85, 1);
    font-size: 13px;
    text-align: justify;
  }
}
