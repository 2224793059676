.cardContainer {
  width: 97%;
  height: 165px;
  direction: rtl;
  box-sizing: border-box;
  font-family: "IranYekanBold";
  padding-inline: 5px;
  border-radius: 5px;
  &[data-cardbg="null"] {
    background-color: #290076ff;
  }
  .header {
    display: flex;
    min-height: 35px;
    padding-bottom: 8px;
    padding-top: 8px;
    flex-direction: row;
    align-items: center;
    .bank_image_container {
      margin-left: 5px;
    }
    p {
      background: var(--topLevel-color);
      color: var(--secondary-color);
      margin: 0;
      padding-inline: 10px;
      padding-block: 2px;
      border-radius: 3px;
    }
  }
  .cardNo {
    font-size: 18px;
    color: #fff;
    text-align: center;
    padding-block: 5px;
    margin-top: 5px;
  }
  .cardIban {
    color: #fff;
    text-align: center;
    font-size: 15px;
    margin-top: 5px;
  }
  .cardStatus {
    display: flex;
    align-items: center;
    margin-top: 33px;
    padding-inline: 5px;
    flex-direction: row;
    font-size: 14px;
    div:nth-child(1) {
      margin-left: 5px;
      color: #fff;
    }
    div:nth-child(2) {
      color: var(--warning-color);
      &[data-status="APPROVED"] {
        color: var(--primary-color);
      }
      &[data-status="REJECTED"] {
        color: var(--danger-color);
      }
    }
  }
}

.noCardRegister {
  background: var(--third-color);
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--secondary-color);
}

.Skelete_Loading {
  width: 100%;
  height: 150px;
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    var(--third-color) 0%,
    var(--third-color) 35%,
    var(--third2-color) 45%,
    var(--third2-color) 55%,
    var(--third-color) 65%,
    var(--third-color) 100%
  );
  background-size: 400%;
  animation: loadingNoPic 1.5s infinite;
}
@keyframes loadingNoPic {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100%;
  }
}
