@import "../../../styles/_global.scss";

.container {
  position: relative;
  width: 100%;
  display: grid;
  .logo_container {
    position: relative;
    margin: 15px auto;
    width: 89px;
    height: 55px;
  }
  .headerStatus_container {
    position: relative;
    margin: 0 auto;
    width: 95%;
    height: 100px;
    .headerStatus_bg_container {
      position: relative;
      width: 100%;
      height: 70px;
      background: #e5f7f2;
      color: #000;
      border-radius: 4px;
      .user_name {
        width: 215px;
        height: 20px;
        font-size: 17px;
        font-family: "IranSansWeb";
        position: absolute;
        right: 18px;
        direction: rtl;
        top: 11px;
      }
      .status_acc {
        font-family: "IranSansWeb";
        direction: rtl;
        right: 18px;
        position: absolute;
        top: 43px;
        font-size: 12px;
      }
      .finish_verify_button {
        width: 95px;
        height: 20px;
        position: absolute;
        border-radius: 17px;
        border: 1px solid $static_green;
        color: $static_green;
        text-align: center;
        font-size: 11px;
        font-family: "IranSansWeb";
        line-height: 22px;
        bottom: 10px;
        right: 110px;
        &:hover {
          background-color: $static_green;
          color: #fff;
        }
      }
    }
    //USER PIC
    .user_pic {
      position: absolute;
      width: 75px;
      height: 75px;
      border-radius: 50px;
      background-color: $static_green;
      bottom: 3px;
      left: 20px;
      img {
        position: absolute;
        top: 13px;
        width: 75px;
        height: 75px;
        border-radius: 50px;
        background-position: center;
        background-size: cover;
      }
    }
  }
  .router_container {
    font-family: "IranSansWeb";
    height: 100%;
    margin-bottom: 110px;
    user-select: none;
    .list_container_type_one {
      &[data-active="true"] {
        background: var(--third-color);
        padding-bottom: 10px;
      }
      .open_list_container {
        width: 100%;
        min-height: 55px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 18px;
        box-sizing: border-box;
        direction: rtl;
        font-size: 15px;
        color: var(--secondary-color) !important;
        .svg {
          margin-top: 5px;
          svg {
            transition: all 0.25s;
            color: var(--secondary-color) !important;
            opacity: 0.7;
          }
          &[data-active="true"] {
            svg {
              transform: rotate(-90deg);
            }
          }
        }
        p {
          display: flex;
          align-items: center;
          span {
            margin-inline-end: 8px;
            svg {
              color: var(--secondary-color) !important;
              opacity: 0.7;
            }
          }
        }
      }
      .sub_container {
        display: flex;
        align-items: center;
        min-height: 50px;
        direction: rtl;
        color: var(--secondary-color) !important;
        padding-inline: 30px;
        font-size: 14px;
        flex-direction: row;
        span{
          margin-inline-end: 8px;
          svg{
            font-size: 18px;
            margin-top: 2px;
            color: var(--primary-color) !important;
          }
        }
      }
    }
    .list_container_type_two {
      direction: rtl;
      padding-inline: 18px;
      min-height: 55px;
      display: flex;
      align-items: center;
      font-size: 15px;
      color: var(--secondary-color) !important;
      p {
        display: flex;
        align-items: center;
        span {
          margin-inline-end: 8px;
          padding-top: 5px;
          svg {
            color: var(--secondary-color) !important;
            opacity: 0.7;
          }
        }
      }
    }
  }
}

/////////
//////////// -----> Modal Exit Styles
/////////
.user_exit_modal {
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  height: 100%;
  z-index: 3;
  .confirm_container {
    position: fixed;
    top: 48%;
    left: 50%;
    background-color: #fff;
    transform: translate(-50%, -50%) scale(0, 0);
    width: 85%;
    height: 130px;
    font-family: "IranSansWeb";
    border-radius: 3px;
    animation-name: openModalAnimation;
    animation-iteration-count: 1;
    animation-duration: 0.4s;
    animation-fill-mode: forwards;
    div:nth-child(1) {
      position: relative;
      width: 100%;
      text-align: center;
      margin-top: 20px;
      font-size: 14.5px;
    }
    div:nth-child(2) {
      margin: 0 auto;
      margin-top: 30px;
      width: 80%;
      button {
        width: 45%;
        margin-left: 5%;
        padding: 0px;
        border-radius: 3px;
        border-width: 0px;
        font-family: "IranSansWeb";
        height: 30px;
        color: #fff;
      }
      button:nth-child(1) {
        background-color: $static_darkRed;
      }
      button:nth-child(2) {
        background-color: $static-blue;
      }
    }
  }
}

@keyframes openModalAnimation {
  from {
    transform: translate(-50%, -50%) scale(0, 0);
  }
  to {
    transform: translate(-50%, -50%) scale(1, 1);
  }
}
