@import "../../../../styles/_global.scss";
.container {
  position: relative;
  width: 90%;
  margin: 0 auto;
  height: 500px;
  font-family: "IranSansWeb";
  .back {
    position: relative;
    width: 100%;
    height: 15px;
    margin-top: 19px;
    img {
      position: absolute;
      left: 10px;
      transform: rotate(180deg);
    }
  }
  .header_title {
    position: relative;
    width: 100%;
    text-align: center;
    height: 18px;
    font-size: 17px;
    margin-bottom: 20px;
  }

  .container_of_inputs {
    width: 100%;
    position: relative;
    display: grid;
    grid-template-columns: auto;
    input {
      margin-top: 16px;
      direction: rtl;
      background-color: rgba(196, 196, 196, 0.2);
      border-radius: 3px;
      border-width: 0px;
      height: 43px;
      outline: none;
      font-family: "IranSansWeb";
      font-size: 13.8px;
      text-indent: 12px;
      color: rgb(133, 127, 127);
      border: 1px solid rgba(196, 196, 196, 0.2);
      &:focus {
        border: 1px solid $static_green;
      }
    }
    button {
      margin-top: 16px;
      width: 100%;
      background-color: $static_darkBlue;
      border-width: 0px;
      outline: none;
      height: 38px;
      border-radius: 3px;
      color: #fff;
      font-size: 17px;
      font-family: "IranSansWeb";
      margin-bottom: 110px;
      &:disabled {
        background-color: rgb(134, 134, 134) !important;
      }
    }
    .error_container {
      direction: rtl;
      padding-top: 5px;
      padding-right: 13px;
      font-family: "IranSansWeb";
      font-size: 12px;
      color: $static_red;
    }
  }
}
