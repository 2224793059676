@mixin ButtonStyle($width) {
  button {
    min-width: $width;
    outline: none;
    background: var(--primary-color);
    color: #fff;
    font-family: "IranSansWeb";
    border: 0;
    padding-block: 5px;
    border-radius: 3px;
    &[data-type="sell"] {
      background: var(--danger-color);
    }
  }
}
.container {
  width: 100%;
  min-height: 300px;
  background: var(--temp-color);
  border-radius: 14px;
  padding-bottom: 10px;
  padding-top: 2px;
  box-sizing: border-box;
  .filter_container {
    box-sizing: border-box;
    width: 100%;
    min-height: 30px;
    padding-inline: 5px;
    border-bottom: 1px solid var(--border-color);
    direction: rtl;
    flex-direction: row;
    button {
      font-family: "IranSansWeb";
      border-width: 0;
      background: transparent;
      cursor: pointer;
      color: var(--secondary-color);
      opacity: 0.9;
      padding-inline: 10px;
      padding-block: 13px;
      outline: none;
      font-size: 13.5px;
      -webkit-tap-highlight-color: transparent;
      border-bottom: 1px solid transparent;
      &[data-active="true"] {
        opacity: 1;
        color: var(--primary-color);
        border-bottom: 1px solid var(--primary-color);
      }
      &[data-index="1"] {
        &[data-active="true"] {
          opacity: 1;
          color: var(--danger-color);
          border-bottom: 1px solid var(--danger-color);
        }
      }
      &[data-index="3"] {
        color: var(--warning-color);
        &[data-active="true"] {
          opacity: 1;
          border-bottom: 1px solid var(--warning-color);
        }
      }
    }
  }
  .head {
    margin-top: 5px;
    text-align: center;
    padding-block: 5px;
    padding-inline: 3px;
    align-items: center;
    color: var(--secondary-color);
    opacity: 0.8;
    font-size: 13px;
  }
  .body {
    margin-top: 3px;
    text-align: center;
    padding-inline: 3px;
  }
  .item {
    user-select: none;
    min-height: 50px;
    align-items: center !important;
    padding-block: 3px;
    justify-content: center !important;
  }
  .percent_box {
    width: 100%;
    max-width: 70px;
    border-radius: 3px;
    background: var(--danger-color);
    color: #fff;
    height: 25px;
    justify-content: center;
    font-size: 13px;
    direction: ltr;
    span {
      margin-top: 1px;
    }
    &[data-positive="true"] {
      background: var(--primary-color);
    }
  }
  .value {
    color: var(--secondary-color);
    font-size: 14px;
  }
  .symbol {
    color: var(--secondary-color);
    font-size: 13px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-inline: 3px;
    direction: rtl;
    box-sizing: border-box;
    span {
      width: 50%;
      text-align: left;
    }
    strong {
      width: 50%;
      margin-inline-start: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: right;
      //   word-break: break-all;
    }
  }
  .fast_order_buttons {
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-items: center !important;
    justify-content: center !important;
    column-gap: 2px;
    row-gap: 4px;
    @include ButtonStyle(70px);
  }
  .coin_container {
    &[data-active="true"] {
      background: var(--third-color);
    }
  }
  .link_fast_order {
    a {
      width: 50%;
    }
    width: 100%;
    min-height: 35px;
    padding-bottom: 5px;
    flex-direction: row !important;
    padding-inline: 5px;
    column-gap: 5px;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    @include ButtonStyle(100%);
    button{
      min-height: 35px;
    }
  }
  .more_fast_orders{
    color: var(--secondary-color);
    margin-top: 10px;
  }
}
