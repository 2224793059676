@import "../../../styles/_global.scss";
.container {
  grid-template-columns: auto;
  position: relative;
  display: grid;
  width: 100%;
  font-family: "IranSansWeb";
  .BackArrow {
    position: relative;
    width: 100%;
    height: 15px;
    margin-top: 20px;
    img {
      width: 15px;
      height: 15px;
      left: 22px;
      transform: rotate(180deg);
      position: absolute;
    }
  }
  .title_component {
    position: relative;
    width: 100%;
    height: 20px;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 42px;
  }
  .section_of_setting {
    width: 100%;
    background-color: rgba(250, 250, 250, 1);
    height: 38px;
    margin-bottom: 7px;
    position: relative;
  }
  .part_of_setting {
    margin: 0 auto;
    width: 77%;
    height: 38px;
    direction: rtl;
    position: relative;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: space-between;
    font-size: 12.5px;
    line-height: 38px;
    div:nth-child(1) {
      animation-name: ScaleArrow;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
    }
    div:nth-child(2) {
      animation-name: ScaleArrow;
      animation-duration: 0.4s;
      animation-iteration-count: 1;
      color: rgba(124, 119, 119, 1);
    }
    .iconOpenCheckbox {
      position: absolute;
      left: -25px;
      top: 11px;
      width: 15px;
      height: 15px;
      img {
        position: absolute;
        top: 0;
      }
    }
  }
}
//Down Code For CheckBox Menu's
.bg_of_menu {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  z-index: 3;
}
.menu_choice_app_language_setting_container {
  position: fixed;
  background-color: rgba(255, 255, 255, 1);
  bottom: -170px;
  width: 100%;
  height: 170px;
  z-index: 4;
  border-radius: 13px 13px 0px 0px;
  font-family: "IranSansWeb";
  .setting_title {
    margin-top: 15px;
    text-align: center;
  }
  .setting_input_container {
    width: 100%;
    position: relative;
    margin-top: 10px;
    margin-bottom: 10px;
    input {
      display: none;
    }
    .persian_c_container {
      position: relative;
      left: 50%;
      transform: translate(-50%);
      background-color: rgba(196, 196, 196, 0.3);
      border: 1px solid rgba(196, 196, 196, 0.3);
      width: 90%;
      height: 38px;
      border-radius: 3px;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: space-between;
      direction: rtl;
      color: rgba(124, 119, 119, 1);
      line-height: 38px;
      font-size: 13px;
      text-indent: 20px;
      margin-bottom: 15px;
      div:nth-child(2) {
        position: absolute;
        width: 13px;
        height: 13px;
        border: 2px solid rgba(181, 176, 176, 1);
        left: 10px;
        top: 10px;
      }
    }
    input:checked ~ div {
      border: 1px solid $static_green;
      div:nth-child(2) {
        border: 2px solid $static_green;
        background-color: $static_green;
        &::before {
          position: absolute;
          left: -6px;
          top: 40%;
          height: 50%;
          width: 3px;
          background-color: #fff;
          content: "";
          transform: translateX(10px) rotate(-45deg);
          transform-origin: left bottom;
        }

        &::after {
          position: absolute;
          left: -5px;
          bottom: 1px;
          height: 3px;
          width: 100%;
          background-color: #fff;
          content: "";
          transform: translateX(10px) rotate(-45deg);
          transform-origin: left bottom;
        }
      }
    }
  }
}

.block {
  display: block;
}
.none {
  display: none;
}
.goToDown {
  transition: bottom 0.4s;
  bottom: -170px;
}
.goToUp {
  transition: bottom 0.4s;
  bottom: 0px;
}

//
//Animation for this page
@keyframes ScaleArrow {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
