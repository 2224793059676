.container {
  width: 100%;
  height: 45px;
  background: var(--third-color);
  border-radius: 5px;
  position: relative;
  display: flex;
  box-sizing: border-box;
  input {
    width: calc(100% - 110px);
    padding-inline: 10px;
    border-width: 0;
    outline: none;
    font-family: "IranYekanBold" !important;
    background-color: transparent;
    color: var(--secondary-color);
    font-size: 16px;
    direction: ltr;
  }
  button {
    position: absolute;
    background: var(--primary-color);
    outline: none;
    border-radius: 5px;
    min-width: 80px;
    right: 5px;
    height: 30px;
    border-width: 0;
    font-family: "IranYekanBold" !important;
    color: #fff;
    font-size: 12px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    top: 50%;
    transform: translate(0, -50%);
  }
}
