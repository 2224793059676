.container {
  input[type="file"] {
    display: none;
  }
  .label {
    width: 100%;
    border: 3px dashed var(--border-color);
    min-height: 50px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    direction: rtl;
    padding: 5px 10px;
    color: var(--secondary-color);
    span {
      font-size: 14px;
    }
    svg {
      margin-inline-end: 10px;
      color: var(--secondary-color) !important;
    }
    &[data-visiblity="true"] {
      display: none;
    }
  }
  button[type="reset"] {
    font-family: "IranSansWeb";
    border-radius: 2px;
    padding: 5px 12px;
    outline: transparent;
    width: 100%;
    border-style: outset;
    border-color: rgba(0, 0, 0, 0.2);
    color: var(--danger-color);
    background-color: var(--dangerOpacity-color);
  }
}
