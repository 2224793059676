.container {
  width: 100%;
  position: fixed;
  z-index: 3;
  bottom: -120%;
  height: 100vh;
  left: 0;
  box-sizing: border-box;
  &[data-visible="true"] {
    bottom: 0;
  }
}
.bg {
  position: fixed;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100%;
}
.content {
  position: fixed;
  width: 100%;
  bottom: -120%;
  transition-property: bottom;
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(1, 0, 0, 1);
  &[data-visible="true"] {
    bottom: 0;
  }
}
