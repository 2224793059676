.container {
  width: 100%;
  height: 50px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: space-between;
  font-family: "IranSansWeb";
  .filterButton{
    text-align: left;
    padding-left: 10px;
    .name{
      font-size: 14px;
      padding-left: 5px;
      position: relative;
      top: -2.5px;
    }
    .icon{
        font-size: 17px;
        position: relative;
        top: 3px;
    }
    .button{
        padding-right: 5px;
    }
  }
  .title{
    padding-right: 10px;
    font-size: 13px;
    span{
        font-size: 22px;
        position: relative;
        top: 7.5px;
        padding-left: 5px;
        padding-right: 8px;
    }
  }
}
