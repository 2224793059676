.container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  font-family: "IranSansWeb";
  margin-bottom: 110px;
  .back_btn {
    position: relative;
    background-color: red;
    margin-top: 20px;
    img {
      position: absolute;
      left: 30px;
      transform: rotate(180deg);
      top: 0;
    }
  }
  .btn_last_withdraw {
    width: 90%;
    margin: 0 auto;
    margin-top: 13px;
    button {
      direction: rtl;
      font-family: "IranSansWeb";
      border-width: 0px;
      border-radius: 3px;
      padding: 7px 17px;
      font-size: 11px;
      line-height: 18px;
      background-color: #2c4065;
      color: #fff;
      outline: none;
      &:hover {
        background: #3e5785;
      }
    }
  }
  .page_title {
    position: relative;
    margin: 35px auto;
    margin-bottom: 0px;
  }
  .container_user_balance {
    position: relative;
    width: 90%;
    background-color: rgba(196, 196, 196, 0.25);
    display: grid;
    grid-template-columns: auto;
    margin: 28px auto;
    margin-top: 5px;
    border-radius: 3px;
    .head_of_table_titles,
    .user_moneys {
      width: 100%;
      position: relative;
      display: grid;
      grid-template-columns: auto auto auto;
      .head_title {
        width: 100%;
        position: relative;
        height: 24px;
        text-align: center;
        line-height: 25px;
        font-size: 11px;
        border-bottom: 1px solid rgba(124, 119, 119, 1);
        color: rgba(124, 119, 119, 1);
      }
      .lock_balance,
      .all_balance,
      .removable_balance {
        width: 100%;
        position: relative;
        height: 24px;
        text-align: center;
        line-height: 25px;
        font-size: 11px;

        .forMoneysGrid {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          grid-gap: 2px;
        }
      }
    }
  }
  .inputs_container {
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    .withdraw_amount_input {
      position: relative;
      width: 100%;
      input {
        width: 99%;
        height: 48px;
        background-color: rgba(196, 196, 196, 0.5);
        outline: none;
        border-width: 0px;
        direction: rtl;
        font-family: "IranSansWeb";
        text-indent: 13px;
        border-radius: 3px;
      }
      .all_balance_btn {
        position: absolute;
        top: 12px;
        left: 5px;
        width: 70px;
        height: 27px;
        border: 1px solid rgba(44, 64, 101, 0.5);
        border-radius: 5px;
        font-size: 10px;
        text-align: center;
        line-height: 23px;
        color: rgba(44, 64, 101, 1);
        &:hover,
        &:active {
          background-color: rgba(44, 64, 101, 1);
          color: #fff;
        }
      }
    }
    .choice_shaba_title {
      margin-top: 5px;
      font-size: 11px;
      text-align: right;
      margin-bottom: 3px;
      color: rgba(28, 39, 48, 1);
    }
    .btn_for_submit {
      width: 100%;
      margin-top: 40px;
      button {
        width: 100%;
        background-color: rgba(44, 64, 101, 1);
        color: #fff;
        font-family: "IranSansWeb";
        outline: none;
        height: 36px;
        border-radius: 3px;
        border-width: 0px;
        &:hover {
          box-shadow: 1px 2px 2px 1px rgb(95, 95, 95, 0.2);
        }
        &:disabled {
          background-color: rgba(124, 119, 119, 1);
        }
      }
    }
  }
}
//
.persian_price {
  margin-top: 5px;
  direction: rtl;
  color: rgba(124, 119, 119, 0.92);
  font-size: 13px;
}
.openThis {
  display: block !important;
}
//* --> Create Custom Modal For Show Last Bank-Withdrawal
.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  z-index: 3;
  .lists {
    position: fixed;
    width: 90%;
    height: 80vh;
    overflow: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 5px;
    direction: rtl;
    font-family: "IranSansWeb";
    .close_btn {
      margin-top: 10px;
      margin-right: 10px;
    }
    .list {
      margin-bottom: 10px;
      padding: 10px;
      background: #fff;
      border-radius: 5px;
      display: grid;
      grid-template-columns: 40% 60%;
      align-items: center;
      word-break: break-all;
      font-size: 12px;
      div {
        padding-block: 10px;
      }
      div:nth-child(even) {
        text-align: left;
      }
    }
  }
  .bg {
    position: fixed;
    width: 100%;
    height: 100vh;
    background: rgba(78, 78, 78, 0.6);
  }
}

.must_verify {
  width: 100%;
  background: var(--primary-color);
  min-height: 35px;
  border-radius: 5px;
  font-size: 13px;
  color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.userCardLabel {
      background-color: var(--third-color);
      border-radius: 3px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: space-between;
      padding: 4px 5px;
      direction: rtl;
      font-family: "IranYekanBold" !important;
      font-weight: 400;
      min-height: 38px;
      p{
        margin: 0;
        margin-top: 3px;
        font-size: 15px;
      }
      color: var(--secondary3-color);
      svg{
        color: var(--secondary3-color);
        margin-top: 5px;
      }
    }