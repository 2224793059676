.container {
  width: 100%;
  height: 50px;
  padding-inline: 15px;
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
  justify-content: space-between;
  .home,
  .back {
    font-size: 35px;
    color: #9d9ea8;
    position: relative;
    top: 3px;
  }
  .back {
    text-align: right;
    svg {
      transform: rotate(180deg);
    }
  }
}
