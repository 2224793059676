//##
.container {
  position: fixed;
  box-sizing: border-box;
  width: 100%;
  height: calc(100vh);
  background: var(--third-color);
  z-index: 99;
  transition: right 0.33s;
  right: -100%;
  padding-inline: 10px;
  &[data-show="true"] {
    right: 0;
  }
  .head {
    height: 105px;
    margin-top: 25px;
  }
  .list {
    height: calc(100vh - 200px);
  }
  &[data-ispwa="true"] {
    .head {
      margin-top: 15px;
    }
    .list {
      height: calc(100vh - 140px);
    }
  }
  .close_box {
    width: 100%;
    padding-inline: 2px;
    svg {
      color: var(--secondary3-color) !important;
      margin-top: 10px;
    }
  }
  .search_box {
    width: 100%;
    padding-inline: 5px;
    margin-top: 10px;
    margin-bottom: 5px;
    box-sizing: border-box;
  }
  .list {
    overflow: auto;
  }
}
