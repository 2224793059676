.container {
  width: 100%;
  height: 68px;
  display: grid;
  grid-template-columns: 27% 73%;
  align-items: center;
  background: #fcfcfc;
  border-radius: 3px 3px 0px 0px;
  direction: rtl;
  .alert {
    padding-inline: 15px;
    font-size: 30px;
    color: #9d9ea8;
    position: relative;
    top: 3px;
    .setting{
      font-size: 28px;
      margin-right: 5px;
    }
  }
  .buttons {
    padding-inline: 15px;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
  }
}
