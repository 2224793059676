.container {
  width: 100%;
  min-height: 45px;
  flex-direction: row !important;
  justify-content: space-between !important;
  align-items: center !important;
  padding-inline: 13px;
  box-sizing: border-box;
  overflow: hidden;
  .logo {
    width: 90px;
  }
  .user_details {
    direction: rtl;
    width: calc(100% - 90px);
    flex-direction: row !important;
    align-items: center !important;
    .profile {
      margin-inline-end: 10px;
      width: 45px;
      height: 45px;
      opacity: 0.9;
      svg {
        color: var(--primary-color) !important;
        font-size: 45px;
      }
    }
  }
  .card_container {
    flex-direction: row !important;
    align-items: center;
    max-width: calc(100% - 60px);
    direction: rtl;
    .card {
      width: 30px;
      height: 30px;
      align-items: center !important;
      justify-content: center !important;
      border-radius: 50%;
      border: 3px solid var(--primary-color);
      svg {
        font-size: 25px;
        color: var(--primary-color)!important;
      }
    }
    .balance {
      padding-inline: 5px;
      width: fit-content;
      max-width: calc(100% - 50px);
      margin-inline-start: 5px;
      padding-inline: 5px;
      min-width: 100px;
      overflow: hidden;
      padding-block: 5px;
      direction: rtl;
      border-radius: 3px;
      font-size: 13px;
      font-family: "IranSansWeb";
      background: rgba(0, 193, 148, 0.6);
      color: #fff;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      p {
        padding-inline: 5px;
        direction: ltr;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-family: "IranYekanMobileRegular";
        &[data-num="true"] {
          font-size: 14.5px;
          margin-top: 1px;
        }
      }
    }
  }
}
