.override-zaman {
  background-color: var(--third-color) !important;
  border-color: var(--border-color) !important;
  div {
    color: var(--secondary-color) !important;
  }
  .zm-Header {
    background-color: var(--third-color) !important;
    border-color: var(--border-color) !important;
    .zm-IconPrevButton,
    .zm-MonthYearButton,
    .zm-IconNextButton {
      color: var(--secondary-color) !important;
      &:hover {
        background-color: rgba(0, 0, 0, 0.2) !important;
      }
    }
  }
  .css-sp254y {
    div {
      color: var(--primary-color) !important;
    }
  }
  .zm-DaysButton {
    &[data-start-range="true"],
    &[data-end-range="true"] {
      background-color: var(--primary-color) !important;
      &::before {
        display: none;
      }
    }
    &[data-in-range="true"] {
      background-color: rgba(0, 193, 148, 0.1) !important;
      &::before {
        display: none;
      }
    }
  }
}
.input-zaman-override {
  background-color: var(--third-color);
  border-width: 0;
  border-radius: 2px;
  outline: transparent;
  font-family: "IranSansWeb";
  padding: 10px 10px;
  color: var(--secondary-color);
  &::placeholder {
    color: var(--secondary-color);
    opacity: 0.78;
  }
  text-align: center;
}
