.container {
  width: 100%;
  // height: 215px;
  direction: rtl;
  font-family: "IranSansWeb";
  .type {
    height: 30px;
    border-bottom: 2px solid #e1e2e4;
    button {
      position: relative;
      width: 90px;
      height: 30px;
      background: transparent;
      border-width: 0px;
      font-family: "IranSansWeb";
      color: #837f7f;
      cursor: pointer;
      font-size: 14.5px;
      outline: none;
      -webkit-tap-highlight-color: transparent;
    }
    .button_active {
      color: #00c194 !important;
      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #00c194;
        bottom: 0px;
        left: 0px;
      }
    }
  }
  .table_header {
    width: 100%;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    justify-content: space-between;
    padding-block: 9px;
    font-size: 14.5px;
    color: #01081e;
    div:nth-child(3) {
      padding-left: 15px;
      text-align: left;
    }
    div:nth-child(1) {
      text-align: right;
      padding-right: 15px;
    }
    div:nth-child(2) {
      text-align: center;
    }
  }
  .table_body_orders {
    width: 100%;
    height: 154px;
    display: grid;
    grid-template-columns: 50% 50%;
    overflow: hidden;
    .buy_table .item,
    .sell_table .item {
      display: grid;
      grid-template-columns: 49% 49%;
      align-items: center;
      grid-column-gap: 2%;
      padding-top: 5px;
      padding-bottom: 5px;
      font-size: 12.8px;
    }
    .buy_table .item,
    .sell_table .item {
      position: relative;
      div:nth-child(2) {
        width: 99%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        text-align: left;
        padding-left: 4px;
      }
      div:nth-child(1) {
         overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding-right: 4px;
      }
    }
    .line_red {
      position: absolute;
      z-index: -1;
      height: 100%;
      background: rgba(237, 110, 114, 0.24);
      right: 0;
    }
    .line_green {
      left: 0;
      position: absolute;
      z-index: -1;
      height: 100%;
      background: rgba(0, 193, 148, 0.16);
    }
  }
  .table_body_trades {
    width: 100%;
    height: 155px;
    overflow: hidden;
    .item {
      display: grid;
      grid-template-columns: 33% 33% 33%;
      align-items: center;
      padding-block: 3px;
      color: #272E40;
      font-size: 14px;
      div:nth-child(1) {
        text-align: right;
        padding-right: 3px;
      }
      div:nth-child(2) {
        text-align: center;
      }
      div:nth-child(3) {
        text-align: left;
        padding-left: 3px;
      }
    }
  }
}
