@import "../../../../styles/_global.scss";
.container {
  grid-template-columns: auto;
  position: relative;
  margin: 0 auto;
  display: grid;
  width: 95%;
  font-family: "IranSansWeb";
  .back {
    position: relative;
    width: 100%;
    height: 15px;
    margin-top: 19px;
    img {
      position: absolute;
      left: 10px;
      transform: rotate(180deg);
    }
  }
  .header_title {
    position: relative;
    width: 100%;
    text-align: center;
    height: 18px;
    font-size: 17px;
    margin-bottom: 20px;
  }
  main {
    margin-bottom: 110px;
  }
}
//AuthenticationFields
.AuthenticationFields_container {
  direction: rtl;
  .part_of_info {
    .item_status {
      border-bottom: 1px solid rgba(124, 119, 119, 0.5);
      box-sizing: border-box;
      min-height: 50px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-direction: row;
      color: var(--secondary-color);
      padding-block: 10px;
      padding-inline: 5px;
      .grid_item {
        position: relative;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;
        direction: rtl;
        padding-right: 20px;
        padding-left: 25px;
        font-size: 12.5px;
        line-height: 45px;
        color: rgba(124, 119, 119, 1);
        .polygon_icon {
          border-top: 5.5px solid transparent;
          border-bottom: 5.5px solid transparent;
          border-right: 8px solid $static_darkBlue;
          position: absolute;
          top: 15px;
          right: 0;
        }
        .status_with_img {
          position: absolute;
          left: 3px;
          top: 11px;
          img {
            position: absolute;
            top: 0;
            left: 0;
          }
        }
        .status_overview {
          line-height: 22px !important;
        }
      }
      .input_verification {
        position: relative;
        direction: rtl;
        width: 100%;
        display: flex;
        flex-direction: row;
        max-width: 400px;
        input {
          width: 100%;
          color: var(--secondary-color);
          font-size: 16px;
          border-width: 0px;
          outline: none;
          border-top-right-radius: 3px;
          border-bottom-left-radius: 3px;
          min-height: 40px;
          text-indent: 10px;
          font-family: "IranSansWeb";
          background-color: var(--topLevel-color);
        }
        button {
          width: 75px;
          min-height: 38px;
          background-color: $static_green;
          border: none;
          border-top-left-radius: 3px;
          border-bottom-left-radius: 3px;
          color: #fff;
          font-family: "IranSansWeb";
          outline: none;
          &:disabled {
            background-color: rgba(0, 0, 0, 0.2) !important;
            color: rgba(100, 95, 95, 0.76) !important;
            border: none !important;
          }
        }
      }
    }
    .item_status:last-child {
      border-bottom: none;
    }
  }
}
.button_to_fill_form,
.button_to_fill_form2,
.button_to_fill_form3 {
  border-width: 0px;
  border: 1px solid $static_green;
  border-radius: 5px;
  height: 40px;
  font-size: 12.1px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $static_green;
  font-family: "IranSansWeb";
  outline: none;
  &:active {
    background-color: $static_green;
    color: #fff;
  }
  &:disabled {
    background-color: rgba(0, 0, 0, 0.2) !important;
    color: rgba(100, 95, 95, 0.76) !important;
    border: none !important;
  }
  &:hover {
    background-color: $static_green;
    color: #fff;
  }
}

.checkbox {
  width: 30px;
  height: 20px;
  border: 1px solid rgb(100, 95, 95);
  border-radius: 5px;
}
.read_email_checkbox:checked ~ .checkbox {
  background-color: $static_green;
  border: 1px solid $static_green;
}

///////
///////
///////
///////
//ID - CARD STYLES
.container_of_id_card {
  grid-template-columns: auto;
  position: relative;
  margin: 0 auto;
  display: grid;
  width: 95%;
  font-family: "IranSansWeb";
  .back {
    position: relative;
    width: 100%;
    height: 15px;
    margin-top: 19px;
    img {
      position: absolute;
      right: 10px;
    }
  }
  .header_title {
    position: relative;
    width: 100%;
    text-align: center;
    height: 18px;
    font-size: 17px;
    margin-bottom: 20px;
  }
  main {
    margin-bottom: 110px;
    position: relative;
    .header_of_main_title {
      text-align: center;
      font-size: 13px;
    }
    .guide_to_send_files {
      margin-top: 18px;
      direction: rtl;
      font-size: 12px;
      line-height: 18px;
      height: 55px;
    }

    .section_of_input {
      margin-top: 20px;
      width: 100%;
      height: 105px;
      input {
        display: none;
      }
      .label_for_input_file {
        background-color: rgba(196, 196, 196, 0.18);
        position: relative;
        width: 100%;
        height: 105px;
        border: 2px dashed rgba(44, 64, 101, 0.5);
        .icon_card {
          z-index: -1;
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .text_icon_card {
          position: absolute;
          display: grid;
          justify-content: center;
          grid-template-columns: auto auto;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
          width: 100%;
          font-size: 12.5px;
          line-height: 20px;
          grid-column-gap: 5px;
          color: rgba(44, 64, 101, 1);
        }
      }
    }
    .pic_uploaded_info {
      margin-top: 9px;
      width: 100%;
      height: 15px;
      color: rgba(124, 119, 119, 1);
      direction: rtl;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: end;
      font-size: 10.5px;
      grid-column-gap: 10px;
      div:nth-child(1) {
        color: $static_green;
      }
    }
    .button_container {
      width: 100%;
      position: relative;
      margin: 0 auto;
      margin-top: 25px;
      text-align: center;
      button {
        width: 100%;
        color: #fff;
        background-color: $static_darkBlue;
        border-width: 0px;
        outline: none;
        border-radius: 3px;
        height: 38px;
        font-family: "IranSansWeb";
        font-size: 16px;
        &:disabled {
          background-color: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
  }
}

/////////////
//////////
/////////////
/////////
//Person Pic Styles
.container_of_person_pic {
  grid-template-columns: auto;
  position: relative;
  margin: 0 auto;
  display: grid;
  width: 95%;
  font-family: "IranSansWeb";
  .back {
    position: relative;
    width: 100%;
    height: 15px;
    margin-top: 19px;
    img {
      position: absolute;
      right: 10px;
    }
  }
  .header_title {
    position: relative;
    width: 100%;
    text-align: center;
    height: 18px;
    font-size: 17px;
    margin-bottom: 20px;
  }
  main {
    margin-bottom: 110px;
    position: relative;
    .header_of_main_title {
      text-align: center;
      font-size: 13px;
    }
    .guide_to_send_files {
      margin-top: 18px;
      direction: rtl;
      font-size: 12px;
      line-height: 18px;
      height: 75px;
    }

    .section_of_input {
      margin-top: 5px;
      width: 100%;
      height: 105px;
      input {
        display: none;
      }
      .label_for_input_file {
        background-color: rgba(196, 196, 196, 0.18);
        position: relative;
        width: 100%;
        height: 105px;
        border: 2px dashed rgba(44, 64, 101, 0.5);
        .icon_card {
          z-index: -1;
          position: absolute;
          top: 45%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .text_icon_card {
          position: absolute;
          display: grid;
          justify-content: center;
          grid-template-columns: auto auto;
          bottom: 0;
          left: 50%;
          transform: translate(-50%);
          width: 100%;
          font-size: 12.5px;
          line-height: 20px;
          grid-column-gap: 5px;
          color: rgba(44, 64, 101, 1);
        }
      }
    }
    .pic_uploaded_info {
      margin-top: 9px;
      width: 100%;
      height: 15px;
      color: rgba(124, 119, 119, 1);
      direction: rtl;
      display: grid;
      grid-template-columns: auto auto;
      justify-content: end;
      font-size: 10.5px;
      grid-column-gap: 10px;
      div:nth-child(1) {
        color: $static_green;
      }
    }
    .button_container {
      width: 100%;
      position: relative;
      margin: 0 auto;
      margin-top: 18px;
      text-align: center;
      button {
        width: 100%;
        color: #fff;
        background-color: $static_darkBlue;
        border-width: 0px;
        outline: none;
        border-radius: 3px;
        height: 38px;
        font-family: "IranSansWeb";
        font-size: 16px;
        &:disabled {
          background-color: rgba(0, 0, 0, 0.2) !important;
        }
      }
    }
  }
}
