.a {
  -webkit-tap-highlight-color: transparent;
}
._item {
  width: 100%;
  min-height: 80px;
  background: var(--main2-color);
  margin-bottom: 4px;
  border-radius: 3px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  align-items: center;
  display: flex;
  flex-direction: row-reverse;
  .star {
    font-size: 20px;
    margin-inline-start: 10px;
    svg {
      color: var(--secondary-color) !important;
    }
  }
  .fill_start {
    svg {
      color: yellow !important;
    }
  }
  .changes {
    margin-right: auto;
    font-size: 15px;
    color: var(--secondary-color);
  }
  .name {
    margin-inline-end: 8px;
    color: var(--secondary-color);
    display: flex;
    flex-direction: column;
    text-align: right;
    font-family: "IranSansWeb";
    row-gap: 3px;
    font-size: 13px;
    max-width: 115px;
  }
  .pic {
    width: 28px;
  }
}
.item_container {
  width: 100%;
  min-height: 60px;
  background: var(--main2-color);
  margin-bottom: 4px;
  border-radius: 3px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  ._item2 {
    min-height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .star {
      font-size: 20px;
      margin-inline-start: 10px;
      svg {
        color: var(--secondary-color) !important;
      }
    }
    .fill_start {
      svg {
        color: yellow !important;
      }
    }
    .changes {
      margin-right: auto;
      font-size: 15px;
      color: var(--secondary-color);
    }
    .name {
      margin-inline-end: 8px;
      color: var(--secondary-color);
      display: flex;
      flex-direction: column;
      text-align: right;
      font-family: "IranSansWeb";
      row-gap: 3px;
      font-size: 13px;
      max-width: 115px;
    }
    .pic {
      width: 28px;
    }
  }
}

.item_container2 {
  width: 100%;
  min-height: 60px;
  background: var(--main2-color);
  margin-bottom: 4px;
  border-radius: 3px;
  padding: 8px;
  box-sizing: border-box;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
  ._item2 {
    min-height: 60px;
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    .star {
      font-size: 20px;
      margin-inline-start: 10px;
      svg {
        color: var(--secondary-color) !important;
      }
    }
    .fill_start {
      svg {
        color: yellow !important;
      }
    }
    .changes {
      margin-right: auto;
      font-size: 15px;
      color: var(--secondary-color);
    }
    .name {
      margin-inline-end: 8px;
      color: var(--secondary-color);
      display: flex;
      flex-direction: column;
      text-align: right;
      font-family: "IranSansWeb";
      row-gap: 3px;
      font-size: 13px;
      max-width: 145px;
    }
    .pic {
      width: 28px;
    }
  }
  .arrow {
    svg {
      transition: all 0.2s;
      color: var(--secondary-color) !important;
    }
    &[data-active="true"] {
      svg {
        transform: rotate(-90deg);
      }
    }
  }
  .markets {
    padding-block: 8px;
    direction: rtl;
    padding-inline: 20px;
    font-family: "IranSansWeb";
    color: var(--secondary-color);
    font-size: 13px;
    display: flex !important;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .trade_btn {
      width: 80px;
      button {
        width: 100%;
        font-family: "IranSansWeb";
        border-radius: 3px;
        outline: none;
        height: 30px;
        background-color: transparent;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
      }
    }
    .fast_order {
      display: flex !important;
      flex-direction: row !important;
      row-gap: 10px;
      grid-gap: 5px;
      button {
        width: 80px;
        font-family: "IranSansWeb";
        border-radius: 3px;
        outline: none;
        height: 30px;
        background-color: transparent;
        font-size: 12px;
        border: 1px solid var(--primary-color);
        color: var(--primary-color);
        &[data-type="sell"] {
          border: 1px solid var(--danger-color);
          color: var(--danger-color);
        }
      }
    }
  }
}

.not_found {
  width: 100%;
  height: 150px;
  align-items: center;
  justify-content: center;
  font-family: "IranSansWeb";
  color: var(--secondary2-color);
}
.order_coins_changes {
  display: flex;
  flex-direction: column;
  font-size: 13px;
  row-gap: 7px;
  .buy {
    color: var(--secondary3-color);
    span {
      font-size: 12.5px;
    }
  }
}
.subMenu_orders {
  width: 100%;
  min-height: 45px;
  padding-block: 5px;
  padding-inline: 2px;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid var(--main-color);
  strong {
    color: var(--secondary-color);
    max-width: 49%;
  }
  .order_buttons {
    display: flex;
    grid-gap: 10px;
    min-height: 35px;
    width: 50%;
    a {
      width: 50%;
    }
    button {
      width: 100%;
      height: 35px;
      margin: 0 auto;
      background: transparent;
      border: none;
      outline: none;
      font-family: "IranSansWeb";
      color: #fff;
      border-radius: 3px;
      &[data-type="buy"] {
        background: var(--primary-color);
      }
      &[data-type="sell"] {
        background: var(--danger-color);
      }
    }
  }
}

.bottom_fav_static {
  border-top: 1px solid var(--main-color);
  padding-block: 15px;
  align-items: center !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  direction: rtl;
  padding-inline: 20px;
  font-family: "IranSansWeb";
  .p {
    color: var(--secondary-color);
    font-size: 13.5px;
  }
  .trade_btn {
    width: 100px;
    button {
      width: 100%;
      font-family: "IranSansWeb";
      border-radius: 3px;
      outline: none;
      height: 33px;
      font-size: 12.5px;
      background-color: transparent;
      border: 1px solid var(--primary-color);
      color: var(--primary-color);
      &[data-status="error"] {
        border: 1px solid var(--danger-color);
        color: var(--danger-color);
      }
    }
  }
}
