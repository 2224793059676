.container {
  width: 99%;
  position: relative;
  display: grid;
  .header_btns {
    align-self: flex-start;
    .just_btn {
      display: grid;
      grid-column-gap: 2%;
      grid-template-columns: 49% 49%;
    }
    .typeBtn {
      width: 100%;
      height: 38px;
      border-width: 0px;
      background: transparent;
      border-radius: 3px;
      font-family: "IranSansWeb";
      outline: none;
      color: #9d9ea8;
      background: rgba(225, 226, 228, 0.4);
    }
  }
  main {
    width: 100%;
  }
  .finally_btn {
    align-self: flex-end;
    width: 100%;
  }
}
