.buttonItems {
  font-family: "IranSansWeb" !important;
  color: var(--secondary-color) !important;
  border-bottom: 1px solid transparent !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &[data-selected="true"] {
    color: var(--primary-color) !important;
    border-bottom-color: var(--primary-color) !important;
  }
}

//# Containers (View's)
.overviewContainer {
  direction: rtl;
  .upper_notification {
    color: var(--secondary-color);
    font-weight: 300;
    font-size: 13px;
    margin-top: 15px;
    margin-bottom: 10px;
    display: block;
    text-align: justify;
    line-height: 1.35;
  }
  .default_text {
    color: var(--secondary-color);
    font-size: 13px;
    display: block;
    font-weight: 300;
    margin-bottom: 5px;
  }
  .textViaCopy {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    .textForCopy,
    .btnForCopy {
      text-align: center;
      padding: 5px 5px;
      border-radius: 5px;
      font-size: 13px;
    }
    .textForCopy {
      flex: 1;
      background-color: var(--third-color);
      color: var(--secondary-color);
      word-break: break-all;
    }
    .btnForCopy {
      min-width: 80px;
      margin-left: 5px;
      background-color: var(--primary-color);
      color: #fff;
      cursor: pointer;
      position: relative;
      -webkit-tap-highlight-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .miniPercentBox {
    background-color: var(--third-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    flex: 1;
    border-radius: 3px;
    font-size: 13px;
    color: var(--secondary-color);
    font-family: "IranYekanBold";
  }
  .largeRefBox {
    background-color: var(--third-color);
    color: var(--secondary-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px;
    flex: 1;
    font-size: 14px;
    span:nth-child(2) {
      margin-top: 20px;
      font-family: "IranYekanBold";
      margin-bottom: 5px;
    }
  }
}

.codeReferralItem {
  margin-bottom: 10px;
  border-radius: 3px;
  padding: 10px 5px;
  direction: rtl;
  background-color: var(--third-color);
  .itemContainer {
    font-size: 14px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--secondary-color);
    word-break: break-all;
    span:nth-child(2) {
      max-width: calc(100% - 60px);
      direction: ltr;
      font-family: "IranYekanBold";
    }
  }
  .editBtn {
    font-family: "IranYekanBold";
    width: 100px;
    border: 1px solid var(--primary-color);
    font-size: 14px;
    background-color: transparent;
    color: var(--primary-color);
    padding: 8px 2px;
    border-radius: 3px;
    &:hover {
      color: #fff;
      background-color: var(--primary-color);
    }
  }
}
.editContainer {
  background-color: var(--third-color);
  min-height: 240px;
  border-top-right-radius: 18px;
  border-top-left-radius: 18px;
  padding: 18px 12px;
  font-family: "IranYekanBold";
  box-sizing: border-box;
  direction: rtl;
  strong {
    text-align: center;
    display: block;
    color: var(--secondary-color);
    font-weight: 300;
  }
  p {
    color: var(--secondary-color);
  }
  .boxPercent {
    flex: 1;
    background-color: var(--layout-color);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px 4px;
    color: var(--secondary-color);
    font-size: 13px;
  }
  .numberInput {
    background-color: var(--layout-color);
    border: 1px solid var(--border-color);
    width: 100%;
    box-sizing: border-box;
    min-height: 35px;
    outline: none;
    padding: 10px 15px;
    color: var(--secondary-color);
  }
}

//#
.friendsContainer {
  direction: rtl;
  .title {
    font-size: 13px;
    color: var(--secondary-color);
    text-align: justify;
  }
  margin-bottom: 120px;
}
