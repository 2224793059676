.menu_routes_container {
  min-height: 130px;
  row-gap: 12px;
  padding-block: 12px;
  border-radius: 14px;
  background: var(--temp-color);
  p {
    color: var(--secondary-color);
    opacity: 0.75;
  }
  .item_route_container {
    text-align: center;
    svg {
      color: var(--primary-color) !important;
      font-size: 24px;
    }
    .icon {
      position: relative;
      &[data-id="verify"] {
        svg {
          animation-name: scaleItem;
          animation-duration: 0.9s;
          animation-iteration-count: infinite;
          color: rgb(255, 96, 23) !important;
        }
      }
      &[data-id="notifications"] {
        &[data-message = "true"] {
          background: red;
        }
      }
      .badge{
        position: absolute;
        background: var(--danger-color);
        color: #fff;
        width: 18px;
        height: 18px;
        bottom: 0;
        border-radius: 50px;
        font-size: 10px;
        font-family: "IranYekanBold";
        line-height: 20px;
      }
    }
    .col {
      display: flex !important;
      flex-direction: column !important;
      p {
        padding: 0;
        margin-block: 2px;
        font-family: "IranSansWeb";
        font-size: 12px;
      }
    }
  }
}

@keyframes scaleItem {
  0% {
    transform: scale(0.9, 0.9);
  }
  50% {
    transform: scale(1.1, 1.1);
  }
  100% {
    transform: scale(0.9, 0.9);
  }
}

@keyframes shake {
  0% {
    transform: translateX(0);
  }
  25% {
    transform: translateX(-2px);
  }
  50% {
    transform: translateX(0);
  }
  75% {
    transform: translateX(2px);
  }
  100% {
    transform: translateX(0);
  }
}
