.container {
  width: 100%;
  height: 100vh;
  position: fixed;
  background-color: var(--third-color);
  top: 0;
  left: -100%;
  z-index: 9;
  transition: left 0.2s;
  box-sizing: border-box;
  &[data-visibility="true"] {
    left: 0;
  }
  .header {
    height: 50px;
    padding-inline: 15px;
    margin-top: 10px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      color: var(--secondary-color) !important;
    }
    input[type="text"] {
      background-color: var(--third2-color);
      outline: transparent;
      border-style: solid;
      border-color: var(--border-color);
      min-height: 30px;
      width: 100%;
      max-width: 200px;
      border-radius: 5px;
      direction: rtl;
      padding: 5px 10px;
      box-sizing: border-box;
      color: var(--secondary-color);
      font-family: "IranSansWeb";
    }
  }
  .content {
    position: fixed;
    width: 100%;
    height: 100vh;
    box-sizing: border-box;
  }
  .item {
    padding-inline: 15px;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    padding-block: 5px;
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    &:hover {
      background-color: rgba(0, 0, 0, 0.2);
      cursor: pointer;
    }
    &[data-active="true"] {
      background-color: rgba(0, 194, 149, 0.05);
    }

    .pic {
      display: flex;
      align-items: center;
      span {
        margin-inline-end: 10px;
        color: var(--secondary-color);
        font-size: 14px;
      }
    }

    .name {
      font-size: 14px;
      color: var(--secondary-color);
      font-weight: 400;
    }
    &[data-disabled="true"] {
      background-color: rgba(246, 85, 85, 0.06);
      cursor: not-allowed;
      .pic {
        span {
          color: var(--danger-color);
        }
      }
      .name {
        color: var(--danger-color);
        text-decoration: line-through;
      }
    }
  }
}
