.container{
    width: 100%;
    height: 35px;
    // background-color: #E7E7E7;
    font-family: "IranSansWeb";
    border-radius: 3px;
    text-align: center;
    line-height: 35px;
    font-size: 14px;
    color: #fff;
    font-weight: 600;
}