@import "../../../../../styles/_global.scss";

.TwoFactor_container {
  position: relative;
  width: 90%;
  margin: 0 auto;
  font-family: "IranSansWeb";
  .back_btn {
    position: relative;
    right: 0;
    width: 100%;
    height: 16px;
    margin-top: 30px;
    img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .title_of_2fa {
    margin-top: 15px;
    width: 100%;
    text-align: center;
    font-size: 16px;
  }
  .steps_of_verify {
    position: relative;
    margin: 20px auto;
    width: 60%;
    height: 20px;
    display: grid;
    grid-template-columns: auto auto auto;
    grid-gap: 7px;
    margin-bottom: 110px;
    div {
      text-align: center;
      line-height: 26px;
      font-size: 10px;
      width: 100%;
      background-color: rgba(196, 196, 196, 1);
      height: 5px;
      border-radius: 3px;
      color: rgba(196, 196, 196, 1);
    }
    .active_step {
      color: $static_green;
      transition: background-color 0.3s;
      background-color: $static_green;
    }
  }
  .stepButtons {
    margin: 0 auto;
    margin-top: -70px;
    width: 60%;
    margin-bottom: 130px;
    display: grid;
    grid-template-columns: 48% 48%;
    grid-column-gap: 4%;
    justify-content: center;
    button {
      border-radius: 3px;
      background-color: $static_green;
      color: #fff;
      font-family: "IranSansWeb";
      width: 100%;
      outline: none;
      border-width: 0px;
      height: 30px;
      &:disabled {
        background-color: rgba(61, 61, 61, 0.5);
      }
    }
  }
}
//
//////
/////

///COMPONENTS
.download_google_auth_container {
  position: relative;
  width: 100%;
  .title {
    margin-top: 24px;
    margin-bottom: 12px;
    width: 100%;
    height: 16px;
    text-align: center;
    font-size: 14px;
  }
  .desc {
    width: 100%;
    direction: rtl;
    text-align: center;
    font-size: 13.5px;
    margin-bottom: 30px;
  }
  //
  //
  //
  //DOWNLOAD GOOGLE AUTHENTICATOR STYLES
  .main_of_download_google_auth {
    position: relative;
    width: 100%;
    text-align: center;
    // margin-bottom: 100px;
    div:nth-child(1) {
      margin-bottom: 30px;
    }
    .choice_type{
      margin-bottom: -10px!important;
    }
    div:nth-child(2) {
      margin-bottom: 10px;
    }
  }
  //
  //
  //
  //GET AUTH CODE FROM SITE STYLES
  .main_of_app_auth_code {
    position: relative;
    width: 100%;
    .QR_container {
      margin: 0 auto;
      width: 90px;
      height: 90px;
    }
    .text_one {
      width: 100%;
      direction: rtl;
      font-size: 13.5px;
      height: 40px;
      text-align: center;
      margin: 0px auto;
      margin-top: 15px;
    }
    .input_of_QR {
      position: relative;
      margin: 0 auto;
      width: 90%;
      height: 38px;
      input {
        width: 98.5%;
        border-width: 0px;
        background-color: rgba(196, 196, 196, 0.35);
        height: 38px;
        border-radius: 3px;
        text-align: center;
        outline: none;
      }
      .copyIcon {
        position: absolute;
        top: 8px;
        left: 5px;
        img {
          top: 0;
          left: 0;
        }
        &:hover {
          img {
            position: absolute;
            transition: all 0.4s;
            transform: rotate(360deg);
          }
        }
      }
    }
    .text_two {
      position: relative;
      direction: rtl;
      text-align: center;
      font-size: 12.5px;
      div:nth-child(1) {
        margin-top: 20px;
        margin-bottom: 25px;
      }
    }
  }
  .main_of_verify_2fa_code {
    .code_recived{
      width: 100%;
      height: 33px;
      direction: rtl;
      border-radius: 3px;
      border-width: 0px;
      outline: none;
      border:1px solid rgba(134, 132, 132, 1);
      font-family: "IranSansWeb";
      text-indent: 10px;
    }
    .input_confirm_code {
      .title_confirm {
        text-align: center;
        font-size: 17px;
        margin-top: 20px;
        color: rgba(43, 63, 99, 1);
      }
      .input_places {
        margin-top: 20px;
        width: 100% !important;
      }
      .input_places > div {
        width: 100% !important;
        display: grid;
        grid-template-columns: repeat(6, auto);
        justify-content: space-between;
        // grid-gap: 10px;


        input {
          width: 40px;
          height: 40px;
          text-align: center;
          padding: 0;
          border-radius: 3px;
          outline: none;
          border: 1.3px solid $static_green !important;
          font-size: 15px;
          font-family: "IranSansWeb";
          &::placeholder {
            color: rgba(0, 0, 0, 1);
          }
          &::-moz-placeholder {
            color: rgba(0, 0, 0, 1);
          }
          &::-webkit-input-placeholder {
            color: rgba(0, 0, 0, 1);
          }
        }
      }
    }
    button {
      margin-top: 30px;
      width: 100%;
      background-color: $static_darkBlue;
      border-width: 0px;
      outline: none;
      height: 38px;
      border-radius: 3px;
      color: #fff;
      font-family: "IranSansWeb";
      font-size: 17px;
      margin-bottom: 60px;
      &:disabled {
        background: rgba(134, 132, 132, 1);
        color: rgb(228, 228, 228);
      }
    }
  }
}

.twoFAEnabled {
  font-family: "IranSansWeb";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 15px;
  color: $static_green;
  text-align: center;
}
