@import "../../../styles/_global.scss";

@mixin placeOfTitleAndDiv {
  position: absolute;
  width: 90px;
  height: 20px;
  text-align: center;
  font-size: 12px;
  font-family: "IranSansWeb";
  color: rgba(134, 132, 132, 1);
  right: 0;
}
@mixin placeOfTotalCoins {
  position: absolute;
  width: 100px;
  height: 20px;
  left: 15px;
  font-size: 12px;
  font-family: "IRANYekanMoblieRegular";
  line-height: 20px;
  span {
    padding-left: 3px;
  }
}

.container {
  margin-top: 60px;
  width: 100%;
  padding-bottom: 110px;
  // background: rgba(111, 109, 109, 0.1);
  border: 1px solid rgba(0, 0, 0, 0.18);
  box-sizing: border-box;
  box-shadow: 0px -3px 0px rgba(0, 0, 0, 0.15);
  border-radius: 21px 21px 0px 0px;
  position: relative;
  .container_responsive {
    position: relative;
    width: 92%;
    margin: 0 auto;
    height: 100%;
    .header_of_deposit {
      position: relative;
      width: 100%;
      height: 75px;
      .coin_picture {
        text-align: center;
        position: absolute;
        width: 65px;
        height: 65px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 50px;
        top: -35px;
      }
      .title_of_page {
        position: absolute;
        width: 55px;
        height: 30px;
        right: 0;
        top: 0px;
        img {
          transform: rotate(180deg);
        }
        p {
          position: absolute;
          top: -9px;
          right: 16px;
          color: #fff;
          font-size: 13px;
          font-family: "IranSansWeb";
        }
      }
      .coin_name {
        position: absolute;
        width: 170px;
        height: 30px;
        left: 50%;
        transform: translate(-50%);
        top: 40px;
        text-align: center;
        font-family: "IranSansWeb";
        font-weight: 600;
        font-size: 16px;
      }
      .refresh_button {
        position: absolute;
        width: 75px;
        height: 30px;
        background-color: $static_green;
        border-radius: 2px;
        bottom: 6px;
        left: 2px;
        font-size: 12px;
        color: #fff;
        border-width: 0px;
        font-family: "IranSansWeb";
        &:disabled {
          background-color: rgba(0, 0, 0, 0.15);
        }
        .refresh_icon {
          position: absolute;
          right: 5px;
          top: 7px;
          img {
            animation-name: RotateOne;
            animation-duration: 0.8s;
            animation-iteration-count: 1;
          }
        }
        .refresh_text {
          position: absolute;
          left: 7px;
          top: 7px;
        }
      }
      .line_header_withdraw {
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #d0cfcf;
        bottom: 0;
      }
    }
    .main_deposit {
      margin-top: 13px;
      position: relative;
      width: 100%;
      background-color: rgba(196, 196, 196, 0.45);
      height: 105px;
      border-radius: 8px;
      animation: ScaleOne;
      animation-iteration-count: 1;
      animation-duration: 0.8s;
      .coin_persian_name {
        position: absolute;
        right: 12px;
        border-radius: 0px 0px 3px 3px;
        min-width: 40px;
        max-width: 140px;
        height: 18px;
        padding-inline: 10px;
        padding-bottom: 3px;
        background-color: rgba(252, 252, 252, 1);
        font-size: 13px;
        font-family: "IranSansWeb";
        color: rgba(146, 146, 146, 1);
        text-align: center;
      }
      .title_of_total_coin {
        @include placeOfTitleAndDiv;
        top: 25px;
        color: rgba(134, 132, 132, 1);
        img {
          position: absolute;
          right: 0;
          top: 5px;
        }
        div {
          position: absolute;
          top: 3px;
          right: 14px;
        }
      }
      .title_of_coin_locked {
        @include placeOfTitleAndDiv;
        top: 50px;
        img {
          position: absolute;
          right: 0;
          top: 5px;
        }
        div {
          position: absolute;
          top: 3px;
          right: 14px;
        }
      }
      .title_removable_coin {
        @include placeOfTitleAndDiv;
        top: 75px;
        img {
          position: absolute;
          right: 0;
          top: 5px;
        }
        div {
          position: absolute;
          top: 3px;
          right: 14px;
        }
      }
      .total_coin {
        @include placeOfTotalCoins;
        top: 25px;
        font-family: "IranSansWeb";
      }
      .total_coin_locked {
        @include placeOfTotalCoins;
        top: 50px;
        font-family: "IranSansWeb";
      }
      .total_removable_coin {
        @include placeOfTotalCoins;
        top: 75px;
        font-family: "IranSansWeb";
      }
    }
    .check_network_container {
      width: 100%;
      .checkbox_net_place {
        position: relative;
        display: grid;
        margin-top: 10px;
        width: 100%;
        .checkbox_input_current {
          display: none;

          // &:checked ~ .arrow_place_checkbox img {
          //   transform: rotate(0deg) !important;
          // }
          // &:checked ~ .value_of_network_input {
          //   border-bottom-left-radius: 0px;
          //   border-bottom-right-radius: 0px;
          // }
        }
        .value_of_network_input {
          margin: 0 auto;
          width: 100%;
          padding: 0;
          background-color: rgba(196, 196, 196, 0.45);
          outline: none;
          border-width: 0px;
          height: 30px;
          border-radius: 5px;
          font-family: "IranSansWeb";
          font-size: 12.5px;
          direction: rtl;
          text-indent: 10px;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
        }
        .arrow_place_checkbox {
          width: 97%;
          position: absolute;
          top: 7px;
          left: 5px;
          img {
            transform: rotate(90deg);
          }
        }
      }

      .list_of_networks {
        position: relative;
        background-color: rgba(196, 196, 196, 0.45);
        width: 100%;
        display: grid;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        display: none;
        li:last-child > .line_last {
          display: none;
        }
        .RadioNetworkCheck:checked ~ .check_custom {
          background-color: $static_green;
        }
        .list_network {
          position: relative;
          width: 100%;
          height: 25px;
          padding-top: 10px;
          margin-bottom: 5px;
        }
        .line_last {
          position: absolute;
          width: 100%;
          height: 1px;
          background-color: rgba(187, 184, 184, 1);
          bottom: 0;
        }
        .label_network {
          position: absolute;
          right: 0;
          .check_custom {
            right: 20px;
            position: absolute;
            width: 10px;
            height: 10px;
            border: 1px solid #2c4065;
            border-radius: 3px;
            top: 2px;
          }
          span {
            top: -1px;
            font-size: 14.5px;
            font-family: "IranSansWeb";
            right: 40px;
            position: absolute;
            color: rgba(134, 132, 132, 1);
          }
        }
        .amount_of_wage {
          left: 15px;
          top: 7px;
          min-width: 110px;
          max-width: 150px;
          color: rgba(124, 119, 119, 1);
          position: absolute;
          display: grid;
          grid-template-columns: auto auto;
          font-size: 12.5px;
          justify-content: start;
          grid-column-gap: 10px;
          font-family: "IranSansWeb";
        }
      }
    }
    .showNotificationOfNetwork {
      position: relative;
      width: 100%;
      background-color: rgba(246, 85, 85, 0.1);
      margin-top: 8px;
      margin-bottom: 8px;
      border-radius: 4px;
      border: 0.2px solid rgba(246, 85, 85, 0.4);
      .iconOfNotificationNetwork {
        position: absolute;
        width: 17px;
        right: 10px;
        height: 17px;
        top: 1px;
      }
      .titleNotificationNetwork {
        color: rgba(246, 85, 85, 1);
        font-size: 12px;
        font-family: "IranSansWeb";
        direction: rtl;
        line-height: 20px;
        text-align: justify;
        padding-left: 5px;
        padding-right: 30px;
      }
    }
  }
  .showNotificationForWalletAddress {
    position: relative;
    width: 100%;
    background: rgba(44, 64, 101, 0.05);
    border: 0.2px solid rgba(44, 64, 101, 0.5);
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 4px;
    text-align: justify;
    padding-left: 5px;
    .iconOfNotificationNetwork {
      position: absolute;
      width: 17px;
      right: 10px;
      height: 17px;
      top: 1px;
    }
    .titleNotificationNetwork {
      line-height: 18px;
      p {
        margin-top: 0;
        color: rgba(44, 64, 101, 1);
        font-size: 14.5px;
      }
      ul {
        margin-top: -6px;
        list-style: decimal;
        li {
          padding-top: 10px;
        }
      }
      color: rgba(44, 64, 101, 1);
      // position: absolute;
      padding-right: 28px;
      padding-top: 5px;
      padding-bottom: 5px;
      right: 30px;
      top: 4px;
      font-size: 12.5px;
      font-family: "IranSansWeb";
      direction: rtl;
    }
  }
  .container_of_wallet {
    position: relative;
    width: 100%;
    display: grid;
    .button_new_wallet_address {
      font-family: "IranSansWeb";
      background-color: rgba(16, 208, 120, 1);
      color: #fff;
      line-height: 32px;
      text-align: center;
      font-size: 13px;
      border-radius: 2px;
      margin: 12px auto;
      width: 120px;
      height: 33px;
      outline: none;
      border-width: 0px;
      &:disabled {
        background-color: rgba(0, 0, 0, 0.15);
      }
    }
    .wallet_address {
      width: 100%;
      position: relative;
      display: grid;
      grid-template-columns: auto;
      .QRCode_address {
        margin: 10px auto;
        width: 95px;
        height: 95px;
        margin-bottom: 0;
      }
      .address_network_name {
        width: 220px;
        height: 20px;
        margin: 2px auto;
        direction: rtl;
        font-size: 12.5px;
        font-family: "IRANYekanMobileBold";
        font-weight: 600;
        text-align: center;
        line-height: 20px;
      }
      .container_of_address_text_wallet {
        position: relative;
        margin: 25px auto;
        margin-bottom: 5px;
        width: 101%;
        .input_show_wallet_address {
          position: relative;
          .place_of_value {
            padding-top: 5px;
            padding-bottom: 5px;
            width: 99%;
            background-color: rgba(44, 64, 101, 0.1);
            border-radius: 5px;
            color: rgb(78, 78, 78);
            font-size: 14px;
            text-indent: 7px;
            line-height: 25px;
            word-break: break-all;
            font-family: "IranYekanBold";
            div {
              max-width: 94%;
            }
          }
        }
        .copy_button {
          right: 7px;
          top: 9px;
          position: absolute;
          width: 20px;
          height: 20px;
          text-align: center;
          &:after {
            content: "Copy";
            display: none;
            position: absolute;
            z-index: 9999;
            top: -50px;
            right: -13px;
            width: 50px;
            height: 30px;
            color: #fff;
            font-size: 10px;
            line-height: 30px;
            text-align: center;
            background: $static_green;
            border-radius: 3px;
          }
          &:active,
          &:focus {
            &:after {
              display: block;
              content: "Copied!";
            }
          }
        }
        .title_for_use_wallet {
          position: absolute;
          width: 250px;
          height: 15px;
          top: -15px;
          right: 0;
          direction: rtl;
          color: rgba(134, 132, 132, 1);
          font-size: 11.5px;
          padding-right: 2px;
          font-family: "IranSansWeb";
        }
      }
    }
  }
}
.show_list_of_network {
  display: block !important;
}

.tagInput_container {
  margin-top: 10px;
  font-size: 13px;
  color: #000;
  .copy_button {
    right: 5px;
    top: 9px;
    position: absolute;
    width: 20px;
    height: 20px;
    text-align: center;
    &:after {
      content: "Copy";
      display: none;
      position: absolute;
      z-index: 9999;
      top: -45px;
      right: -13px;
      width: 50px;
      height: 30px;
      color: #fff;
      font-size: 10px;
      line-height: 30px;
      text-align: center;
      background: $static_darkBlue;
      border-radius: 3px;
    }
    &:active,
    &:focus {
      &:after {
        display: block;
        content: "Copied!";
      }
    }
  }
  .memoQrPlace {
    text-align: center;
    direction: rtl;
    font-family: "IranSansWeb";
  }
  input {
    width: 100%;
    padding: 0;
    margin: 0;
    outline: 0;
    background-color: rgba(78, 78, 78, 0.5);
    border-width: 0px;
    border-color: none;
    color: #000;
    font-family: "IranSansWeb";
    height: 30px;
  }
  .titleNotificationNetwork {
    font-family: "IranSansWeb";
    color: $static-darkRed;
    direction: rtl;
    font-size: 13px;
    text-align: justify;
  }
}

.container_show {
  position: relative;
  width: 100%;
  background-color: rgba(44, 64, 101, 0.1);
  border-radius: 3px;
  word-break: break-all;
  padding-top: 7px;
  padding-bottom: 5px;
  div {
    line-height: 20px;
    padding-left: 5px;
    max-width: 90%;
  }
}

///Animation
@keyframes RotateOne {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes ScaleOne {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.035);
  }
  100% {
    transform: scale(1);
  }
}
