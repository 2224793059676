@import "../../../../styles/_global.scss";
.container {
  width: 100%;
  margin-bottom: 50px;
  .confirm_title {
    margin: 0 auto;
    width: 228px;
    height: 34px;
    margin-bottom: 16px;
    margin-top: 27px;
    text-align: center;
    line-height: 32px;
    font-size: 19px;
    color: rgba(43, 63, 99, 1);
    font-family: "IranSansWeb";
  }
  .input_places {
    margin-top: 41px;
    width: 100% !important;
  }
  .input_places > div {
    width: 100% !important;
    display: grid;
    grid-template-columns: repeat(5, auto);
    justify-content: center;
    grid-gap: 22px;

    input {
      width: 40px;
      height: 40px;
      text-align: center;
      padding: 0;
      border-radius: 3px;
      outline: none;
      border: 1.3px solid $static_green !important;
      font-size: 15px;
      font-family: "IranSansWeb";
      &::placeholder {
        color: rgba(0, 0, 0, 1);
      }
      &::-moz-placeholder {
        color: rgba(0, 0, 0, 1);
      }
      &::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 1);
      }
    }
  }
  .container_resend_datas {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: 310px;
    height: 30px;
    // background-color: red;
    .resend_code_container {
      top: -4px;
      right: 2px;
      position: absolute;
      font-size: 13px;
      font-family: "IranSansWeb";
      .grayColor {
        color: gray;
      }
      button {
        all: unset;
        font-family: "IranSansWeb";
        margin-top: 10px;
      }
    }
    .resend_timer_container {
      font-family: "IranSansWeb";
      font-size: 13px;
      top: -4px;
      left: 2px;
      position: absolute;
    }
  }

  .button_container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    width: 100%;
    text-align: center;
    button {
      width: 306px;
      height: 36px;
      border: inherit;
      border-radius: 32px;
      color: rgba(255, 255, 255, 1);
      font-family: "IRANYekanMobileExtraBold";
      font-size: 14.5px;
    }
    .continue_btn {
      margin-top: 33px;
      background-color: $static_green;
      &:hover {
        transition: background-color 0.6s;
        background-color: $static_green_hover;
      }
    }
    .back_btn {
      margin-top: 15px;
      background-color: $static_darkBlue;
    }
  }
  .errorMessage {
    text-align: right;
    font-family: "IranSansWeb";
    font-size: 12px;
  }
}
