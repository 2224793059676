@import "../../../../styles/_global.scss";
.container {
  width: 100%;
  margin-bottom: 50px;
  .register_title {
    margin: 0 auto;
    width: 228px;
    height: 34px;
    margin-bottom: 16px;
    margin-top: 27px;
    text-align: center;
    line-height: 32px;
    font-size: 19px;
    color: rgba(43, 63, 99, 1);
    font-family: "IranSansWeb";
  }

  .inputs_place {
    margin: 26px auto;
    overflow: hidden;
    .email_place,
    .pass_place,
    .mobile_place {
      width: 100%;
      justify-content: center;
      display: grid;
      grid-template-columns: auto;
      padding: 10px;
      position: relative;
      input {
        background-color: rgba(196, 196, 196, 0.1);
        width: 290px;
        border-radius: 6px;
        font-family: "IranSansWeb";
        padding: 10px;
      }
      label {
        font-family: "IranSansWeb";
        right: 35px;
        top: -3px;
        font-size: 14px;
      }
      .icon_place {
        text-align: right;
        margin-top: -35px;
        margin-right: 8px;
      }
      .icon_place_active {
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
          brightness(88%) contrast(119%);
      }
    }
  }
  .pass_place {
    margin-top: 5px;
    .icon_place {
      margin-top: -32px !important;
    }
  }
  .button_container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    width: 100%;
    text-align: center;
    button {
      margin-top: 5px;
      width: 306px;
      height: 36px;
      border: inherit;
      border-radius: 32px;
      color: rgba(255, 255, 255, 1);
      font-family: "IRANYekanMobileExtraBold";
      font-size: 14.5px;
    }
    .continue_button {
      background-color: $static_green;
      &:hover {
        transition: background-color 0.6s;
        background-color: $static_green_hover;
      }
    }
    .back_button {
      margin-top: 13px;
      background-color: $static_darkBlue;
    }
  }
  .errorMessage {
    text-align: right;
    font-family: "IranSansWeb";
    font-size: 12px;
    color: $static_red;
  }
}
