.bg {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  background-color: rgba($color: #000000, $alpha: 0.12);
}
.container {
  position: fixed;
  width: 100%;
  height: 465px;
  background-color: #fcfcfc;
  z-index: 1;
  top: -100%;
  transition: top 0.5s;
  border-radius: 0px 0px 24px 24px;
  font-family: "IranSansWeb";
  .main {
    width: 96%;
    margin: 0 auto;
    h4 {
      color: #272e40;
      text-align: center;
      font-size: 16px;
      margin: 0;
    }
    p {
      direction: rtl;
      color: #9d9ea8;
      font-size: 12px;
      margin-bottom: 4px;
    }
    .select_market {
      -webkit-tap-highlight-color: transparent;
      width: 100%;
      height: 40px;
      background: #f2f1f1;
      display: grid;
      grid-template-columns: 80% 20%;
      direction: rtl;
      align-items: center;
      justify-content: space-between;
      border-radius: 3px;
      color: #272e40;
      cursor: pointer;
      strong {
        font-size: 11.8px;
        padding-right: 10px;
        user-select: none;
      }
      div {
        text-align: left;
        svg {
          position: relative;
          top: 3px;
          left: 4px;
        }
      }
    }
    .container_type,
    .container_market_type,
    .container_date {
      display: grid;
      grid-template-columns: 33% 32% 33%;
      grid-column-gap: 1%;
      direction: rtl;
    }
  }
  .container_custom_date {
    position: relative;
    margin-bottom: 5px;
    .custom_labels {
      div {
        position: absolute;
        color: #272e40;
        font-size: 12px;
        top: 7px;
        user-select: none;
      }
      div:nth-child(1) {
        right: 6px;
      }
      div:nth-child(2) {
        right: 53%;
      }
    }
  }
  .button_container {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 49% 49%;
    grid-column-gap: 2%;
    direction: rtl;
    button {
      width: 100%;
      outline: none;
      background: transparent;
      font-family: "IranSansWeb";
      border-width: 0;
      border-radius: 3px;
      height: 35px;
    }
    .confirm {
      background: #00c194;
      color: #fff;
      border: 1px solid #00c194;
    }
    .restore {
      color: #00c194;
      border: 1px solid #00c194;
    }
  }
}
.show {
  top: 0;
}
