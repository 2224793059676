@import "../../../../styles/_global.scss";
.container {
  width: 90%;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  margin: 0 auto;
  margin-bottom: 110px;
  .AccountsInfo_BTN {
    margin: 0 auto;
    width: 120px;
    height: 37px;
    border: 1px solid $static_green;
    border-radius: 7px;
    color: $static_green;
    text-align: center;
    font-size: 14px;
    line-height: 39px;
    margin-bottom: 13px;
    margin-top: -10px;
    &:hover {
      background-color: $static_green;
      color: #fff;
    }
  }
  .input_container {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: auto;
    input {
      width: 99.2%;
      direction: rtl;
      height: 37px;
      background-color: rgba(196, 196, 196, 0.6);
      outline: none;
      border-width: 0px;
      color: rgba(124, 119, 119, 0.95);
      margin-top: 11px;
      text-indent: 10px;
      font-family: "IranSansWeb";
      border-radius: 3px;
      font-size: 14px;
    }
    .dateTime_pic_container {
      position: relative;
      display: grid;
      grid-template-columns: 58% 40%;
      width: 100%;
      direction: rtl;
      grid-gap: 7px;
      margin-top: 11px;
      .date_and_time {
        width: 98%;
        position: relative;
        border-radius: 3px;
        height: 38px;
        label {
          font-size: 12.5px;
          line-height: 40px;
          padding-right: 8px;
          color: rgba(124, 119, 119, 0.99);
        }
        input {
          padding: 0px;
          font-size: 16px;
          top: -11px;
          position: absolute;
          width: 100%;
          height: 38px;
        }
      }
      .upload_pic {
        width: 101.2%;
        border-radius: 3px;
        height: 37.48px;
        border: 1px solid $static_green;
        color: $static_green;
        font-size: 10px;
        text-align: center;
        line-height: 38px;
        font-weight: 400;
        transition: all 0.2s;
        &:hover{
          background: $static_green;
          color: #fff;
        }
      }
    }
    .pic_information {
      margin-top: 6px;
      margin-bottom: 5px;
      display: grid;
      height: 10px;
      text-align: center;
      grid-template-columns: auto auto;
      justify-content: end;
      font-size: 9px;
      direction: rtl;
      grid-gap: 15px;
      div:nth-child(1) {
        color: $static_green;
      }
      button {
        font-family: "IranSansWeb";
        background: transparent;
        border-width: 0;
        color: $static_red;
        font-size: 11px;
      }
    }
  }
  .persian_price {
    margin-top: 5px;
    direction: rtl;
    color: rgba(124, 119, 119, 0.92);
    font-size: 13px;
  }
}
