.headerDataContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  min-height: 40px;
  direction: rtl;
  background: var(--third2-color);
  padding: 5px;
  border-radius: 5px;
  .sumDetails {
    p {
      margin: 0;
      font-size: 14px;
      color: var(--secondary4-color);
      span {
        font-family: "IranYekanBold";
        color: var(--primary-color);
      }
      span[data-color="danger"] {
        color: var(--danger-color);
      }
    }
  }
}

.messagesContainer {
  margin-bottom: 110px;
  margin-top: 10px;
  min-height: 250px;
}
.messageItem {
  background: var(--third-color);
  margin-bottom: 10px;
  padding: 5px;
  border-radius: 3px;
  .headItem {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    .seenIcon {
      svg {
        color: var(--danger-color) !important;
      }
      &[data-seen="true"] {
        svg {
          color: var(--primary-color) !important;
        }
      }
    }
    .title_date {
      flex: 1;
      padding-inline: 10px;
      direction: rtl;
      strong,
      time {
        display: block;
      }
      strong {
        color: var(--secondary3-color);
        font-size: 13.5px;
      }
      time {
        color: var(--secondary4-color);
        font-size: 12.5px;
        font-family: "IranYekanBold";
        margin-top: 5px;
      }
    }

    svg {
      margin-top: 3px;
    }
    span {
      svg {
        color: var(--secondary4-color) !important;
      }
    }
  }
  .descItem {
    height: 0;
    direction: rtl;
    font-size: 14.5px;
    margin-top: 5px;
    color: var(--secondary2-color);
    text-align: justify;
    overflow: hidden;
    transition: height 0.15s;
    padding: 3px;
    &[data-show="true"] {
      height: max-content;
    }
  }
}

.readAllBtn{
  font-family: "IranSansWeb";
  font-size: 12px;
  background-color: var(--primary-color);
  border-width: 0;
  border-radius: 3px;
  color: #fff;
  padding: 5px;
}