@import "../../../../styles/_global.scss";
.container {
  position: relative;
  margin: 0 auto;
  width: 90%;
  //   background-color: red;
  font-family: "IranSansWeb";
  .backIcon {
    position: relative;
    margin-top: 29px;
    width: 15px;
    height: 15px;
    img {
      transform: rotate(180deg);
    }
  }
  .title_of_page {
    text-align: center;
    position: relative;
    margin-top: 10px;
    font-size: 18px;
    width: 100%;
    height: 20px;
  }
  .Inputs_container {
    position: relative;
    display: grid;
    grid-template-columns: auto;
    width: 100%;
    .one_input_container {
      position: relative;
      width: 100%;
      margin-top: 10px;
      margin-bottom: 8px;

      input {
        height: 38px;
        width: 100%;
        position: relative;
        background-color: rgba(196, 196, 196, 0.65);
        outline: none;
        border-width: 0;
        padding: 0;
        direction: rtl;
        border-radius: 3px;
        font-family: "IranSansWeb";
        text-indent: 13px;
      }
      .show_hidden_pass_container {
        top: 12px;
        left: 12px;
        position: absolute;
        width: 13px;
        height: 13px;
      }
    }
    button {
      margin-top: 10px;
      background-color: $static_green;
      outline: none;
      border-width: 0px;
      height: 40px;
      font-family: "IranSansWeb";
      color: #fff;
      font-size: 17px;
      border-radius: 3px;
    }
  }
}
.error_red {
  color: $static_darkRed;
  font-size: 13px;
  text-align: right;
  padding-right: 2px;
}
