.container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: #212121;
  border-radius: 3px;
  overflow: hidden;
  .main {
    width: 80%;
    min-height: 260px;
    max-height: 300px;
    position: absolute;
    top: 47%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .messageIconContainer {
    width: 100%;
    height: 140px;
    display: flex;
    align-items: center;
    flex-direction: column;
    &[data-type="error"] {
      background-color: var(--danger-color);
    }
    &[data-type="success"] {
      background-color: var(--primary-color);
    }
    &[data-type="warning"] {
      background-color: var(--warning-color);
    }
    position: relative;
    .title {
      position: absolute;
      bottom: -5px;
      color: #fff;
      font-size: 14px;
    }
    svg {
      font-size: 100px;
      color: #fff !important;
    }
  }
  .messageContext {
    background-color: #fff;
    min-height: 145px;
    padding: 10px;
    box-sizing: border-box;
  }
}
