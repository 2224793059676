.buttonItems {
  font-family: "IranSansWeb" !important;
  color: var(--secondary-color) !important;
  border-bottom: 1px solid transparent !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &[data-selected="true"] {
    color: var(--primary-color) !important;
    border-bottom-color: var(--primary-color) !important;
  }
}

.title_input,
.textArea_input {
  background-color: var(--third-color);
  border-style: solid;
  width: 100%;
  box-sizing: border-box;
  border-color: transparent;
  padding: 7px 12px;
  outline: transparent;
  direction: rtl;
  color: var(--secondary-color);
  font-size: 16px;
  font-family: "IranSansWeb";
}
.textArea_input {
  resize: none;
  height: 140px;
}
.button_submit {
  font-family: "IranSansWeb";
  border-radius: 2px;
  padding: 10px 12px;
  outline: transparent;
  width: 100%;
  border-style: outset;
  border-color: rgba(0, 0, 0, 0.2);
  color: var(--primary-color);
  cursor: pointer;
  background-color: var(--primaryOpacity-color);
  &:disabled {
    background-color: var(--border-color);
    color: var(--secondary-color);
  }
  &[data-size="small"] {
    padding: 4px 15px;
  }
}
.search {
  width: 50%;
  max-width: 200px;
  min-width: 100px;
  box-sizing: border-box;
  min-height: 40px;
  background-color: var(--third-color);
  border-style: solid;
  border-color: var(--border-color);
  border-radius: 3px;
  outline: transparent;
  padding-inline: 10px;
  color: var(--secondary-color);
  font-family: "IranSansWeb";
}
.item {
  width: 100%;
  background-color: var(--third-color);
  min-height: 150px;
  margin-bottom: 10px;
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  .flexItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--secondary-color);
    padding-block: 5px;
    border-radius: 2px;
    box-sizing: border-box;
    font-size: 14px;
    span {
      max-width: 50%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      &[data-number="true"] {
        font-family: "IranYekanBold" !important;
      }
    }
    &[data-status="success"] {
      background-color: var(--primaryOpacity-color);
    }
    &[data-status="danger"] {
      background-color: var(--dangerOpacity-color);
    }
    &[data-status="warning"] {
      background-color: var(--warningOpacity-color);
    }
  }
}
.modalContainer {
  .noSingle {
    font-family: "IranYekanBold" !important;
    color: var(--secondary-color);
  }
  .close {
    color: var(--secondary-color);
    svg {
      color: var(--secondary-color) !important;
    }
  }
}
.message_container {
  border-radius: 3px;
  padding: 10px;
  box-sizing: border-box;
  direction: rtl;
  background-color: var(--layout-color);
  margin-bottom: 10px;
  span,
  p {
    color: var(--secondary-color);
    word-break: break-all;
    font-size: 14px;
  }
  p {
    margin: 0;
    margin-block: 5px;
  }
  time {
    color: var(--main-color);
    font-size: 13px;
    font-family: "IranYekanBold" !important;
  }
  .footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 15px;
    b {
      color: var(--secondary-color);
      font-weight: 200;
      font-size: 12px;
    }
  }
  &[data-type="USER"] {
    border: 1px solid var(--primary-color);
  }
  &[data-type="ADMIN"] {
    border: 1px solid var(--warning-color);
  }
}
.answer {
  textarea {
    background-color: var(--layout-color);
    border-style: solid;
    width: 100%;
    box-sizing: border-box;
    border-color: transparent;
    border-radius: 3px;
    padding: 7px 12px;
    outline: transparent;
    direction: rtl;
    color: var(--secondary-color);
    font-size: 16px;
    font-family: "IranSansWeb";
    resize: none;
    height: 120px;
  }
}
