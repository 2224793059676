.container {
  width: 100%;
  height: 425px;
  .last_price {
    height: 45px;
    padding-block: 3px;
    text-align: right;
    padding-left: 10px;
    font-size: 18px;
    border-top: 1px solid #e1e2e4;
    border-bottom: 1px solid #e1e2e4;
    display: flex;
    align-items: center;
  }

  .sell_container {
    .price {
      color: #ed6e72;
    }
  }
  .buy_container {
    .price {
      color: #00c194;
    }
  }
  .sell_container,
  .buy_container {
    width: 100%;
    // height: 200px;
    .price,
    .amount {
      width: 99%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .amount {
      direction: ltr;
    }
    .head {
      padding-top: 5px;
      color: #01081e;
      font-family: "IranYekanMobileBold";
    }
    .amount {
      color: #837f7f;
    }
    .body {
      margin-top: 5px;
      height: 165px;
      overflow: hidden;
    }
    .full_view_list {
      height: 360px;
      overflow-y: auto;
    }
    .item {
      padding-bottom: 3.1px;
      position: relative;
      .colorize {
        position: absolute;
        height: 100%;
        top: 0;
        right: 0;
      }
      .colorize_red {
        background: rgba(237, 110, 114, 0.24);
      }
      .colorize_green {
        background: rgba(0, 193, 148, 0.16);
      }
    }
    .head,
    .item {
      display: grid;
      grid-template-columns: 50% 50%;
      font-size: 13px;
      padding-inline: 10px;
      align-items: center;

      div:nth-child(1) {
        text-align: right;
      }
      div:nth-child(2) {
        text-align: left;
      }
    }
  }
}
