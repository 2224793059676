@mixin ButtonStyles {
  font-family: "IranSansWeb";
  font-size: 15.5px;
  padding-inline: 12px;
  border: none;
  outline: none;
  background: transparent;
  padding-block: 8px;
  margin-inline-end: 5px;
  color: var(--secondary2-color);
  border-bottom: 1px solid transparent;
  &[data-active="true"] {
    color: var(--primary-color);
    border-bottom: 1px solid var(--primary-color);
  }
}
.buttons {
  flex-direction: row-reverse !important;
  border-bottom: 1px solid var(--main-color);
  padding-inline: 5px;
  box-sizing: border-box;
  button {
    @include ButtonStyles;
    &[data-index="2"] {
      color: var(--warning-color);
      &[data-active="true"] {
        border-bottom: 1px solid var(--warning-color);
      }
    }
  }
}
.sub_button {
  flex-direction: row-reverse !important;
  margin-top: 5px;
  // border-bottom: 1px solid var(--main-color);
  margin-inline: 5px;
  button {
    @include ButtonStyles;
  }
  .icon {
    img {
      position: relative;
      top: 4.5px;
      margin-left: 4px;
    }
  }
}
.search {
  width: 100%;
  padding-inline: 5px;
  margin-top: 10px;
  box-sizing: border-box;
}
.main {
  width: 100%;
  margin-top: 10px;
  max-height: calc(100vh - 265px);
  overflow: auto;
  padding-inline: 5px;
  box-sizing: border-box;
}
.main[data-children="true"] {
  max-height: calc(100vh - 295px);
}
