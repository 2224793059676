.container {
  width: 100%;
  height: 50px;
  padding-inline: 22px;
  box-sizing: border-box;
  svg{
    margin-top: 20px;
    color: var(--secondary-color)!important;
  }
}
