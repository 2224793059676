.container{
    width: 96%;
    margin: 0 auto;
    .checkbox{
        margin-top: 7px;
    }
    .trade_buttons{
        display: grid;
        grid-template-columns: 49% 49%;
        grid-column-gap: 2%;
        button{
            font-size: 11px;
        }
    }
}