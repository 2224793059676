.container{
    width: 100%;
    margin-bottom: 120px;
    font-family: "IranSansWeb";
    .privacy_text{
        width: 95%;
        margin: 0 auto;
        margin-top: 20px;
        border-radius: 4px;
        line-height: 22px;
        font-size: 17px;
        p{
            padding-inline: 10px;
        }
        strong{
            display: block;
            text-align: center;
            padding-top: 20px;
            padding-bottom: 5px;
            &::first-letter{
                color: red;
            }
        }
    }
}