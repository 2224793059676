.container {
  position: relative;
  width: 90%;
  margin: 0 auto;
  font-family: "IranSansWeb";
  .back_btn {
    position: relative;
    right: 0;
    width: 100%;
    height: 16px;
    margin-top: 30px;
    img {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
  .title_of_page {
    width: 100%;
    position: relative;
    text-align: center;
    color: var(--secondary-color);
  }
  .text_of_guid {
    color: var(--secondary-color);
    text-align: justify;
    margin: 30px auto;
    position: relative;
    width: 90%;
    line-height: 20px;
    font-size: 13px;
    direction: rtl;
  }
  .guid_pic_container {
    width: 100%;
    margin-bottom: 110px;
  }
}
