@import "../../../styles/_global.scss";
.container {
  width: 100%;
  margin-bottom: 50px;
  .recovery_title {
    margin: 0 auto;
    width: 228px;
    height: 34px;
    margin-bottom: 16px;
    margin-top: 27px;
    text-align: center;
    line-height: 32px;
    font-size: 19px;
    color: rgba(43, 63, 99, 1);
    font-family: "IranSansWeb";
  }
  .login_buttons {
    display: grid;
    width: 100%;
    height: 29px;
    grid-template-columns: auto auto;
    text-align: center;
    font-size: 15px;
    line-height: 25px;
    font-family: "IranSansWeb";
  }
  .with_mobile {
    height: 40px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }
  .with_email {
    height: 40px;
    -webkit-tap-highlight-color: transparent;
    cursor: pointer;
  }
  .lines_under_button {
    width: 100%;
    margin-top: 7px;
    display: grid;
    grid-template-columns: 35% 50px 35%;
    justify-content: center;
    .line_button {
      background-color: rgba(181, 176, 176, 1);
      //   width: 130px;
      height: 2px;
    }
    .line_button_active {
      height: 3px;
      background-color: $static_green;
      margin-top: -1px;
    }
  }
  .inputs_place {
    // background-color: red;
    margin: 26px auto;
    overflow: hidden;
    .email_place,
    .pass_place,
    .mobile_place {
      // position: absolute;
      // right: 7%;
      width: 100%;
      justify-content: center;
      display: grid;
      grid-template-columns: auto;
      padding: 10px;
      position: relative;
      input {
        // margin: 0 auto;
        background-color: rgba(196, 196, 196, 0.1);
        width: 290px;
        border-radius: 6px;
        font-family: "IranSansWeb";
        padding: 10px;
      }
      .number_login_input {
        animation-name: shakeInput;
        animation-duration: 1s;
        animation-iteration-count: 1;
      }
      .first_input_icon {
        animation-name: shakeInput;
        animation-duration: 1s;
        animation-iteration-count: 1;
      }
      label {
        font-family: "IranSansWeb";
        right: 35px;
        top: -3px;
        font-size: 14px;
      }
      .icon_place {
        text-align: right;
        margin-top: -35px;
        margin-left: 275px;
        width: 10px;
      }
      .icon_place_active {
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
          brightness(88%) contrast(119%);
      }
    }
  }

  .button_container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    width: 100%;
    // height: 50px;
    text-align: center;
    button {
      width: 306px;
      height: 36px;
      border: inherit;
      border-radius: 32px;
      color: rgba(255, 255, 255, 1);
      font-family: "IRANYekanMobileExtraBold";
      font-size: 14.5px;
    }
    .continue_btn {
      background-color: $static_green;
      margin-top: -10px;
      &:hover {
        transition: background-color 0.6s;
        background-color: $static_green_hover;
      }
    }
    .back_btn {
      margin-top: 15px;
      background-color: $static_darkBlue;
    }
  }
  .errorMessage {
    text-align: right;
    font-family: "IranSansWeb";
    font-size: 12px;
    color: $static_red;
  }
}

//Animation
@keyframes shakeInput {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}
