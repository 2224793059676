@import "./_global.scss";
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: $static_white;
  font-family: "IranSansWeb";
  overflow-x: hidden;
}
a {
  text-decoration: none;
  color: inherit;
}

@for $i from 1 through 40 {
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
}
@for $i from 1 through 40 {
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
}

.hidden {
  display: none;
}
.MuiInputLabel-shrink {
  transform: translate(21px, -9px) scale(0.75) !important;
  top: 0 !important;
}
div.panel-buttons {
  display: none;
}
div.panel-buttons.show {
  display: block !important;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
.alice-carousel__next-btn,
.alice-carousel__prev-btn {
  display: none;
}
.alice-carousel__dots {
  margin-top: 2px !important;
}
.alice-carousel__dots-item {
  border: 1px solid var(--primary-color);
  background-color: transparent !important;
}
.alice-carousel__dots-item.__active {
  background-color: var(--primary-color) !important;
}

@font-face {
  font-family: IRANYekanMobileBlack;
  src: url("./fonts/IRANYekanMobileBlack.ttf");
}
@font-face {
  font-family: IRANYekanMobileBold;
  src: url("./fonts/IRANYekanMobileBold.ttf");
}
@font-face {
  font-family: IRANYekanMobileExtraBlack;
  src: url("./fonts/IRANYekanMobileExtraBlack.ttf");
}
@font-face {
  font-family: IRANYekanMobileExtraBold;
  src: url("./fonts/IRANYekanMobileExtraBold.ttf");
}
@font-face {
  font-family: IRANYekanMobileMobileMedium;
  src: url("./fonts/IRANYekanMobileMedium.ttf");
}
@font-face {
  font-family: IRANYekanMobileRegular;
  src: url("./fonts/IRANYekanMobileRegular.ttf");
}
@font-face {
  font-family: IRANYekanMobileThin;
  src: url("./fonts/IRANYekanMobileThin.ttf");
}
@font-face {
  font-family: IRANYekanMobileLight;
  src: url("./fonts/IRANYekanMobileLight.ttf");
}
@font-face {
  font-family: IranSansWeb;
  src: url("./fonts/IRANSansWeb.woff2");
}
@font-face {
  font-family: "IranYekanBold";
  src: url("./fonts/IRANYekanMobileBold.ttf");
}
.eng-number-font {
  font-family: "IranYekanBold" !important;
}
.MuiTypography-root {
  font-family: IranSansWeb !important;
}
.active_wallet .ArrowImgWallet {
  transform: rotate(90deg) !important;
}
.active-faq .ArrowImgFaq {
  transition: all 0.25s;
  transform: rotate(-90deg) !important;
}
.active-user-details .ArrowImgPersonalInfo {
  transition: all 0.4s;
  transform: rotate(90deg) !important;
}

.convert_basic {
  .MuiPaper-root {
    border: 0.5px solid rgb(0, 0, 0, 0.05);
    background-color: $static_grayLight !important;
    box-shadow: none !important;
    &:focus {
      border: 0.5px solid $static_green !important;
    }
  }
}
.wallet-global {
  .MuiOutlinedInput-notchedOutline {
    border-radius: 7px;
  }
  .Mui-expanded {
    .ArrowImgWallet {
      transform: rotate(90deg) !important;
    }
  }
  .MuiAccordionSummary-root {
    height: 100px !important;
  }
  .MuiPaper-root {
    background-color: rgba(245, 245, 245, 1) !important;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.11) !important;
    border-radius: 10px !important;
  }
}
.trades_list_basic {
  .MuiPaper-root {
    box-shadow: none !important;
  }
}

.color_white_light_mode {
  color: #fff !important;
}
.MuiSlider-root {
  .MuiSlider-rail {
    height: 2px;
    background-color: #202328;
  }
  .MuiSlider-thumb {
    background-color: $static_green;
    width: 15px;
    height: 15px;
  }
  .MuiSlider-track {
    background-color: $static-green;
    border: 1px solid $static-green;
  }
  .MuiSlider-mark {
    width: 5px;
    height: 5px;
    border-radius: 50px;
    border: 1px solid #202328;
    background-color: #fff;
  }
  .MuiSlider-markActive {
    display: none;
  }
}
.slider_professional_market_sell {
  .MuiSlider-thumb {
    background-color: $static_darkRed;
    width: 15px;
    height: 15px;
  }
  .MuiSlider-track {
    background-color: $static_darkRed;
    border: 1px solid $static_darkRed;
  }
}
.scroll-container {
  will-change: transform;
}

.alice-carousel__stage-item {
  padding-left: 5px;
  padding-right: 5px;
  a {
    all: unset;
  }
}
#tradingview_widget_wrapper {
  div:nth-child(2) {
    display: none;
  }
}

.inProfessionalConvert {
  .MuiPaper-root {
    background-color: rgba(196, 196, 196, 0.2);
    box-shadow: none;
    border: 0.5px solid rgb(0, 0, 0, 0.1);
  }
}

.customizePaperProf {
  .MuiPaper-root {
    background-color: rgba(196, 196, 196, 0.2);
    box-shadow: none;
    border: 0.5px solid rgba(196, 196, 196, 0.4);
    min-height: 45px;
    max-height: 45px;
  }
  .customizeSelect {
    .MuiOutlinedInput-root {
      box-shadow: none !important;
      font-family: "IranSansWeb";
      font-size: 14px;
      border: none;
    }
    .MuiInputBase-root {
      border-width: 0px;
      box-shadow: none;
      color: rgb(88, 85, 85);
    }
    .MuiOutlinedInput-root {
      border: none;
    }
    .MuiOutlinedInput-notchedOutline {
      border: 0px;
    }
  }
}
//Customize List Of MenuItem
.MuiList-root {
  direction: rtl;
  .MuiMenuItem-root {
    font-family: "IranSansWeb";
  }
}
.customizeNumberFormate {
  width: 100%;
  .MuiFormControl-root {
    width: 93%;
  }
  .MuiInput-root:before {
    border-bottom: none;
  }
  .MuiInput-root::after {
    border-bottom: none;
  }
  .MuiInput-root:hover:not(.Mui-disabled):before {
    border: none;
  }
}
.bg_green {
  background: #00c194 !important;
}
.bg_red {
  background: #ed6e72 !important;
}
.color_green {
  color: #00c194 !important;
}
.color_red {
  color: #ed6e72 !important;
}
.active-list-aside {
  background: rgba(221, 221, 221, 0.5);
}
.active-menu-market-type {
  background: #e7e7e9;
}
.input-trade-type-1 {
  input {
    border-radius: 5px;
    height: 14px;
    font-family: "IranYekanBold" !important;
    &::placeholder {
      direction: rtl;
      text-align: left;
    }
  }
  .MuiOutlinedInput-root {
    background-color: #fcfcfc;
    p {
      font-size: 13px;
    }
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: rgba(16, 208, 120, 1);
  }
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: rgba(16, 208, 120, 1);
  }
}

.button-plus-trade,
.button-minus-trade {
  background: transparent;
  border-width: 0px;
  font-size: 17px;
  color: #837f7f;
  outline: none;
  max-width: 20px;
}
.button-minus-trade {
  position: relative;
  top: -1.15px;
}
.span-button-trade {
  width: 1px;
  height: 20px;
  background: rgba(131, 127, 127, 0.5);
}
.box-text-markets {
  width: 100%;
  height: 48px;
  text-align: center;
  line-height: 47px;
  font-family: "IranSansWeb";
  border-radius: 3px;
  font-size: 11.2px;
  border: 1px solid rgba(187, 187, 187, 0.6);
  p {
    padding: 0;
    margin: 0;
    color: #9d9ea8;
  }
}
.button-shadow {
  width: 100%;
  height: 35px;
  outline: none;
  border-width: 0px;
  background: #e7e7e7;
  border-radius: 3px;
  font-family: "IranSansWeb";
  color: #837f7f;
}
.button-shadow-active {
  background: rgba(0, 193, 148, 0.22) !important;
  color: #00c194 !important;
}
.box-proferssional {
  width: 70%;
  height: 155px;
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  // background: #151c30;
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.064);
  background: #ffff;
  border-radius: 5px;
  &::before {
    content: "";
    position: absolute;
    width: 110%;
    height: 110%;
    background: rgba(209, 209, 209, 0.17);
    top: -70%;
    left: -5%;
    border-radius: 50%;
  }
  &:first-child {
    margin-top: 55px;
  }
}
.select-market {
  .Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #00c194 !important;
  }
  .Mui-disabled .MuiSelect-select {
    -webkit-text-fill-color: var(--item-color) !important;
    opacity: 0.5;
  }
}
.select-market-input-props {
  ul {
    direction: ltr !important;
    li {
      font-family: "IranYekanBold" !important;
    }
  }
}
.datePickerInMarketHistory > div {
  label {
    display: none;
  }
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 49%);
  grid-column-gap: 2%;
  input {
    width: 100%;
    height: 33px;
    border-width: 0px;
    background: #f2f1f1;
    color: #202328;
    text-align: left;
    outline-color: #00c194;
    padding-left: 5px;
  }
  div:nth-child(2) {
    display: inline;
  }
  div:nth-child(4) {
    display: inline;
  }
}
///
//////
////
//////// DARK ----> MODE
///
////
.--color-primary {
  color: #000;
}

.bg-secondary {
  background: rgba(180, 176, 176, 0.1);
}
.bg_F2F8F6_202328 {
  background: #f2f8f6;
}
body.dark {
  .MuiPaper-elevation8 {
    background: #202328 !important;
    color: #fff !important;
    .Mui-selected {
      background: rgba(25, 25, 25, 0.8);
    }
  }
  .bg_F2F8F6_202328 {
    background: #202328;
  }
  .bg-secondary {
    background: #141823;
  }
  .--color-primary {
    color: #fff;
  }
  .select-market {
    .MuiSvgIcon-root {
      color: #e1e2e4 !important;
    }
  }
  .datePickerInMarketHistory div {
    input {
      background: #202328;
      color: #9d9ea8;
    }
  }
  .box-proferssional {
    background: #151c30;
    border-radius: 5px;
    &::before {
      content: "";
      position: absolute;
      width: 110%;
      height: 110%;
      background: rgba(39, 46, 64, 0.3);
      top: -70%;
      left: -5%;
      border-radius: 50%;
    }
  }
  .border_color_272E40 {
    border-color: #202328 !important;
  }
  .button-shadow {
    background: #202328;
    color: #ffffff;
  }
  .box-text-markets {
    border: 2px solid #151c30;
  }
  .button-plus-trade,
  .button-minus-trade {
    color: #c4c4c4;
  }
  .input-trade-type-1 {
    .MuiOutlinedInput-root {
      background-color: #202328;
    }
    .MuiInputAdornment-root p {
      color: rgb(150, 150, 150) !important;
    }
    input {
      font-family: "IranYekanBold" !important;
      color: #fff;
    }
    fieldset {
      // border-radius: 0px;
    }
  }
  .active-menu-market-type {
    background: #202328;
  }
  .active-list-aside {
    background: #202328;
  }
  .border_bottom_272E40 {
    border-bottom-color: #202328 !important;
  }
  .change-border-light fieldset {
    border-color: #bbbbbb;
  }
  .change-border-light svg {
    color: #bbb;
  }
  background-color: #191919;
  .darkModeSettingGlobal {
    background-color: #202328;
    color: #fff;
  }
  #formatted-numberformat-input {
    color: #fff;
  }
  /////
  .darkModeInTradeList {
    .MuiPaper-root {
      background-color: #202328 !important;
    }
    .darkModeInTradeListForTexts {
      color: #fff !important;
    }
  }
  .font_color_white {
    color: #fff !important;
  }
  .border_bottom_to_151C30 {
    border-bottom-color: #202328 !important;
  }
  .changeFontColorToBlack {
    color: #000 !important;
  }
  .blurBackGroundDarkMode {
    background-color: rgba(196, 196, 196, 0.2) !important;
    filter: blur(4px) !important;
  }
  //
  .button_all_amount_darkMode {
    border: 0.5px solid $static-green !important;
    color: $static-green !important;
    &:hover {
      background-color: $static-green !important;
      color: #fff !important;
    }
  }
  //
  //SEND CODE DARK MODE
  .button_send_code_darkMode {
    background-color: transparent !important;
    &:active {
      background-color: $static_green;
      color: #fff;
    }
  }
  ///
  //Custom Check For Withdraw ...
  .check_custom_darkMode {
    border: 1px solid $static-green !important;
  }
  .RadioNetworkCheckDarkMode:checked ~ .check_custom_darkMode {
    background-color: $static-green !important;
  }
  ///
  .change_to_151C30 {
    background: #151c30 !important;
  }
  .change_color_to_9D9EA8 {
    color: #9d9ea8 !important;
  }
  .change_color_to_E1E2E4 {
    color: #e1e2e4 !important;
  }
  .changeBackgroundToWhite {
    background-color: #fff !important;
  }
  .font_color_change_to_green {
    color: $static-green !important;
  }
  .font_color_change_to_red {
    color: rgba(246, 85, 85, 1) !important;
  }
  .create_border_red_darkMode {
    border: 1px solid rgba(246, 85, 85, 1) !important;
  }
  .create_border_green_darkMode {
    border: 1px solid $static-green !important;
  }
  /////
  .changeTextFieldBackgroundToWhite input {
    background-color: #fff !important;
    color: #000 !important;
  }
  .changeTextFieldBackgroundToWhite label {
    color: $static-green !important;
  }
  ///
  .changeTextFieldBackgroundToWBlack input {
    background-color: #202328 !important;
    color: #fff !important;
  }
  .changeTextFieldBackgroundToWBlack label {
    color: $static-green !important;
  }
  ////
  .changeSearchFieldBoxDarkMode {
    // .MuiFormControl-root {
    .MuiOutlinedInput-root {
      background-color: #202328 !important;
      color: #fff !important;
    }
    label {
      color: $static-green !important;
    }
    fieldset {
      border-color: #837f7f !important;
    }
    color: #fff !important;
  }
  ///DARK MODE FOR CONVERT INPUT
  .darkModeForConvertInputs {
    .MuiPaper-root {
      background-color: #202328 !important;
    }
    input {
      color: #fff !important;
    }
    label {
      color: #fff !important;
    }
    .Mui-disabled {
      -webkit-text-fill-color: rgb(211, 202, 202) !important;
    }
    .MuiDivider-root {
      background-color: $static-green;
    }
  }
  //Arrow Felesh Payeen -->
  .MuiSvgIcon-root:not(.no-svg-change) {
    color: $static-green;
  }
  ///
  .convertPicToGreen {
    filter: invert(59%) sepia(98%) saturate(391%) hue-rotate(99deg)
      brightness(90%) contrast(95%);
  }
  .convertPicToWhite {
    filter: invert(100%) sepia(39%) saturate(532%) hue-rotate(254deg)
      brightness(114%) contrast(200%);
  }
  .changeBackgroundToGreen {
    background-color: $static-green !important;
  }
  .changeBackgroundColorToHardBlack {
    background-color: #202328 !important;
  }
  .changeBackgroundColorToBlack {
    background-color: #191919 !important;
  }
  .changeBackgroundColorToGreen {
    background-color: $static-green !important;
  }
  .addBorderGreen {
    border: 1px solid $static-green !important;
  }
  .addBorderWhite {
    border: 1px solid #fff !important;
  }
  .changeTextColorToGreen {
    color: $static-green !important;
  }
  .changeTextColorToDarkBlue {
    color: #202328 !important;
  }
  .hiddenOfDarkMode {
    display: none;
  }
  .darkModeInTradeList {
    label {
      color: #fff !important;
    }
    .MuiOutlinedInput-input {
      background-color: #202328 !important;
      color: #fff !important;
    }
  }
  .darkModeOnDatePicker {
    input {
      background-color: #202328 !important;
      color: #fff !important;
    }
  }

  .changePolygonColorOfTransactionInDarkMode {
    border-right: 8px solid $static-green !important;
  }

  .checkBoxDarkModeOnWallet {
    background-color: #202328 !important;
    border: 1px solid #202328 !important;
  }
  .sliderDarkMode {
    .MuiSlider-root {
      .MuiSlider-rail {
        background-color: rgb(255, 255, 255) !important;
        opacity: 1;
      }

      .MuiSlider-mark {
        border: 1px solid $static_green !important;
        background-color: $static_green !important;
      }
      .MuiSlider-markActive {
        display: none;
      }
    }
  }
  .sliderDarkModeRed {
    .MuiSlider-root {
      .MuiSlider-rail {
        background-color: rgb(255, 255, 255) !important;
        opacity: 1;
      }

      .MuiSlider-mark {
        border: 1px solid $static_darkRed !important;
        background-color: $static_darkRed !important;
      }
      .MuiSlider-markActive {
        display: none;
      }
    }
  }
  .forChangeCheckboxInDarkMode {
    input {
      &:checked ~ .checkBoxDarkModeOnWallet {
        background-color: $static-green !important;
        border: 1px solid $static-green !important;
      }
    }
  }
  .highChartDark .highcharts-background {
    fill: #000 !important;
  }
}

.no-pic-toShow {
  width: 100%;
  height: 180px;
  border-radius: 3px;
  background: linear-gradient(
    90deg,
    var(--third-color) 0%,
    var(--third-color) 35%,
    var(--third2-color) 45%,
    var(--third2-color) 55%,
    var(--third-color) 65%,
    var(--third-color) 100%
  );
  background-size: 400%;
  animation: loadingNoPic 1.5s infinite;
}
@keyframes loadingNoPic {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100%;
  }
}
.border-fieldset-none {
  fieldset {
    border: none !important;
  }
}
.font-fa-light {
  font-family: "IranSansWeb";
}
.twofacselect {
  .MuiOutlinedInput-root {
    height: 50px;
  }
  .MuiFormHelperText-root {
    margin-top: -20px;
  }
}
.dir-rtl {
  direction: rtl;
}
.dir-lrt {
  direction: ltr;
}

.illegal_market {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #191919;
  z-index: 20;
  display: flex;
  justify-content: center;
  align-items: center;
  direction: rtl;
  text-align: center;
  .illegal_market_box {
    box-sizing: border-box;
    padding: 20px;
    background: #202328;
    width: 85%;
    min-height: 270px;
    border-radius: 3px;
    .illegal_market_logo {
      img {
        width: 120px;
        height: 80px;
      }
    }
    p {
      text-align: center;
      font-family: "IranSansWeb";
      color: #fff;
    }
    .illegal_button_contianer {
      button {
        font-family: "IranSansWeb";
        outline: none;
        width: 120px;
        background: transparent;
        border-width: 0;
        color: #00c194;
        border: 1px solid #00c194;
        border-radius: 3px;
        padding-block: 7px;
      }
    }
  }
}

//# Customize The Input With Override CSS
.PRIMARY_INPUT_CONTAINER {
  .PRIMARY_INPUT_CHILD {
    font-family: "IranYekanBold";
    color: var(--secondary-color) !important;
    font-size: 18px;
    &::placeholder {
      font-size: 16px;
    }
  }
  fieldset {
    border-color: var(--border-color) !important;
  }
  .Mui-focused {
    fieldset {
      border-color: var(--primary-color) !important;
      border-width: 1px !important;
    }
  }
}
.ShowCardListPage {
  .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
    border-color: var(--border-color) !important;
  }
  svg {
    color: var(--main-color) !important;
  }
  color: var(--main-color) !important;
  .Mui-focused.MuiOutlinedInput-notchedOutline {
    border-color: var(--primary-color) !important;
  }
}

//# Chat Global Styles
#raychat_widget {
 display: none;
}
