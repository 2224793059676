.item {
  width: 100%;
  margin-top: 8px;
  padding-bottom: 10px;
  border-bottom: 1px solid #e1e2e4;
  direction: rtl;
  font-family: "IranSansWeb";
  .one {
    display: grid;
    grid-template-columns: 55% 45%;
    justify-content: space-between;
    align-items: center;
    .name {
      font-size: 12.5px;
      margin-top: 7px;
      color: #837f7f;
      .icon_buy_sell {
        font-size: 20px;
        position: relative;
        top: 5px;
      }
    }
    .work {
      direction: ltr;
      position: relative;
      top: 5px;
      button {
        padding-inline: 15px;
        padding-block: 4px;
        font-size: 13px;
        outline: none;
        border-width: 0px;
        background: transparent;
        margin-right: 10px;
        border-radius: 3px;
        border: 1px solid;
        font-family: "IranSansWeb";
      }
      .cancel_btn {
        color: #ed6e72;
        border-color: #ed6e72;
      }
      .update_btn {
        color: #00c194;
        border-color: #00c194;
        &:disabled{
            color: #837f7f;
            border-color: #e1e2e4;
        }
      }
    }
  }
  .second {
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    justify-content: space-between;
    color: #837f7f;
    font-size: 13px;
    margin-top: 7px;
    .date {
      margin-top: 2px;
    }
    .market_type {
      font-size: 12px;
      text-align: center;
      span {
        border: 1px solid currentColor;
        border-radius: 3px;
        padding-inline: 10px;
        padding-block: 3px;
      }
    }
    .area_price {
      margin-top: 2px;
      text-align: left;
    }
  }
  .three,
  .four {
    margin-top: 5px;
    display: grid;
    grid-template-columns: 33% 33% 33%;
    align-items: center;
    justify-content: space-between;
    .status {
      text-align: left;
    }
    .titles {
      color: #837f7f;
      font-size: 12px;
    }
    .titles ~ div {
      margin-top: -3px;
      font-size: 13px;
      color: #272e40;
    }
    .amount {
      text-align: right;
    }
    .dones {
      text-align: center;
    }
    .undones {
      text-align: left;
    }
    .trade_type {
      text-align: center;
    }
    .trade_type > .titles ~ div {
      margin-top: -5px;
    }
  }
}
