.menu {
  z-index: 3;
  position: fixed;
  transition-property: bottom;
  transition-timing-function: ease-in-out;
  transition-duration: 0.3s;
  position: fixed;
  background: #fcfcfc;
  width: 100%;
  bottom: 0;
  border-radius: 24px 24px 0px 0px;
  font-family: "IranSansWeb";
  color: #1c2730;
  h5 {
    text-align: center;
    margin: 0;
    margin-block: 10px;
    font-weight: normal;
    font-size: 17px;
  }
  .items {
    width: 100%;
    .radio_market:checked ~ label {
      font-weight: 600;
      .checkbox {
        border: 1px solid #00c194;
        background: #00c194;
      }
    }
    .item .label {
      width: 100%;
      height: 45px;
      display: grid;
      direction: rtl;
      grid-template-columns: 40px auto;
      align-items: center;
      padding-inline: 20px;
      .checkbox {
        width: 15px;
        height: 15px;
        border: 1px solid #9d9ea8;
        border-radius: 3px;
      }
    }
  }
  .btn {
    padding-inline: 20px;
    width: 100%;
    height: 65px;
    display: flex;
    align-items: center;
    justify-content: center;
    button {
      width: 100%;
      height: 40px;
      font-family: "IranSansWeb";
      font-size: 17px;
      border-width: 0px;
      outline: none;
      border-radius: 3px;
      color: #1c2730;
    }
  }
}
.bg {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  background-color: rgba(208, 208, 208, 0.26);
  z-index: 2;
}
.hidden {
  bottom: -100%;
}
.visible {
  bottom: 0;
}
