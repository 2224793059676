._a {
  -webkit-tap-highlight-color: transparent;
}
.container {
  width: 100%;
  height: 160px;
  background: var(--temp-color);
  border-radius: 14px;
  .image {
    margin-top: 17px;
    align-items: center !important;
  }
  .name_percent {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    column-gap: 4px;
    margin-block: 4px;
    strong {
      color: var(--secondary-color);
    }
    span {
      font-size: 14px;
      color: var(--danger-color);
      font-family: "IranYekanMobileRegular" !important;
      &[data-positive="true"] {
        color: var(--primary-color);
      }
    }
  }
  .price {
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center;
    color: var(--secondary-color);
    column-gap: 3px;
    font-family: "IranYekanMobileRegular";
    font-size: 15px;
    span {
      font-size: 12px;
      opacity: 0.9;
      color: var(--secondary3-color);
    }
  }
  .message {
    margin-top: 6px;
    color: var(--primary-color);
    flex-direction: row !important;
    align-items: center !important;
    justify-content: center !important;
    direction: rtl;
    column-gap: 3px;
    svg {
      font-size: 16px;
      margin-top: 6px;
      color: var(--primary-color) !important;
    }
    p {
      font-size: 12px;
    }
    &[data-positive="false"] {
      color: var(--danger-color);
      svg {
        color: var(--danger-color) !important;
        transform: rotate(180deg);
      }
    }
  }
}
