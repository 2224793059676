.mainView {
  background-color: var(--third-color);
  border-radius: 26px 26px 0 0;
  min-height: calc(100vh - 140px);
  padding-inline: 10px;
  margin-bottom: 80px;
}
.imageHolder {
  width: 70px;
  height: 70px;
  img {
    width: 100%;
    height: 100%;
  }
}
.namesContainer {
  margin-top: 5px;
  span {
    word-break: break-all;
    color: var(--secondary-color);
    font-size: 14px;
    &[data-rtl="true"] {
      direction: rtl;
    }
  }
  padding-bottom: 10px;
  border-bottom: 1px solid var(--border-color);
}

.amountContainer {
  background-color: var(--temp-color);
  margin-top: 10px;
  border-radius: 5px;

  .item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    padding-block: 4px;
    span {
      font-size: 13px;
      color: var(--secondary3-color);
    }
    p {
      margin: 0;
      font-family: "IranYekanBold" !important;
      color: var(--secondary-color);
    }
  }
  padding: 10px 5px;
  button {
    background-color: transparent;
    border-style: none;
    border-bottom: 1px dashed var(--primary-color);
    padding-bottom: 3px;
    font-family: inherit;
    color: var(--primary-color);
    font-size: 11px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    outline: none;
    &:hover {
      opacity: 0.8;
    }
  }
}

.buttonOfTransfers {
  background-color: var(--temp-color);
  padding: 0px 5px;
  border-radius: 3px;
  span {
    color: var(--secondary3 -color);
    font-size: 12px;
  }
  button {
    min-height: 37px;
    font-family: inherit;
    background-color: transparent;
    border-style: none;
    outline: none;
    border-radius: 3px;
    color: var(--secondary-color);
    padding-inline: 14px;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    &[data-active="true"] {
      background-color: var(--primary-color);
    }
  }
}

.selectNetworkBox {
  background-color: var(--temp-color);
  min-height: 37px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding-inline: 10px;
  color: var(--secondary3-color);
  border-radius: 3px;
  span:nth-child(1) {
    font-size: 13px;
    direction: rtl;
  }
  span:nth-child(2) {
    color: var(--secondary-color);
  }
  .fee {
    font-family: "IranYekanBold" !important;
    margin-left: 4px;
  }
}

.chainsModalAndInfoContainer {
  padding-bottom: 45px;
  max-height: 300px;
  min-height: 100px;
  overflow: auto;
  background-color: var(--third-color);
  border-radius: 15px 15px 0 0;
  box-shadow: rgba(0, 0, 0, 0.6) 0px 3px 8px;
  .titleChainModal {
    margin: 0;
    direction: rtl;
    padding-inline: 10px;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom: 10px;
    span {
      display: block;
      text-align: center;
    }
    span:nth-child(1) {
      color: var(--secondary-color);
    }
    span:nth-child(2) {
      color: var(--danger-color);
    }
  }
  .chainItem {
    background-color: var(--temp-color);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 10px;
    min-height: 40px;
    padding-block: 6px;
    color: var(--secondary-color);
    font-size: 14px;
    margin-bottom: 5px;
    direction: rtl;
    &[data-active="false"] {
      .name {
        font-size: 11px;
        span {
          color: var(--danger-color);
        }
      }
    }
    &[data-selected="true"] {
      background-color: var(--primaryOpacity-color);
    }
    .amounts {
      .list {
        font-family: "IranYekanBold" !important;
        text-align: left;
        display: block;
        margin-top: 3px;
        .symbol {
          font-size: 12px;
        }
      }
    }
  }
}
.qrCodeScanContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: var(--temp-color);
  .qrCodeScanner {
    position: relative;
    top: 50%;
    transform: translate(0, -50%);
  }
  .closeCamera {
    position: absolute;
    left: 50%;
    top: 15%;
    transform: translate(-50%, -10%);
    background-color: transparent;
    outline: none;
    padding: 10px;
    border-style: none;
    svg {
      font-size: 40px;
      color: var(--danger-color) !important;
    }
  }
}

.overNotifContainer {
  direction: rtl;
  font-size: 13px;
  color: var(--primary-color);
  list-style: decimal;
  list-style-position: inside;
  background-color: var(--primaryOpacity-color);
  border-radius: 3x;
  text-align: justify;
  margin-bottom: 15px;
  li {
    margin-bottom: 5px;
    font-size: 12px;
  }
  padding: 5px;
}
.notificationError {
  font-size: 12px;
  color: var(--danger-color);
  direction: rtl;
  display: block;
  text-align: justify;
}
.withdrawErrorContainer {
  box-sizing: border-box;
  margin: 0;
  font-size: 14px;
  text-align: center;
  padding-inline-start: 0;
  direction: rtl;
  list-style-type: none;
  li{
    margin-bottom: 3px;
    color: var(--danger-color);
  }
}
.btnResetErrors {
  margin-top: 40px;
}
