.item_container {
  min-height: 100px;
  background: var(--third-color);
  padding: 10px;
  border-radius: 5px;
  margin-top: 5px;
  .item_group {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    padding-block: 4px;
    span {
      font-size: 12.5px;
      color: var(--secondary3-color);
      font-family: "IranSansWeb" !important;
    }
    p {
      font-family: "IranYekanBold" !important;
      font-size: 14.5px;
      color: var(--secondary-color);
      &[data-color="danger"] {
        color: var(--danger-color);
      }
      &[data-color="success"] {
        color: var(--primary-color);
      }
      &[data-color="warning"] {
        color: var(--warning-color);
      }
    }
  }
  .cancel {
    outline: none;
    font-family: "IranSansWeb";
    background-color: var(--danger-color);
    color: #fff;
    border-radius: 3px;
    border-width: 0;
    padding: 4px 12px;
    font-size: 13px;
    &:disabled {
      background-color: var(--border-color);
      opacity: 0.6;
    }
  }
}

.noWthFound {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.2);
  color: var(--secondary-color);
  margin-top: 30px;
  padding-block: 7px;
  font-size: 14px;
}
