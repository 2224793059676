.container {
  width: 100%;
  font-family: "IranSansWeb";
  .item {
    direction: rtl;
    display: grid;
    grid-template-columns: 20px auto;
    align-items: center;
    width: 100%;
    padding-inline: 10px;
    padding-block: 5px;
    color: #01081e;
    border-bottom: 1px solid rgba(225, 226, 228,0.5);
    .icon_item {
      font-size: 18px;
    }
    .text_item{
        font-size: 13px;
    }
    .history {
      font-size: 19px;
      position: relative;
      top: 4px;
      left: 3px;
    }
  }
  .item_no_border{
    border-bottom: 0px;
  }
  .rotate_90 {
    transform: rotate(90deg);
  }
  .rotate_minus_90 {
    position: relative;
    left: 10px;
    transform: rotate(-90deg);
  }
}
