@import "../../../styles/_global.scss";
.container {
  grid-template-columns: auto;
  position: relative;
  display: grid;
  width: 100%;
  font-family: "IranSansWeb";
  .BackArrow {
    position: relative;
    width: 100%;
    height: 15px;
    margin-top: 20px;
    img {
      width: 15px;
      height: 15px;
      left: 22px;
      transform: rotate(180deg);
      position: absolute;
    }
  }
  .title_component {
    position: relative;
    width: 100%;
    height: 20px;
    text-align: center;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .container_of_FAQs {
    position: relative;
    margin: 0 auto;
    width: 90%;
    display: grid;
    grid-template-columns: auto;
    margin-bottom: 110px;
    .item_of_FAQ_container {
      margin-bottom: 15px;
      width: 100%;
      border-radius: 3px;
      border: 0.5px solid #202020;
      text-align: justify;
      .subject_message_container {
        position: relative;
        width: 100%;
        height: 42px;
        direction: rtl;
        display: grid;
        grid-template-columns: auto;
        text-indent: 20px;
        .title_of_meesage {
          width: 100%;
          margin-top: 15px;
          font-size: 13px;
          animation-name: ScaleArrow;
          animation-duration: 0.4s;
          animation-iteration-count: 1;
        }
      }
      .ArrowIconContainer {
        width: 15px;
        height: 15px;
        position: absolute;
        left: 10px;
        top: 15px;
        img {
          position: absolute;
          top: 0;
          left: 0;
          transform: rotate(90deg);
        }
      }
      .text_message_container {
        position: relative;
        width: 100%;
        .line_1 {
          width: 100%;
          height: 1px;
          background-color: rgba(44, 64, 101, 1);
        }
        .textOfMessage {
          line-height: 20px;
          direction: rtl;
          padding-right: 20px;
          padding-left: 15px;
          padding-top: 8px;
          padding-bottom: 8px;
          font-size: 13px;
        }
      }
    }
  }
}

//animation for bell icon
@keyframes shakeBell {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(25deg);
  }
  50% {
    transform: rotate(0deg);
  }
  75% {
    transform: rotate(-25deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes ScaleArrow {
  from {
    transform: scale(0.1);
  }
  to {
    transform: scale(1);
  }
}
