.boxTitle {
  font-family: "IranYekanBold" !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  color: var(--secondary-color);
  .tagTitle {
    font-size: 11.5px;
    color: var(--secondary-color);
  }
}
.wthTitle {
  font-size: 13px;
  margin-bottom: 5px;
  color: var(--secondary-color);
  text-align: center;
  width: 100%;
}
.titleLeft {
  font-family: "IranYekanBold" !important;
}

.buttonItems {
  font-family: "IranSansWeb" !important;
  color: var(--secondary-color) !important;
  border-bottom: 1px solid transparent !important;
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  &[data-selected="true"] {
    color: var(--primary-color) !important;
    border-bottom-color: var(--primary-color) !important;
  }
}

.searchBox {
  background-color: var(--main2-color);
  border-style: solid;
  border-radius: 2px;
  outline: transparent;
  min-height: 34px;
  border-color: var(--border-color);
  padding: 2px 5px;
  box-sizing: border-box;
  color: var(--secondary-color);
  border-width: 1px;
  font-family: "IranSansWeb";
  &:focus {
    border-color: var(--primary-color);
  }
}

.item_wallet {
  min-height: 80px;
  margin-bottom: 5px;
  border-radius: 3px;
  background-color: var(--layout-color);
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .imageSide {
    .imagePre {
      display: flex;
      align-items: center;
      span {
        color: var(--secondary-color);
        font-size: 12px;
        margin-right: 5px;
      }
    }
    strong {
      font-size: 13px;
      color: var(--secondary-color);
      font-weight: 200;
    }
  }
  .priceSide {
    direction: ltr;
    .symbol {
      font-size: 11px;
      color: var(--secondary-color);
      opacity: 0.8;
      margin-inline-start: 7px;
    }
    .number {
      font-size: 14px;
      color: var(--secondary-color);
      font-family: "IranYekanBold" !important;
    }
  }
}
.titleModalPrice {
  font-size: 14px;
  color: var(--secondary-color);
}
.priceModal {
  font-size: 16px;
  color: var(--secondary-color);
  font-family: "IranYekanBold";
}
.update {
  background-color: var(--primary-color);
  border-style: none;
  font-family: "IranSansWeb";
  border-radius: 2px;
  padding: 5px 15px;
  color: #ffff;
  outline: transparent;
  &:disabled {
    background-color: var(--border-color);
    opacity: 0.8;
  }
}
.button_group {
  font-family: "IranSansWeb";
  border-radius: 2px;
  padding: 8px 12px;
  outline: transparent;
  width: 100%;
  border-style: outset;
  border-color: rgba(0, 0, 0, 0.2);
  &[data-color="warning"] {
    color: var(--warning-color);
    background-color: var(--warningOpacity-color);
  }
  &[data-color="danger"] {
    color: var(--danger-color);
    background-color: var(--dangerOpacity-color);
  }
  &[data-color="success"] {
    color: var(--primary-color);
    background-color: var(--primaryOpacity-color);
  }
  &:disabled {
    background-color: var(--border-color);
  }
}

.item_wth {
  min-height: 150px;
  background-color: var(--layout-color);
  margin-bottom: 8px;
  border-radius: 3px;
  margin-top: 15px;
  position: relative;
  padding-top: 25px;
  padding-bottom: 5px;
  box-sizing: border-box;
  direction: ltr;
  .top_item {
    position: absolute;
    top: -10px;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 5px;
    box-sizing: border-box;
    span {
      font-size: 13px;
      padding: 3px 13px;
      border-radius: 3px;
    }
    .type {
      &[data-type="WITHDRAW"] {
        color: var(--danger-color);
        background-color: var(--dangerOpacity-color);
      }
      &[data-type="DEPOSIT"] {
        color: var(--primary-color);
        background-color: var(--primaryOpacity-color);
      }
    }
    .status {
      &[data-status="danger"] {
        color: var(--danger-color);
        background-color: var(--dangerOpacity-color);
      }
      &[data-status="success"] {
        color: var(--primary-color);
        background-color: var(--primaryOpacity-color);
      }
      &[data-status="warning"] {
        color: var(--warning-color);
        background-color: var(--warningOpacity-color);
      }
    }
  }
  .flexItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 8px;
    box-sizing: border-box;
    color: var(--secondary-color);
    font-family: "IranYekanBold";
    font-size: 15px;
    padding-block: 5px;
    word-break: break-all;
    span:nth-child(2) {
      min-width: fit-content;
      padding-left: 10px;
      svg {
        color: var(--primary-color) !important;
        margin-top: -3px;
      }
    }
    span {
      &[data-color="DEPOSIT"] {
        color: var(--primary-color);
      }
      &[data-color="WITHDRAW"] {
        color: var(--danger-color);
      }
    }
  }
  .cancel_order {
  }
}
