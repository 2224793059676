.container {
  width: 100%;
  height: 90px;
  background: var(--temp-color);
  border-radius: 18px;
  padding-top: 15px;
  padding-inline: 12px;
  box-sizing: border-box;
}

.item_container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  .item {
    width: 100%;
    text-align: center;
    font-family: "IranSansWeb";
    .top {
      display: flex;
      flex-direction: row;
      align-items: center;
      column-gap: 4px;
    }
    strong {
      font-size: 12.4px;
      white-space: nowrap;
      color: var(--secondary3-color);
    }
    span {
      color: var(--danger-color);
      font-family: "IranYekanMobileRegular";
      font-size: 11.3px;
      &[data-positive="true"] {
        color: var(--primary-color);
      }
    }
    p {
      color: var(--secondary-color);
      margin: 0;
      margin-top: 2px;
      font-size: 14.7px;
      font-family: "IranYekanMobileRegular";
      padding: 0;
    }
  }
}
