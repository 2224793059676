.item {
  min-height: 150px;
  background-color: var(--third-color);
  margin-bottom: 8px;
  border-radius: 3px;
  margin-top: 15px;
  position: relative;
  padding-top: 25px;
  padding-bottom: 5px;
  box-sizing: border-box;
  .top_item {
    position: absolute;
    top: -10px;
    display: flex;
    width: 100%;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    padding-inline: 5px;
    box-sizing: border-box;
    span {
      font-size: 13px;
      padding: 3px 13px;
      border-radius: 3px;
    }
    .status,
    .type {
      &[data-status="danger"] {
        color: var(--danger-color);
        background-color: var(--dangerOpacity-color);
      }
      &[data-status="success"] {
        color: var(--primary-color);
        background-color: var(--primaryOpacity-color);
      }
      &[data-status="warning"] {
        color: var(--warning-color);
        background-color: var(--warningOpacity-color);
      }
    }
  }
  .flexItems {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline: 8px;
    box-sizing: border-box;
    color: var(--secondary-color);
    font-family: "IranYekanBold";
    font-size: 15px;
    padding-block: 5px;
    word-break: break-all;
    span:nth-child(2) {
      min-width: fit-content;
      padding-left: 10px;
      svg {
        color: var(--primary-color) !important;
        margin-top: -3px;
      }
    }
    span {
      &[data-color="success"] {
        color: var(--primary-color);
      }
      &[data-color="danger"] {
        color: var(--danger-color);
      }
      &[data-color="warning"] {
        color: var(--warning-color);
      }
    }
  }
}
