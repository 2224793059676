@import "../../../../styles/_global.scss";
.container {
  display: none;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  height: 110vh;
  background-color: #fff;
  font-family: "IRANYekanMobileBold";
  z-index: 5;
  .back_btn {
    position: absolute;
    width: 20px;
    height: 20px;
    right: 22px;
    top: 22px;
  }
  .title_of_page {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    text-align: center;
    font-size: 18px;
    margin-top: 50px;
  }
  .line {
    position: relative;
    left: 50%;
    transform: translate(-50%);
    width: 200px;
    height: 1px;
    background-color: $static_darkBlue;
    margin: 20px 0px 30px 0px;
  }
  ////
  ////
  ////
  //BODY OF CONTAINER\\
  header {
    width: 94%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 35% 64%;
    text-align: center;
    grid-column-gap: 3px;
    color: #fff;
    margin-bottom: 3px;
    div {
      width: 100%;
      background-color: $static_darkBlue;
      height: 46px;
      border-radius: 2px 2px 0px 0px;
      line-height: 46px;
      font-size: 13px;
    }
  }
  .main {
    margin: 0 auto;
    width: 94%;
    display: grid;
    grid-template-columns: 35% 64%;
    grid-column-gap: 3px;
    color: #000;
    text-align: justify;
    text-align: center;
    .person,
    .info {
      width: 100%;
      border-radius: 0px 0px 2px 2px;
      background-color: rgba(243, 243, 243, 1);
      font-size: 13px;
    }
    .person {
      div {
        position: relative;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        line-height: 25px;
      }
    }
    ///INFO ACCOUNT CSS
    .info {
      line-height: 20px;
      .item_column {
        padding-bottom: 12px;
        .item_title {
          direction: rtl;
          padding-bottom: 2px;
        }
        .item_row {
          display: grid;
          grid-template-columns: auto auto;
          justify-content: center;
          font-size: 14px;
          .copy {
            width: 20px;
            height: 10px;
          }
        }
      }
    }
  }
  .main:last-child {
    .line_two {
      display: none;
    }
  }
  .line_two {
    width: 100%;
    height: 1px;
    background-color: rgba(124, 119, 119, 0.48);
  }
}
.container_show {
  display: block;
}
