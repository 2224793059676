@import "../../../styles/_global.scss";

.container {
  position: relative;
  width: 100%;
  font-family: "IranSansWeb";
  .input_confirm_code {
    .title_confirm {
      text-align: center;
      font-size: 17px;
      margin-top: 20px;
      color: rgba(43, 63, 99, 1);
    }
    .input_places {
      margin-top: 20px;
      width: 100% !important;
    }
    .input_places > div {
      width: 100% !important;
      display: grid;
      grid-template-columns: repeat(5, auto);
      justify-content: center;
      grid-gap: 20px;

      input {
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 0;
        border-radius: 3px;
        outline: none;
        border: 1.3px solid $static_green !important;
        font-size: 15px;
        font-family: "IranSansWeb";
        &::placeholder {
          color: rgba(0, 0, 0, 1);
        }
        &::-moz-placeholder {
          color: rgba(0, 0, 0, 1);
        }
        &::-webkit-input-placeholder {
          color: rgba(0, 0, 0, 1);
        }
      }
    }
    .text_guid_verify {
      margin: 0 auto;
      width: 297px;
      color: red;
      font-size: 11px;
      direction: rtl;
      margin-top: 3px;
    }
  }
  .password_container {
    margin-top: 20px;
    overflow: hidden;
    .pass_place {
      width: 100%;
      justify-content: center;
      display: grid;
      grid-template-columns: auto;
      padding: 10px;
      position: relative;
      input {
        background-color: rgba(196, 196, 196, 0.1);
        width: 290px;
        border-radius: 6px;
        font-family: "IranSansWeb";
        padding: 10px;
      }
      label {
        font-family: "IranSansWeb";
        right: 35px;
        top: -3px;
        font-size: 14px;
      }
      .icon_place {
        text-align: right;
        margin-top: -35px;
        margin-right: 8px;
      }
      .icon_place_active {
        filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
          brightness(88%) contrast(119%);
      }
    }
  }
  .button_container {
    display: grid;
    grid-template-columns: auto;
    justify-content: center;
    width: 100%;
    text-align: center;
    button {
      margin-top: 15px;
      width: 306px;
      height: 36px;
      border: inherit;
      border-radius: 3px;
      color: rgba(255, 255, 255, 1);
      font-family: "IRANYekanMobileExtraBold";
      font-size: 14.5px;
    }
    .continue_button {
      background-color: $static_green;
      &:hover {
        transition: background-color 0.6s;
        background-color: $static_green_hover;
      }
    }
    .back_button {
      background-color: $static_darkBlue;
    }
  }
  .errorMessage {
    color: red;
    text-align: right;
    font-family: "IranSansWeb";
    font-size: 12px;
  }
}
