.paperButton {
  background-color: initial;
  box-sizing: border-box;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: "IranSansWeb";
  font-size: 14px;
  height: 35px;
  line-height: 35px;
  outline: 0;
  overflow: hidden;
  padding: 0 20px;
  pointer-events: auto;
  position: relative;
  text-align: center;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
  vertical-align: top;
  white-space: nowrap;
  width: 100%;
  z-index: 9;
  border: 0;
  &[data-theme="success"] {
    background-image: linear-gradient(-180deg, #00c194, rgb(0, 213, 117));
  }
  &[data-theme="error"] {
    background-image: linear-gradient(-180deg, #f65555, #ff4646);
  }
}
.buttonTitleContainer {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  border-radius: 3px;
  background-color: var(--third-color);
  color: var(--secondary-color);
  position: relative;
  user-select: none;
  span {
    font-size: 14px;
    width: calc(100% - 50px);
    overflow: hidden;
    text-overflow: ellipsis;
    direction: ltr;
    text-align: right;
    word-break: break-all;
  }
  i {
    position: relative;
    top: 4px;
    svg {
      font-size: 20px;
      color: var(--secondary-color) !important;
    }
    &[data-close="true"] {
      svg{
        color: var(--danger-color)!important;
        font-size: 18px;
      }
    }
  }
}
