@import "../../../styles/_global.scss";

@font-face {
  font-family: IRANYekanMobileRegular;
  src: url("../../../styles/fonts/IRANYekanMobileRegular.ttf");
}
.container {
  width: 100%;
  // height: 220px;
  display: grid;
  grid-template-columns: auto;
  .app_logo_container {
    z-index: 1;
    width: 90px;
    height: 56px;
    margin: 17px auto;
    margin-bottom: 28px;
    img {
      width: 100%;
    }
  }
  .market_analyst_container {
    z-index: 1;
    width: 100%;
    height: 90px;
    display: grid;
    grid-template-columns: auto auto auto;
    justify-content: center;
    grid-gap: 15px;
    .analyst_container {
      border-radius: 5px;
      background-color: rgba(246, 255, 251, 1);
      position: relative;
      width: 97px;
      height: 90px;
      .crypto_icon {
        margin-left: 5px;
        margin-top: 5px;
        width: 20px;
        height: 23px;
        text-align: center;
        img {
          max-width: 20px;
          max-height: 23px;
          background-size: cover;
          //   background-position: center;
          //   background-repeat: no-repeat;
        }
      }
      .crypto_name_change_price {
        position: absolute;
        left: 27px;
        top: 7px;
        width: 60px;
        // background-color: red;
        font-family: "IranSansWeb";
        font-size: 11px;
        color: $static_green;
        text-align: center;
      }
      .crypto_price {
        position: absolute;
        left: 27px;
        top: 20px;
        text-align: center;
        font-family: "IranSansWeb";
        font-size: 11px;
      }
      .crypto_chart {
        position: absolute;
        bottom: 0px;
        left: 0;
        width: 97px;
        height: 50px;
        overflow: hidden; /// MAYBE HAS A BUG < << <<< <<<
        // svg {
        //   background-color: rebeccapurple;
        // }
        img {
          width: 70px;
          height: 27px;
          background-position: center;
          background-size: cover;
        }
      }
    }
  }
}

//Ball_Background
.balls_place {
  position: absolute;
  height: 260px;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.ball_gradient_1 {
  z-index: -1;
  position: absolute;
  width: 297.73px;
  height: 297.73px;
  left: -60px;
  top: -40px;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.4);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.064);
  transform: rotate(-70.63deg);
}
.ball_gradient_2 {
  z-index: -2;
  position: absolute;
  width: 297.73px;
  height: 297.73px;
  right: -60px;
  top: -80px;
  border-radius: 50%;
  background-color: rgba(224, 224, 224, 0.4);
  box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.09);
  transform: rotate(-70.63deg);
}
