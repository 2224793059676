.container .sections {
  width: 100%;
  display: grid;
  grid-template-columns: 50% 50%;
  font-family: "IranSansWeb";
  align-items: center;
  direction: rtl;
  padding:2px 0px;
  color: #837F7F;
  .title {
    font-size: 12px;
    text-align: right;
  }
  .desc {
    text-align: left;
    font-size: 13px;
    width: 100%;
    overflow: auto;
  }
  .symbols{
    font-size: 11px;
    padding-left: 2px;
  }
}

.infoIcon{
  font-size: 19px;
}