.ads {
  font-size: 13px;
  font-family: "IranSansWeb";
  direction: rtl;
  max-width: 160px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mui_container {
  input {
    z-index: 1;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    /* Firefox */
    &[type="number"] {
      -moz-appearance: textfield;
    }
  }
  .ads {
    z-index: 1;
    color: var(--item-color);
    opacity: 0.8;
  }
  input {
    color: var(--item-color);
  }
  input:disabled {
    -webkit-text-fill-color: var(--item-color);
    opacity: 0.8;
  }
}
